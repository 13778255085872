import { Grid, H5, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import Image from "@Components/Image";
import type { BulletPoint } from "@Components/pages/common/LandingWhySection/types";
import {
  Container,
  ContentCol,
  ImageCol,
  LogoWrapper,
  StyledBulletPoints,
} from "@Components/pages/common/LandingWhySection/WhySectionDetails/styles";

export interface Props {
  imageSrc: string;
  contentHeader: string;
  bulletPoints: BulletPoint[];
  contentLogo: string;
  bulletPointsIcon: string;
}

const WhySectionDetails = ({ imageSrc, bulletPoints, contentHeader, contentLogo, bulletPointsIcon }: Props) => {
  const formattedBulletPoints: { item: string; key: string }[] = bulletPoints.map(({ text }, i) => ({
    item: text,
    key: `${createKey(text)}-bullet-point-${i}`,
  }));

  return (
    <Grid.Row as={Container}>
      <Grid.Col as={ImageCol} md={4} sm={0}>
        {imageSrc && <Image alt="" layout="fill" objectFit="contain" sizes="40vw" src={imageSrc} />}
      </Grid.Col>
      <Grid.Col as={ContentCol} md={4} sm={8}>
        <LogoWrapper>{contentLogo && <Image alt="" height={56} src={contentLogo} width={139} />}</LogoWrapper>
        <Spacing mb={3} />
        <H5 bottom={5} boxtTheme="slate">
          {contentHeader}
        </H5>
        <StyledBulletPoints
          boxtTheme="slate"
          items={formattedBulletPoints}
          {...(bulletPointsIcon && { icon: <Image alt="" height={20} src={bulletPointsIcon} width={20} /> })}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default WhySectionDetails;
