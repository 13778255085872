import type { ButterButtonCollectionItem } from "@Collections/types/butter/collectionItem";

export enum ButterImageTextPosition {
  Left = "left",
  Right = "right",
}

export type ButterImageTextCard = {
  image: string;
  title: string;
  body: string;
  position: ButterImageTextPosition;
  cta_text: string;
  cta_theme: ButterButtonCollectionItem;
  cta_url: string;
};

export type ButterImageTextFields = {
  title: string;
  cards: ButterImageTextCard[];
};
