import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const Container = styled.button`
  display: flex;
  position: absolute;
  top: ${rem(72)};
  left: ${rem(16)};
  border-radius: 50px;
  padding: ${rem(12)} ${rem(17)};
  background-color: ${tinyColor(colors.slate.normal).setAlpha(0.8).toRgbString()};
  border: 0;
  cursor: pointer;
  z-index: 10;

  ${breakpoint("md")} {
    top: ${rem(80)};
  }

  ${breakpoint("lg")} {
    top: ${rem(92)};
    left: ${rem(56)};
  }
`;

export const ImageContainer = styled.span`
  margin-right: ${rem(10)};
  display: flex;
  align-items: center;
`;
