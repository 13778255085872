"use client";

import styled, { css } from "styled-components";
import { breakpoint, Button, H4, Paragraph, rem } from "@boxt/design-system";

import { ButterImageTextPosition } from "@Components/pages/common/ImageText/types";

const preWrap = css`
  white-space: pre-wrap;
`;

export const ImageCol = styled.div<{ $position: ButterImageTextPosition }>`
  order: 0;

  ${breakpoint("md")} {
    order: ${({ $position }) => ($position === ButterImageTextPosition.Right ? 1 : 0)};
  }
`;

export const Content = styled(Paragraph)`
  ${preWrap};
`;

export const Subtitle = styled(H4)`
  ${preWrap};
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: ${rem(265)};

  ${breakpoint("md")} {
    height: ${rem(385)};
  }
`;

export const CTAButton = styled(Button)`
  width: 100%;
  ${preWrap};

  ${breakpoint("md")} {
    width: initial;
  }
`;
