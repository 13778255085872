import Skeleton from "react-loading-skeleton";
import useTranslation from "next-translate/useTranslation";
import { H2, H3, H5, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import { roundNumberToNearestHundred } from "@Helpers/roundNumberToNearestHundred";
import { Answer } from "@StateMachines/Screener";
import type { BillWithoutProductProps } from "@Components/ProductSavings/ElectricitySavings/types";
import useProductType from "@Hooks/useProductType";
import useScreening from "@Hooks/useScreening";

import { SavingsRow } from "../styles";

import { Container } from "./styles";

const ElectricityBillWithoutProduct = ({
  isLoading,
  usagePerYear,
  costPerYear,
  costPerUnit,
  hasDefaultElectricityRates,
  productType,
  titleKey,
  i18nNamespace,
  hasUnitCosts = true,
  monthAndYearPriceCapRate = "",
  screeningId,
}: BillWithoutProductProps) => {
  const { t } = useTranslation(i18nNamespace);

  const { screeningData } = useScreening({
    productType,
    screeningId,
  });

  const { isBatteryOnly } = useProductType({ productType });
  const isStandaloneBattery =
    isBatteryOnly && screeningData?.screeningHistory?.content?.installType !== Answer.AddToSolar;

  let descriptionCopy = hasDefaultElectricityRates
    ? t("electricity-savings-description-default-rates")
    : t("electricity-savings-description", {
        monthAndYearPriceCapRate,
        usagePerYear: usagePerYear.toLocaleString(),
      });

  if (isBatteryOnly) {
    descriptionCopy = isStandaloneBattery
      ? t("electricity-savings-description-standalone-without-product")
      : t("electricity-savings-description-solar-retrofit-without-product");
  }

  return (
    <Container data-testid={`savings-without-${productType}`}>
      <Spacing mh={3} mv={3}>
        <Spacing mb={2}>
          <H5>{t(`electricity-savings-without-product.${titleKey}`)}</H5>
        </Spacing>
        <Spacing mb={2}>
          <SavingsRow>
            {/* Styling div */}
            <div>
              <H2>
                {isLoading ? (
                  <Skeleton containerTestId="skeleton-loader" width={100} />
                ) : (
                  currencyFormatter(roundNumberToNearestHundred(costPerYear))
                )}
              </H2>
              <Paragraph boxtTheme="slateDark">{t("per-year")}</Paragraph>
            </div>
          </SavingsRow>
        </Spacing>
        {hasUnitCosts ? (
          <Spacing mb={2}>
            <SavingsRow>
              {/* Styling divs */}
              <Spacing mb={{ lg: 0, md: 1, sm: 1 }}>
                <H3>
                  {isLoading ? (
                    <Skeleton containerTestId="skeleton-loader" width={100} />
                  ) : (
                    t("energy-usage", { usagePerYear: usagePerYear.toLocaleString() })
                  )}
                </H3>
                <Paragraph boxtTheme="slateDark" size="small">
                  {t("used-per-year")}
                </Paragraph>
              </Spacing>
              <div>
                <H3>{isLoading ? <Skeleton containerTestId="skeleton-loader" width={100} /> : costPerUnit}</H3>
                <Paragraph boxtTheme="slateDark" size="small">
                  {t("unit-cost")}
                </Paragraph>
              </div>
            </SavingsRow>
          </Spacing>
        ) : null}

        <Paragraph boxtTheme="slateDark" size="small">
          {descriptionCopy}
        </Paragraph>
      </Spacing>
    </Container>
  );
};

export default ElectricityBillWithoutProduct;
