import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  ${includeSpacing({
    mb: { md: 4, sm: 3 },
    mt: { md: 5, sm: 4 },
  })}
`;
