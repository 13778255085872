import styled, { css } from "styled-components";
import { breakpoint } from "@boxt/design-system";

type ContainerProps = {
  $isHomePage: boolean | undefined;
  $hasForegroundImage: boolean;
};

export const Container = styled.div`
  ${breakpoint("lg")} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

export const MobileVideoContainer = styled.div<ContainerProps>`
  ${({ $isHomePage }: ContainerProps) =>
    $isHomePage &&
    css`
      position: relative;
    `};

  ${({ $isHomePage, $hasForegroundImage }: ContainerProps) =>
    !$isHomePage &&
    !$hasForegroundImage &&
    css`
      position: relative;
    `};

  ${({ $isHomePage, $hasForegroundImage }: ContainerProps) =>
    !$isHomePage &&
    $hasForegroundImage &&
    css`
      position: absolute;
      height: 100%;
      width: 100%;
    `};

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const DesktopVideoContainer = styled.div`
  display: none;

  ${breakpoint("lg")} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const MobileVideoAspectRatio = styled.div<ContainerProps>`
  padding-top: 70%;

  ${breakpoint("md")} {
    ${({ $hasForegroundImage }: ContainerProps) =>
      !$hasForegroundImage &&
      css`
        padding-top: 50%;
      `};
  }
`;
