import { Add, Subtract } from "@carbon/icons-react";
import { Spacing } from "@boxt/design-system";

import { ZoomButton, ZoomControlsContainer } from "./styles";

export type Props = {
  handleZoom: (zoomType: "zoom-in" | "zoom-out") => void;
};

const ZoomControls = ({ handleZoom }: Props) => {
  return (
    <ZoomControlsContainer>
      <Spacing mh={{ lg: 2, sm: 1 }}>
        <ZoomButton
          data-testid="zoom-out"
          onClick={() => {
            handleZoom("zoom-out");
          }}
          size="small"
        >
          <Subtract color="#2D3D4D" size={32} />
        </ZoomButton>
      </Spacing>
      <Spacing mh={{ lg: 2, sm: 1 }}>
        <ZoomButton
          data-testid="zoom-in"
          onClick={() => {
            handleZoom("zoom-in");
          }}
          size="small"
        >
          <Add color="#2D3D4D" size={32} />
        </ZoomButton>
      </Spacing>
    </ZoomControlsContainer>
  );
};

export default ZoomControls;
