import type { KeenSliderOptions } from "keen-slider/react";
import { breakpoints } from "@boxt/design-system";

import { MAX_CARDS_VISIBLE, SLIDER_PADDING } from "@Components/pages/common/LandingProductTiles/constants";

export const getConfig = (slidesLength): KeenSliderOptions => ({
  breakpoints: {
    [`(max-width: ${breakpoints.sm.width})`]: {
      initial: 0,
      loop: false,
      slides: {
        perView: 1,
        spacing: SLIDER_PADDING,
      },
    },
    [`(min-width: ${breakpoints.sm.width})`]: {
      initial: 0,
      loop: false,
      slides: {
        origin: "center",
        perView: "auto",
        spacing: SLIDER_PADDING,
      },
    },
    [`(min-width: ${breakpoints.md.width})`]: {
      initial: 0,
      loop: false,
      slides: {
        perView: "auto",
        spacing: SLIDER_PADDING,
      },
    },
    [`(min-width: ${breakpoints.lg.width})`]: {
      initial: 0,
      loop: false,
      slides: {
        perView: Math.min(MAX_CARDS_VISIBLE, slidesLength),
        spacing: SLIDER_PADDING,
      },
    },
    [`(min-width: ${breakpoints.xlg.width})`]: {
      initial: 0,
      loop: false,
      slides: {
        perView: Math.min(MAX_CARDS_VISIBLE, slidesLength),
        spacing: SLIDER_PADDING,
      },
    },
  },
});
