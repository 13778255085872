import type { ComponentProps } from "react";
import { isEmpty } from "lodash-es";
import { Grid, H3, Tabs as TabsComponents } from "@boxt/design-system";

import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import { actions, ReactGAEvent } from "@Lib/googleAnalytics";
import VisibilitySensor from "@Hoc/VisibilitySensor";
import { Row } from "@Components/pages/common/ButterLandingFAQ/styles";
import { StyledAnswer, Wrapper } from "@Components/pages/common/ButterLandingFAQ/styles";
import type { ButterCategoryItem, ButterFAQFields } from "@Components/pages/common/ButterLandingFAQ/types";
import { getDOMParserOptions } from "@Components/pages/common/HTMLParser/helpers";

const { TabPanel, TabsContainer, Tabs } = TabsComponents;

export type Props = {
  alignTitle?: "left" | "center" | "right";
  content: ButterFAQFields;
  gaCategory: string;
  header: string;
  headerColumnProps?: ComponentProps<typeof Grid.Col>;
  keyName: string;
  tabsWrapperProps?: ComponentProps<typeof Grid.Col>;
};

const defaultTabsWrapperProps = { lg: { offset: 2, span: 12 } };
const defaultHeaderColumnProps = { lg: 16, md: 8, sm: 4 };
const defaultPanelPadding = { top: { md: 6, sm: 3 } };

const ButterLandingFAQ = ({
  alignTitle = "center",
  content,
  gaCategory,
  header,
  headerColumnProps = defaultHeaderColumnProps,
  keyName,
  tabsWrapperProps = defaultTabsWrapperProps,
}: Props) => {
  const categories = content?.categories?.map(({ category, questions_and_answers }: ButterCategoryItem) => {
    return {
      category,
      questionsAndAnswers: questions_and_answers?.map((item, index) => {
        return {
          answer: dangerouslySetInnerHTML(item.answer, getDOMParserOptions()) as JSX.Element,
          key: `${keyName}-${category}-category-${index}-question`,
          question: item.question,
        };
      }),
    };
  });

  const handleAnalyticEvent = (label: string) => {
    ReactGAEvent({
      action: actions.faqTabs,
      category: gaCategory,
      label,
    });
  };

  const showTabs = categories?.length > 1;

  if (isEmpty(categories)) {
    return null;
  }

  return (
    <Wrapper id={content.id}>
      <Grid.Row as={Row}>
        <Grid.Col {...headerColumnProps}>
          <H3 align={alignTitle} bottom={{ md: 7, sm: 5 }}>
            {header}
          </H3>
        </Grid.Col>
        <Grid.Col {...tabsWrapperProps}>
          <TabsContainer panelPadding={showTabs ? defaultPanelPadding : 0}>
            {showTabs ? (
              <Tabs align="center" boxtTheme="slate" options={categories.map(({ category }) => category)} top={2} />
            ) : (
              <></> // Don't delete fragment as it will break tabs as it looks for specific children
            )}
            {categories.map(({ category, questionsAndAnswers }) => (
              <TabPanel key={category}>
                <VisibilitySensor onVisible={() => handleAnalyticEvent(category)} />
                {questionsAndAnswers?.map(({ question, answer }) => (
                  <StyledAnswer
                    $isContentColorOverriden
                    $textTheme="slate"
                    bottom={{ md: 0, sm: 2 }}
                    key={question}
                    title={question}
                    top={{ md: 0, sm: 2 }}
                  >
                    {answer}
                  </StyledAnswer>
                ))}
              </TabPanel>
            ))}
          </TabsContainer>
        </Grid.Col>
      </Grid.Row>
    </Wrapper>
  );
};

export default ButterLandingFAQ;
