"use client";

import styled, { css } from "styled-components";
import { Accordion, breakpoint, colors } from "@boxt/design-system";

import { FONT_COLOR } from "@Collections/themeColors";

export const Wrapper = styled.div`
  padding: 2rem 0;
  ${breakpoint("md")} {
    padding: 3rem 0;
  }
`;

export const ModuleContainer = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Row = styled.div`
  margin: unset;
`;

export const StyledAnswer = styled(Accordion)<{ $textTheme: string; $isContentColorOverriden: boolean }>`
  ${({ $textTheme, $isContentColorOverriden }) =>
    $isContentColorOverriden &&
    FONT_COLOR[$textTheme] &&
    css`
      color: ${FONT_COLOR[$textTheme]};
    `};
`;
