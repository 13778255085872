import { Grid, H3 } from "@boxt/design-system";

import { Title } from "@Components/pages/common/LandingImageTiles/LandingImageTilesHeader/styles";
import type { ButterImageTilesFields } from "@Components/pages/common/LandingImageTiles/types";

export type Props = {
  content: ButterImageTilesFields;
};

const LandingImageTilesHeader = ({ content }: Props) => {
  const header = content?.header || "";

  return (
    <Grid.Row>
      <Grid.Col as={Title}>
        <H3>{header}</H3>
      </Grid.Col>
    </Grid.Row>
  );
};

export default LandingImageTilesHeader;
