import type { ProductSpec } from "@Collections/types/productsBundle";
import type { SolarPackage } from "@Components/SolarConfigurator/types";

export const getBatteryCapacity = (specs: ProductSpec[]) => {
  const spec = specs.find((item) => item.key === "battery_capacity");
  return spec ? `${spec.value}${spec.unit}` : null;
};

export const getBatteryImage = (solarPackage: SolarPackage | undefined) => {
  if (!solarPackage) return "";

  return solarPackage.merchandisingCover || "";
};

export const getBatterySize = (specs: ProductSpec[]) => {
  const spec = specs.find((item) => item.key === "battery_size_label_for_solar_estimate");
  return spec ? spec.value : null;
};
