import { animated } from "react-spring";
import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${rem(280)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${rem(90)};
  gap: ${rem(16)};

  ${breakpoint("md")} {
    max-width: none;
  }
`;

export const AnimatedContainer = styled(animated.div)<any>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  background-color: ${colors.white};
  z-index: 10;
  box-shadow: 0 0 15px 0 #33455726;
  padding: 0 ${rem(90)};
`;
