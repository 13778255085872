import { useState } from "react";
import type { KeenSliderOptions } from "keen-slider";
import { breakpoints } from "@boxt/design-system";

import Image from "@Components/Image";
import Slider from "@Components/pages/common/Slider";

import type { ButterLandingHeroForegroundImage } from "../../types";

import { DesktopImage, MobileImage, SlideWrapper, TabletImage, WrapperStyle } from "./styles";

export type Props = {
  images: ButterLandingHeroForegroundImage[];
  isForegroundImageMaxWidth?: boolean;
};

const ForegroundImage = ({ images, isForegroundImageMaxWidth }: Props) => {
  const [opacities, setOpacities] = useState<number[]>([]);

  const sliderConfig: KeenSliderOptions = {
    breakpoints: {
      [`(max-width: ${breakpoints.sm.width})`]: {
        initial: 0,
        loop: images.length > 1,
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
      [`(min-width: ${breakpoints.sm.width})`]: {
        initial: 0,
        loop: images.length > 1,
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
    },
    defaultAnimation: {
      duration: 1500,
    },
    detailsChanged: (slider) => {
      const newOpacities = slider.track.details.slides.map((slide) => slide.portion);
      setOpacities(newOpacities);
    },
    drag: false,
    renderMode: "custom",
  };

  return (
    <Slider includeSpacing={false} showDotsController={false} sliderOptions={sliderConfig} wrapperStyle={WrapperStyle}>
      {images.map((image, index) => (
        <SlideWrapper
          $isForegroundImageMaxWidth={isForegroundImageMaxWidth}
          $opacity={opacities[index]}
          className={`keen-slider__slide slide-${index}`}
          key={`image-${index}`}
        >
          <MobileImage>
            <Image alt={image.alt_text} height={255} layout="responsive" src={image.mobile} width={375} />
          </MobileImage>
          <TabletImage>
            <Image alt={image.alt_text} height={518} layout="responsive" src={image.tablet} width={768} />
          </TabletImage>
          <DesktopImage $isForegroundImageMaxWidth={isForegroundImageMaxWidth} data-testid="desktop-image">
            <Image alt={image.alt_text} layout="fill" objectFit="cover" src={image.desktop} />
          </DesktopImage>
        </SlideWrapper>
      ))}
    </Slider>
  );
};

export default ForegroundImage;
