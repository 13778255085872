import Trans from "next-translate/Trans";
import { breakpoints, Button, H3, H5, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import type { OwnershipMethodType } from "../../types";

import { BackgroundImage, Container, ContentWrapper, TextWrapper, Wrapper } from "./styles";

type Props = {
  method: OwnershipMethodType;
};

const OwnershipMethod = ({ method }: Props) => {
  const { key, title, buttonText, buttonTheme, onClick, imageSrc, isLoadingScreener, bgImage, theme, testId } = method;
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  return (
    <Container $boxtTheme={theme}>
      {bgImage && <BackgroundImage alt="" layout="fill" objectFit="contain" objectPosition="left" src={bgImage} />}
      <Wrapper mb={{ lg: 5, sm: 4 }} mh={{ lg: 4, md: 3, sm: 2 }}>
        <ContentWrapper>
          <div>
            <Image alt={title} height={isMobile ? 90 : 180} src={imageSrc} width={isMobile ? 136 : 272} />
          </div>
          <TextWrapper>
            <Spacing mb={{ lg: 4, md: 3, sm: 2 }} mt={{ lg: 5, md: 4, sm: 2 }}>
              <H3 bottom={{ lg: 6, md: 5, sm: 3 }} weight="bold">
                {title}
              </H3>
              <H5 weight="regular">
                <Trans components={{ strong: <strong /> }} i18nKey={`boilers/index:life-popup.${key}.subtitle`} />
              </H5>
            </Spacing>
            <div>
              <Button
                boxtTheme={buttonTheme}
                data-testid={testId}
                loadingConfig={{
                  isLoading: isLoadingScreener,
                }}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </div>
          </TextWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default OwnershipMethod;
