"use client";

import styled from "styled-components";
import { breakpoint, includeSpacing, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const Container = styled.div<{ $bgColor: string }>`
  display: flex;
  width: 100%;
  border-radius: ${rem(12)};
  ${({ $bgColor }) => `background-color: ${BACKGROUND_COLORS[$bgColor]};`}
  justify-content: space-between;

  align-items: flex-start;
  flex-direction: column;
  padding: ${rem(32)} ${rem(24)};
  ${breakpoint("md")} {
    flex-direction: row;
    align-items: center;
    padding: ${rem(20)} ${rem(24)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${includeSpacing({ mr: { lg: 3, md: 3, sm: 0, xlg: 7 } })};
`;

export const ImageWrapper = styled.div`
  display: flex;
  min-width: ${rem(165)};
  max-height: ${rem(60)};
  ${breakpoint("md")} {
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: ${rem(152)};
  a {
    width: 100%;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: block;
  ${breakpoint("md")} {
    display: flex;
    align-items: center;
  }
`;
