import Skeleton from "react-loading-skeleton";
import useTranslation from "next-translate/useTranslation";
import { H2, H3, H5, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import { roundNumberToNearestHundred } from "@Helpers/roundNumberToNearestHundred";
import { Answer } from "@StateMachines/Screener";
import type { BillWithProductProps } from "@Components/ProductSavings/ElectricitySavings/types";
import useProductType from "@Hooks/useProductType";
import useScreening from "@Hooks/useScreening";

import { SavingsRow } from "../styles";

import PaybackPeriod from "./PaybackPeriod";
import { Container } from "./styles";

const ElectricityBillWithProduct = ({
  annualGridExportInPence = 0,
  isLoading,
  costPerYear,
  savingsPerYear,
  paybackPeriod,
  savingsPercentage,
  productType,
  titleKey,
  i18nNamespace,
  hasAdditionalInfo = false,
  hasPaybackPeriod = true,
  screeningId,
}: BillWithProductProps) => {
  const { t } = useTranslation(i18nNamespace);
  const { isBatteryOnly } = useProductType({ productType });

  const { screeningData } = useScreening({
    productType,
    screeningId,
  });

  const isStandaloneBattery =
    isBatteryOnly && screeningData?.screeningHistory?.content?.installType !== Answer.AddToSolar;

  let descriptionCopy = "";

  descriptionCopy = isStandaloneBattery
    ? t("electricity-savings-description-standalone-with-product")
    : t("electricity-savings-description-solar-retrofit-with-product");

  return (
    <Container data-testid={`savings-with-${productType}`}>
      <Spacing mh={3} mv={3}>
        <Spacing mb={2}>
          <H5>{t(`electricity-savings-with-product.${titleKey}`)}</H5>
        </Spacing>
        <Spacing mb={2}>
          <SavingsRow>
            {/* Styling div */}
            <div>
              <H2>
                {isLoading ? (
                  <Skeleton containerTestId="skeleton-loader" width={100} />
                ) : (
                  currencyFormatter(roundNumberToNearestHundred(costPerYear))
                )}
              </H2>
              <Paragraph boxtTheme="slateDark">{t("per-year")}</Paragraph>
            </div>
          </SavingsRow>
        </Spacing>
        <Spacing mb={2}>
          <SavingsRow>
            {/* Styling divs */}
            <Spacing mb={{ lg: 0, md: 1, sm: 1 }}>
              <H3>
                {isLoading ? (
                  <Skeleton containerTestId="skeleton-loader" width={100} />
                ) : (
                  currencyFormatter(savingsPerYear, 1, 0)
                )}
              </H3>
              <Paragraph boxtTheme="slateDark" size="small">
                {t("savings-per-year")}
              </Paragraph>
            </Spacing>
            {isBatteryOnly ? (
              <div>
                <H3>
                  {isLoading ? (
                    <Skeleton containerTestId="skeleton-loader" width={100} />
                  ) : (
                    currencyFormatter(annualGridExportInPence, 1, 0)
                  )}
                </H3>
                <Paragraph boxtTheme="slateDark" size="small">
                  {t("export-earnings-per-year")}
                </Paragraph>
              </div>
            ) : (
              <div>
                <H3>
                  {isLoading ? <Skeleton containerTestId="skeleton-loader" width={100} /> : `${savingsPercentage}%`}
                </H3>
                <Paragraph boxtTheme="slateDark" size="small">
                  {t("savings-per-year")}
                </Paragraph>
              </div>
            )}
          </SavingsRow>
        </Spacing>
        <Spacing mb={3}>
          {isBatteryOnly ? (
            <Paragraph boxtTheme="slateDark" size="small">
              {descriptionCopy}
            </Paragraph>
          ) : hasPaybackPeriod ? (
            <PaybackPeriod i18nNamespace={i18nNamespace} isLoading={isLoading} paybackPeriod={paybackPeriod} />
          ) : null}
        </Spacing>
        {hasAdditionalInfo ? (
          <Paragraph boxtTheme="slateDark" size="small">
            {t("electricity-estimates-description")}
          </Paragraph>
        ) : null}
      </Spacing>
    </Container>
  );
};

export default ElectricityBillWithProduct;
