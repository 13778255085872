import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { Button, Grid, H3, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";

import LogoList from "./components/LogoList";
import type { Logo } from "./components/LogoList/LogoList";
import Tag from "./components/Tag";
import { StyledColumn, StyledRow, TagsContainer, Wrapper } from "./styles";
import type { Butter5050Fields } from "./types";

export type Props = {
  fields: Butter5050Fields;
  logoHeight?: number;
  logoWidth?: number;
};

const themeMapper = {
  dark: {
    contentTheme: "dark",
    ctaTheme: "dark",
    detailTheme: "dark",
    title: "dark",
  },
  light: {
    contentTheme: "coalLighter",
    ctaTheme: "coal",
    detailTheme: "slateLight",
    title: "coal",
  },
};

const defaultTheme = {
  ...themeMapper.light,
  ctaTheme: "jade",
};

const Landing5050 = ({ fields, logoHeight = 35, logoWidth = 35 }: Props) => {
  const { t } = useTranslation("list/index");

  const {
    android_logo: androidLogo,
    cta_link: ctaLink,
    description,
    detail,
    image_alignment: imageAlignment,
    ios_logo: iOSLogo,
    main_image: mainImage,
    tags,
    title,
    top_logos: topLogos,
    cta_text: ctaText,
    app_store_url: appStoreUrl,
    play_store_url: playStoreUrl,
    bg_color: bgColor,
    theme,
  } = fields;

  const mappedTopLogos = topLogos?.map((logo) => ({
    ...logo,
    altText: logo.alt_text,
    logoHeight: logo?.height || logoHeight,
    logoWidth: logo?.width || logoWidth,
  }));

  const getBottomLogos = () => {
    const bottomLogos: Logo[] = [];

    if (!isEmpty(androidLogo)) {
      bottomLogos.push({
        altText: t("landing-50-50.android-alt-text"),
        image: androidLogo,
        link: playStoreUrl,
        logoHeight: 42,
        logoWidth: 138,
      });
    }

    if (!isEmpty(iOSLogo)) {
      bottomLogos.push({
        altText: t("landing-50-50.ios-alt-text"),
        image: iOSLogo,
        link: appStoreUrl,
        logoHeight: 42,
        logoWidth: 123,
      });
    }

    return bottomLogos;
  };

  return (
    <Wrapper $bgColor={bgColor?.name}>
      <Spacing mh={{ lg: 8, md: 0, sm: 0 }} mv={5}>
        <Grid>
          <StyledRow $imageAlignment={imageAlignment}>
            <Grid.Col md={4} sm={4}>
              <Spacing
                data-testid="landing-50-50-main-image"
                ml={{ lg: imageAlignment === "left" ? 0 : 3, md: 0, sm: 0 }}
                mr={{ lg: imageAlignment === "left" ? 3 : 0, md: 0, sm: 0 }}
              >
                <Spacing mb={{ md: 0, sm: 3 }}>
                  <Image
                    alt=""
                    height={470}
                    objectFit="contain"
                    priority
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    src={mainImage}
                    width={470}
                  />
                </Spacing>
              </Spacing>
            </Grid.Col>
            <StyledColumn md={4} sm={4}>
              <Spacing
                ml={{ lg: imageAlignment === "left" ? 3 : 0, md: 0, sm: 0 }}
                mr={{ lg: imageAlignment === "left" ? 0 : 3, md: 0, sm: 0 }}
              >
                {!isEmpty(tags) && (
                  <Spacing mb={{ lg: 2, sm: 4 }}>
                    <TagsContainer>
                      {tags?.map(({ tag_label: tagLabel }) => <Tag key={tagLabel}>{tagLabel}</Tag>)}
                    </TagsContainer>
                  </Spacing>
                )}
                {!isEmpty(topLogos) && (
                  <Spacing mb={4}>
                    <LogoList dataTestId="landing-50-50-top-logo-list" logos={mappedTopLogos || []} />
                  </Spacing>
                )}
                {detail && (
                  <Spacing data-testid="landing-50-50-detail" mb={2}>
                    <Paragraph
                      boxtTheme={themeMapper[theme.name]?.detailTheme || defaultTheme.detailTheme}
                      size="small"
                    >
                      {detail}
                    </Paragraph>
                  </Spacing>
                )}
                {title && (
                  <H3
                    bottom={4}
                    boxtTheme={themeMapper[theme.name]?.title || defaultTheme.title}
                    data-testid="landing-50-50-title"
                    weight="regular"
                  >
                    {title}
                  </H3>
                )}
                {description && (
                  <Spacing data-testid="landing-50-50-description" mb={{ lg: 1, sm: 0 }}>
                    <ButterHTMLParsingContent
                      addContainerSpacing={false}
                      contentTheme={themeMapper[theme.name]?.contentTheme || defaultTheme.contentTheme}
                      fields={{ content: description }}
                      idAttribute="landing-5050-description"
                    />
                  </Spacing>
                )}
                {ctaLink && ctaText && (
                  <Spacing mb={{ md: 3, sm: 2 }}>
                    <Button
                      boxtTheme={themeMapper[theme.name]?.ctaTheme || defaultTheme.ctaTheme}
                      data-testid="landing-50-50-cta"
                      href={ctaLink}
                      skin="secondary"
                    >
                      {ctaText}
                    </Button>
                  </Spacing>
                )}
                {!isEmpty(getBottomLogos()) && (
                  <LogoList dataTestId="landing-50-50-bottom-logo-list" logos={getBottomLogos()} />
                )}
              </Spacing>
            </StyledColumn>
          </StyledRow>
        </Grid>
      </Spacing>
    </Wrapper>
  );
};

export default Landing5050;
