"use client";

import styled from "styled-components";
import { Grid, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const StyledRow = styled(Grid.Row)<{ $imageAlignment: "left" | "right" }>`
  flex-direction: ${({ $imageAlignment }) => ($imageAlignment === "left" ? "row" : "row-reverse")};
`;

export const StyledColumn = styled(Grid.Col)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: ${rem(8)};
`;

export const Wrapper = styled.div<{ $bgColor?: string }>`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${({ $bgColor }) => ($bgColor && BACKGROUND_COLORS[$bgColor]) || "transparent"};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
