"use client";

import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const ChevronContainer = styled.div`
  display: flex;
  transform: rotate(180deg);
  height: ${rem(13.7)};
  width: ${rem(6.9)};
`;
