"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${colors.white};
`;

export const ContentWrapper = styled.div`
  max-width: ${rem(848)};
  margin: 0 auto;
`;
