import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import {
  LG_MAX_TILE_HEIGHT,
  LG_TILE_HEIGHT,
  LG_TILE_WIDTH,
  MD_MAX_TILE_HEIGHT,
  MD_TILE_HEIGHT,
  MD_TILE_WIDTH,
  SM_MAX_TILE_HEIGHT,
  SM_TILE_HEIGHT,
  SM_TILE_WIDTH,
  XLG_MAX_TILE_WIDTH,
} from "./constants";

export const StyledBackgroundStrip = styled.div`
  background-color: ${tinyColor(colors.coral.normal).setAlpha(0.2).toRgbString()};
  position: relative;
  margin: 0 calc(50% - 50vw);

  ${breakpoint("sm")} {
    height: ${rem(520)};
  }
  ${breakpoint("md")} {
    height: ${rem(528)};
  }
  ${breakpoint("lg")} {
    height: ${rem(456)};
  }
`;

export const StyledGrid = styled.div`
  position: relative;
  padding-right: 0;
  max-width: 99rem;
  margin: 0 auto;
  top: ${rem(-24)};
  overflow-x: hidden;

  ${breakpoint("lg")} {
    top: ${rem(-48)};
  }
  ${breakpoint("max")} {
    padding-right: 2.5rem;
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${rem(576)};
  border-radius: ${rem(12)} 0 0 ${rem(12)};

  ${breakpoint("lg")} {
    height: ${rem(552)};
  }

  ${breakpoint("max")} {
    border-radius: ${rem(12)};
  }
`;

export const StyledTile = styled.div<{ $firstTile: boolean }>`
  ${includeSpacing({ mh: 1, mv: 1 })}
  background-color: white;
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: column;
  justify-content: ${({ $firstTile }) => ($firstTile ? "space-between" : "flex-end")};
  padding: ${rem(16)};
  overflow: hidden;

  ${breakpoint("sm")} {
    max-width: ${rem(SM_TILE_WIDTH)};
    height: ${({ $firstTile }) => ($firstTile ? `${rem(SM_MAX_TILE_HEIGHT)}` : `${rem(SM_TILE_HEIGHT)}`)};
  }
  ${breakpoint("md")} {
    max-width: ${rem(MD_TILE_WIDTH)};
    height: ${({ $firstTile }) => ($firstTile ? `${rem(MD_MAX_TILE_HEIGHT)}` : `${rem(MD_TILE_HEIGHT)}`)};
  }

  ${breakpoint("lg")} {
    max-width: ${rem(LG_TILE_WIDTH)};
    height: ${({ $firstTile }) => ($firstTile ? `${rem(LG_MAX_TILE_HEIGHT)}` : `${rem(LG_TILE_HEIGHT)}`)};
    padding: ${rem(24)};
    margin-bottom: 1rem;
  }

  ${breakpoint("max")} {
    max-width: ${rem(XLG_MAX_TILE_WIDTH)};
  }
`;

export const VanImageWrapper = styled.div`
  position: relative;
  left: ${rem(-10)};
  top: ${rem(10)};

  ${breakpoint("sm")} {
    height: ${rem(65)};
    width: ${rem(171)};
  }
  ${breakpoint("md")} {
    height: ${rem(96)};
    width: ${rem(252)};
    left: ${rem(30)};
  }

  ${breakpoint("lg")} {
    height: ${rem(102)};
    width: ${rem(272)};
    left: ${rem(-16)};
    top: ${rem(45)};
  }
`;

export const TilesWrapper = styled.div<{ $isLg: boolean }>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  top: ${({ $isLg }) => ($isLg ? `${rem(40)}` : `${rem(15)}`)};
`;
