"use client";

import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const StyledSpacing = styled.div<{ $sm: number; $md: number; $lg: number; $bgColor?: string }>`
  position: relative;
  overflow: hidden;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  height: ${({ $sm }) => rem($sm)};

  ${breakpoint("md")} {
    height: ${({ $md }) => rem($md)};
  }

  ${breakpoint("lg")} {
    height: ${({ $lg }) => rem($lg)};
  }
  background-color: ${({ $bgColor }) => ($bgColor ? BACKGROUND_COLORS[$bgColor] : colors.white)};
`;
