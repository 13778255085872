"use client";

import styled from "styled-components";
import { breakpoint, Button, colors, rem } from "@boxt/design-system";

export const ZoomControlsContainer = styled.div`
  bottom: ${rem(38)};
  display: flex;
  height: ${rem(50)};
  left: calc(50% - ${rem(64)});
  position: absolute;
  width: min-content;
  z-index: 1;
  ${breakpoint("lg")} {
    left: calc(50% - ${rem(80)});
  }
`;
export const ZoomButton = styled(Button)`
  background: ${colors.white};
  border-radius: 50%;
  padding: ${rem(8)};

  &:hover {
    background: ${colors.white};
  }
`;
