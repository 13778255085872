"use client";

import styled from "styled-components";
import { colors, includeSpacing } from "@boxt/design-system";

export const List = styled.ul`
  list-style: inherit;
  ${includeSpacing({ mb: 2, ml: 2 })}
`;

export const ListItem = styled.li`
  ${includeSpacing({ mt: 1 })}
  line-height: 1.25;
  font-weight: 300;
  color: ${colors.coal.lighter};
`;
