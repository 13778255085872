import { H4, Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";
import { CardContainer, CTAButton, ImageWrapper } from "@Components/pages/common/GuideCTA/styles";
import type { ButterGuideCTAFields } from "@Components/pages/common/GuideCTA/types";

type Props = {
  fields: ButterGuideCTAFields;
};

const GuideCTA = ({ fields }: Props) => {
  return (
    <CardContainer $backgroundColor={fields.card_theme.background_color || "platinumLighter"}>
      <ImageWrapper>
        <Image alt={fields.marketing_hook_text} layout="fill" objectFit="cover" src={fields.image} />
      </ImageWrapper>
      <H4 bottom={3} boxtTheme={fields.card_theme.text_color || "slate"} top={{ md: 1, sm: 0 }}>
        {fields.marketing_hook_text}
      </H4>
      <Paragraph bottom={6} boxtTheme={fields.card_theme.text_color || "slate"}>
        {fields.offer_text}
      </Paragraph>
      <CTAButton boxtTheme={fields.button_theme.theme || "jade"} data-testid="cta-button" href={fields.button_url}>
        {fields.button_text}
      </CTAButton>
    </CardContainer>
  );
};

export default GuideCTA;
