import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const BillSavingsContainer = styled.div<{ $isSingleColumn: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ $isSingleColumn }) => ($isSingleColumn ? rem(16) : rem(24))};

  ${breakpoint("md")} {
    gap: ${({ $isSingleColumn }) => ($isSingleColumn ? rem(16) : rem(32))};
    grid-template-columns: 1fr 1fr;
    grid-template-columns: ${({ $isSingleColumn }) => ($isSingleColumn ? "1fr" : "1fr 1fr")};
  }
`;

export const SavingsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoint("lg")} {
    gap: ${rem(16)};
    grid-template-columns: 1fr 1fr;
  }
`;
