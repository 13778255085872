import { useMemo } from "react";
import { isEmpty } from "lodash-es";
import { Grid } from "@boxt/design-system";

import Slider from "../Slider";
import type { ArrowTheme } from "../Slider/types";

import { getConfig } from "./config";
import {
  CardContainer,
  Container,
  StyledCaption,
  StyledGridCol,
  StyledGridRow,
  StyledParagraph,
  StyledQuotationMark,
} from "./styles";
import type { ButterTestimonial, ButterTestimonialsFields } from "./types";

export type Props = {
  fields: ButterTestimonialsFields;
  idAttribute: string;
};
const arrowTheme: ArrowTheme = { color: "light", position: "outside", size: "small" };

const Testimonials = ({ fields: { quotation_marks: quotationMarks, testimonials }, idAttribute }: Props) => {
  const sliderImages: ButterTestimonial[] = useMemo(
    () =>
      testimonials.map((slide) => ({
        ...slide,
        link: isEmpty(slide.link) ? null : slide.link,
      })),
    [testimonials],
  );

  return (
    <StyledGridRow id={idAttribute}>
      <Grid.Col $length={sliderImages.length} as={StyledGridCol}>
        <Container>
          <Slider
            arrowTheme={arrowTheme}
            duration={6000}
            includeSpacing={false}
            initiallyLoop={false}
            showArrows
            showDotsController={false}
            showLeftArrow={sliderImages.length > 3}
            sliderOptions={getConfig()}
          >
            {sliderImages.map((testimonial, index) => (
              <div className="keen-slider__slide" key={`${idAttribute}-${index}`}>
                <CardContainer
                  $hasLink={Boolean(testimonial?.link)}
                  {...(testimonial?.link && { href: testimonial?.link })}
                >
                  {quotationMarks && <StyledQuotationMark />}
                  <StyledCaption>{testimonial.text}</StyledCaption>
                  <StyledParagraph>{testimonial.name}</StyledParagraph>
                </CardContainer>
              </div>
            ))}
          </Slider>
        </Container>
      </Grid.Col>
    </StyledGridRow>
  );
};

export default Testimonials;
