const MAX_OFFSET = 1500;
const SPEED = 0.1;

export const calcOffset = (initialTop: number, currentTop: number, currentOffset: number) => {
  let offset: number;

  // Is element in view on page load or coming into view from bottom?
  if (currentOffset == 0) {
    offset = initialTop - currentTop;
  } else {
    // Must be coming into view from top
    offset = currentOffset - Math.abs(initialTop - currentTop);

    if (offset < 0) offset = 0;
  }

  if (offset > MAX_OFFSET) {
    return MAX_OFFSET;
  } else if (offset < 0) {
    return 0;
  } else {
    return offset;
  }
};

// 'offset' can be +ve or -ve
export const translateYFx = (offset: number) => `translateY(${-(offset * SPEED)}px)`;
