import { isEmpty } from "lodash-es";
import { parseCookies, setCookie } from "nookies";
import { v4 as uuidv4 } from "uuid";

import type { screenerStateType } from "src/reduxStore/features/screenerSlice";

import type { ScreenerOptions } from "./types";
import { SCREENER_COOKIE_KEY } from "./types";

const createNewScreenerKey = (
  screenerKey: string,
): {
  screenerKey: string;
  screenerId: string;
} => {
  const screenerId = uuidv4();

  setCookie(null, screenerKey, screenerId, {
    path: "/",
    sameSite: "lax",
  });

  return {
    screenerId,
    screenerKey,
  };
};

const getScreenerIdFromCookie = (screenerKey: string) => {
  return parseCookies()[screenerKey];
};

type ReturnValue = {
  isValid: boolean;
  screenerId: string;
  screenerKey: string;
  isComplete?: boolean;
};

const validateScreener = (state: screenerStateType, options: ScreenerOptions): ReturnValue => {
  const { version, variant } = options;
  const screenerKey = `${SCREENER_COOKIE_KEY}:${variant}`;
  const cookieId = getScreenerIdFromCookie(screenerKey);

  const hasCookieScreener = !isEmpty(cookieId);
  const hasStateScreener = state[screenerKey];
  if (hasCookieScreener && hasStateScreener) {
    // check if the cookie and state match
    const isIdMatching = state[screenerKey].meta.id === cookieId;
    const isVersionMatching = state[screenerKey].meta.version === version;
    const isSynced = isIdMatching && isVersionMatching;
    if (isSynced) {
      return {
        isComplete: Boolean(state[screenerKey].meta.isComplete),
        isValid: true,
        screenerId: cookieId,
        screenerKey,
      };
    }
  }
  return {
    isComplete: false,
    isValid: false,
    screenerId: cookieId,
    screenerKey,
  };
};
const validateAndGenerateNewScreenerKey = (state: screenerStateType, options: ScreenerOptions): ReturnValue => {
  const validationResult = validateScreener(state, options);
  // state and cookie are synced
  if (validationResult.isValid) return validationResult;

  const { screenerId } = createNewScreenerKey(validationResult.screenerKey);
  // state and cookie are not synced, generate new screener key to init state
  return {
    isValid: false,
    screenerId,
    screenerKey: validationResult.screenerKey,
  };
};

const getScreenerProgress = (graph: any, currentQuestion: string, start: string, end: string) => {
  let totalSteps: number;
  let stepsAmountLeft: number;

  const cleanNodeName = currentQuestion.split(".will")[0];

  try {
    const stepsAmountDone = graph.shortestPath(start, cleanNodeName).weight || 0;
    stepsAmountLeft = graph.shortestPath(cleanNodeName, end).weight || 0;

    totalSteps = stepsAmountDone + stepsAmountLeft;
  } catch (e) {
    // current step might be the dead end one, so shortestPath can throw an error
    console.error(`${cleanNodeName} ${e}`);
    totalSteps = graph.shortestPath(start, end).weight || 0;
    stepsAmountLeft = totalSteps;
  }

  return ((totalSteps - stepsAmountLeft) / totalSteps) * 100;
};

export {
  createNewScreenerKey,
  getScreenerIdFromCookie,
  getScreenerProgress,
  validateAndGenerateNewScreenerKey,
  validateScreener,
};
