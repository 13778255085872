import type { IHit } from "@flagship.io/react-sdk";
import { useFlagship } from "@flagship.io/react-sdk";

import Bugsnag from "@Lib/bugsnag";

const useBoxtFlagship = () => {
  const { hit } = useFlagship();

  const fireFlagshipEvent = async (payload: IHit) => {
    try {
      await hit.send(payload);
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  return { fireFlagshipEvent };
};

export default useBoxtFlagship;
