import { useMemo } from "react";
import { breakpoints } from "@boxt/design-system";

import type { Background, Breakpoints } from "@Components/pages/common/LandingWhySection/types";
import useMedia from "@Hooks/useMedia";

const getScreenSize = (isMd: boolean, isLg: boolean, isXl: boolean): Breakpoints => {
  if (isXl) return "xl";
  if (isLg) return "lg";
  if (isMd) return "md";

  return "sm";
};

const useBackgroundUrl = (background: Background[]) => {
  const isMd = useMedia(`(min-width: ${breakpoints.md.width}) and (max-width: ${breakpoints.lg.width})`);
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width}) and (max-width: ${breakpoints.xlg.width})`);
  const isXl = useMedia(`(min-width: ${breakpoints.xlg.width})`);

  return useMemo(() => {
    const urlsMap: Record<Breakpoints, string> = {
      lg: background?.[0]?.lg || "",
      md: background?.[0]?.md || "",
      sm: background?.[0]?.sm || "",
      xl: background?.[0]?.xl || "",
    };

    return urlsMap[getScreenSize(isMd, isLg, isXl)];
  }, [isMd, isLg, isXl, background]);
};

export default useBackgroundUrl;
