import styled from "styled-components";
import { breakpoint, colors } from "@boxt/design-system";

export const BackgroundContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  border: 1px solid;
  border-color: ${colors.white};
  cursor: not-allowed;
  width: 100%;
  text-align: left;
  display: block;

  ${breakpoint("lg")} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
