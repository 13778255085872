import { scaleLinear } from "d3-scale";

export const latLangToPixelInViewPort = (
  map: google.maps.Map,
  latlng: google.maps.LatLng,
): undefined | { x: number; y: number } => {
  // if map been recenter, you should execute this function again since boundaries been changed !!
  const latLngBounds = map?.getBounds();
  // if map not centered yet due to async nature of execution, getBounds() will return undefined
  if (!latLngBounds) return undefined;
  const neBound = latLngBounds.getNorthEast();
  const swBound = latLngBounds.getSouthWest();
  const neBoundInPx = map?.getProjection()?.fromLatLngToPoint(neBound);
  const swBoundInPx = map?.getProjection()?.fromLatLngToPoint(swBound);
  const worldCoordinates = map?.getProjection()?.fromLatLngToPoint(latlng);
  // by getting map coordinate boundaries and view port pixel boundaries,
  // scale is created to convert coordinates to pixel
  const xScale = scaleLinear()
    .domain([swBoundInPx?.x as number, neBoundInPx?.x as number])
    .range([0, window.innerWidth]);
  const yScale = scaleLinear()
    .domain([neBoundInPx?.y as number, swBoundInPx?.y as number])
    .range([0, window.innerHeight]);
  return { x: xScale(worldCoordinates?.x as number), y: yScale(worldCoordinates?.y as number) };
};

export const offsetPanFromCenter = (map: google.maps.Map, offset: { x: number; y: number }): void => {
  const scale = 2 ** Number(map?.getZoom());
  const worldCoordinateCenter = map?.getProjection()?.fromLatLngToPoint(map?.getCenter() as google.maps.LatLng);
  const pixelOffset = new google.maps.Point(offset.x / scale || 0, offset.y / scale || 0);
  const worldCoordinateNewCenter = new google.maps.Point(
    Number(worldCoordinateCenter?.x) - pixelOffset.x,
    Number(worldCoordinateCenter?.y) + pixelOffset.y,
  );
  const newCenter = map?.getProjection()?.fromPointToLatLng(worldCoordinateNewCenter);
  map?.panTo(newCenter as google.maps.LatLng);
};
