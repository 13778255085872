import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const Card = styled.div<{ $backgroundColour?: string }>`
  background-color: ${({ $backgroundColour }) =>
    $backgroundColour ? BACKGROUND_COLORS[$backgroundColour] : `${colors.slate.lightest}`};
  display: flex;
  border-radius: 12px;
  padding: ${rem(40)} ${rem(24)} ${rem(40)} ${rem(24)};
  flex-direction: column;
  align-items: center;
  gap: ${rem(40)};
  margin-top: ${rem(80)};
  margin-bottom: ${rem(80)};

  ${breakpoint("md")} {
    padding: ${rem(40)};
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  gap: ${rem(16)};
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(12)};
`;

export const BulletWrapper = styled.div`
margin-top: ${rem(-2)};
min-width: ${rem(24)};
min-height: ${rem(24)};

${breakpoint("lg")} {
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const IconWrapper = styled.div`
  margin-top: ${rem(3)};
  min-width: ${rem(24)};
  min-height: ${rem(24)};
`;
