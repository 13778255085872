import React from "react";
import useTranslation from "next-translate/useTranslation";
import type { KeenSliderOptions } from "keen-slider";
import { breakpoints, H3, H4, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import Map from "@Components/Map";
import Slider from "@Components/pages/common/Slider/Slider";
import useMedia from "@Hooks/useMedia";

import BulletList from "./components/BulletList";
import {
  BulletItem,
  Container,
  EngineerCard,
  EngineerDetails,
  EngineerImage,
  Header,
  MapContainer,
  MapWrapper,
  MobileHeader,
  SliderDiv,
  WrapperStyle,
} from "./styles";
import type { EngineerMapFields } from "./types";

export type Props = {
  fields: EngineerMapFields;
};

const EngineerMap = ({ fields }) => {
  const { t } = useTranslation("list/index");
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const { card_title, title_body, list_title, list_body, list_items, profile_cards, postcode } = fields;

  const sliderOptions: KeenSliderOptions = {
    breakpoints: {
      [`(min-width: ${breakpoints.sm.width})`]: {
        slides: {
          origin: "center",
          perView: 1,
          spacing: 20,
        },
      },
      [`(min-width: ${breakpoints.md.width})`]: {
        slides: {
          origin: "center",
          perView: 2,
          spacing: 20,
        },
      },
      [`(min-width: ${breakpoints.lg.width})`]: {
        slides: {
          origin: "auto",
          perView: 1,
          spacing: 10,
        },
      },
    },
    initial: 0,
  };

  const bulletListFields = {
    body: list_body,
    bullets: list_items,
    title: list_title,
  };

  return (
    <Container>
      <MobileHeader data-testid="mobile-header">
        <H3 align="left">{card_title}</H3>
        <Paragraph align="left" boxtTheme="slateDark">
          {title_body}
        </Paragraph>
      </MobileHeader>
      <MapContainer>
        <Map
          customMapWrapper={MapWrapper}
          initialZoom={14}
          isForegroundMap
          postcode={postcode ? postcode : "WC1R4AP"}
          renderMarkerOnLoad={false}
        />
        <Header data-testid="header">
          <Spacing mb={1}>
            <H3 align="left">{card_title}</H3>
          </Spacing>
          <Paragraph align="left" boxtTheme="slateDark">
            {title_body}
          </Paragraph>
        </Header>
        <SliderDiv>
          <Slider
            arrowTheme={{ color: "lighter", position: "outside", size: "small" }}
            duration={0}
            initiallyLoop={false}
            showArrows
            showDotsController={isMobile}
            sliderOptions={sliderOptions}
            wrapperStyle={WrapperStyle}
          >
            {profile_cards.map((card, index) => (
              <EngineerCard className={`keen-slider__slide number-slide${index}`} key={index}>
                <EngineerImage
                  alt={t("engineer-map.engineer-image-alt")}
                  height={isDesktop ? 155 : 100}
                  objectFit="cover"
                  src={card.installer_image}
                  width={isDesktop ? 250 : 200}
                />
                <EngineerDetails>
                  {isDesktop ? (
                    <H4>{card.name}</H4>
                  ) : (
                    <Paragraph boxtTheme="slate" weight="bold">
                      {card.name}
                    </Paragraph>
                  )}
                  <Spacing mv={1}>
                    <BulletItem>
                      <Image
                        alt={t("engineer-map.bullet-icon-alt")}
                        height={24}
                        src={card.bullet_one_icon}
                        width={24}
                      />
                      <Paragraph boxtTheme="slateDark" size={isDesktop ? "normal" : "small"}>
                        {card.bullet_one_text}
                      </Paragraph>
                    </BulletItem>
                    <BulletItem>
                      <Image
                        alt={t("engineer-map.bullet-icon-alt")}
                        height={24}
                        src={card.bullet_two_icon}
                        width={24}
                      />
                      <Paragraph boxtTheme="slateDark" size={isDesktop ? "normal" : "small"}>
                        {card.bullet_two_text}
                      </Paragraph>
                    </BulletItem>
                    <BulletItem>
                      <Image
                        alt={t("engineer-map.bullet-icon-alt")}
                        height={24}
                        src={card.bullet_three_icon}
                        width={24}
                      />
                      <Paragraph boxtTheme="slateDark" size={isDesktop ? "normal" : "small"}>
                        {card.bullet_three_text}
                      </Paragraph>
                    </BulletItem>
                  </Spacing>
                </EngineerDetails>
              </EngineerCard>
            ))}
          </Slider>
        </SliderDiv>
      </MapContainer>
      <BulletList fields={bulletListFields} />
    </Container>
  );
};

export default EngineerMap;
