"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { colors } from "@boxt/design-system";

export const Container = styled.div`
  background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.15).toRgbString()};
  border-radius: 16px;
  overflow: hidden;
`;

export const BogofContainer = styled.div`
  border: 1px solid ${colors.coral.lighter};
  background-color: ${colors.coral.lightest};
  border-radius: 4px;
  overflow: hidden;
`;
