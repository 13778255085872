export const XLG_MAX_TILE_WIDTH = 320;

export const LG_TILE_WIDTH = 288;
export const LG_MAX_TILE_HEIGHT = 360;
export const LG_TILE_HEIGHT = 172;
export const LG_COL_PADDING = 16;

export const MD_TILE_WIDTH = 348;
export const MD_MAX_TILE_HEIGHT = 236;
export const MD_TILE_HEIGHT = 114;
export const COL_PADDING = 8;

export const SM_TILE_WIDTH = 187;
export const SM_MAX_TILE_HEIGHT = 232;
export const SM_TILE_HEIGHT = 112;
