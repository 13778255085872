import type { RefObject } from "react";
import { isEmpty } from "lodash-es";
import { breakpoints, Button, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import type { ButterProductTile } from "@Components/pages/common/LandingProductTiles/types";
import useMedia from "@Hooks/useMedia";

import type { ButterLandingHeroCallToActionButton, ButterLandingHeroScrollButton } from "../../types";

import HomePageButtons from "./HomePageButtons";
import { CtaContainer } from "./styles";

export type Props = {
  callToActionButtons: ButterLandingHeroCallToActionButton[];
  scrollTargetRef?: RefObject<HTMLDivElement | null>;
  scrollButton?: ButterLandingHeroScrollButton[];
  heroVariant?: string;
  bottomSheetTiles?: ButterProductTile[];
};

const CtaButtons = ({ callToActionButtons, scrollTargetRef, scrollButton, heroVariant, bottomSheetTiles }: Props) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const getButtonSize = () => {
    if (isDesktop) {
      return "large";
    }

    if (isMobile && callToActionButtons.length > 1) {
      return "small";
    }

    return "medium";
  };

  const handleScrollButtonClick = () => {
    if (scrollTargetRef?.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <CtaContainer>
        {heroVariant === "homepage" && (
          <HomePageButtons
            bottomSheetTiles={bottomSheetTiles}
            data-testid="finish-quote-button"
            getButtonSize={getButtonSize}
            handleScrollButtonClick={handleScrollButtonClick}
            isMobile={isMobile}
            scrollButton={scrollButton}
          />
        )}
        {callToActionButtons.map(({ text, url, theme, skin }) => (
          <Spacing key={createKey(text)} mb={{ md: 0, sm: 1 }} mr={{ md: 1, sm: 0 }}>
            <Button
              boxtTheme={isEmpty(theme) ? "coral" : theme.theme}
              data-testid="cta-button"
              href={url}
              isFullWidth={isMobile}
              size={getButtonSize()}
              skin={skin.name}
            >
              {text}
            </Button>
          </Spacing>
        ))}
      </CtaContainer>
    </>
  );
};

export default CtaButtons;
