import type { ButterLandingHeroFields } from "../../types";
import Badge from "../Badge";
import ForegroundImage from "../ForegroundImage";

export type Props = {
  hasForegroundImage: boolean;
  isForegroundImageMaxWidth: boolean;
  fields: ButterLandingHeroFields;
  hasBadge: boolean;
};

const ForegroundImages = ({ hasForegroundImage, isForegroundImageMaxWidth, fields, hasBadge }: Props) => {
  return (
    <>
      {hasForegroundImage && (
        <ForegroundImage
          images={fields.foreground_images || []}
          isForegroundImageMaxWidth={isForegroundImageMaxWidth}
        />
      )}
      {hasBadge && <Badge badge={fields.badge as string} badgeAltText={fields.badge_alt_text as string} />}
    </>
  );
};

export default ForegroundImages;
