import type { EventArgs } from "react-ga";
import { breakpoints } from "@boxt/design-system";

import Image from "@Components/Image";
import ButtonsSection from "@Components/pages/common/LandingWhySection/ButtonsSection/ButtonsSection";
import Heading from "@Components/pages/common/LandingWhySection/Heading/Heading";
import { Container, StyledVideo, VideoWrapper } from "@Components/pages/common/LandingWhySection/styles";
import { ContentWrapper } from "@Components/pages/common/LandingWhySection/styles";
import type { ButterWhySectionFields } from "@Components/pages/common/LandingWhySection/types";
import useBackgroundUrl from "@Components/pages/common/LandingWhySection/useBackgroundUrl";
import WhySectionDetails from "@Components/pages/common/LandingWhySection/WhySectionDetails/WhySectionDetails";
import useMedia from "@Hooks/useMedia";

export type Props = {
  content: ButterWhySectionFields;
  gaScrollEvent?: EventArgs;
};

const LandingWhySection = ({ content, gaScrollEvent }: Props) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const backgroundUrl: string = useBackgroundUrl(content.background);

  return (
    <Container id={content?.id}>
      {Boolean(backgroundUrl) && <Image alt="" layout="fill" objectFit="cover" src={backgroundUrl} />}
      {!isMobile && Boolean(content.video_background) && (
        <VideoWrapper>
          <StyledVideo autoPlay loop muted>
            <source src={content.video_background} type="video/mp4" />
          </StyledVideo>
        </VideoWrapper>
      )}
      <ContentWrapper>
        <Heading
          header={content.header}
          headerTheme={content?.header_theme?.name}
          secondaryText={content.secondary_text}
        />
        <WhySectionDetails
          bulletPoints={content.content_bullet_points_5050}
          bulletPointsIcon={content.content_bullet_point_icon_5050}
          contentHeader={content.content_header_5050}
          contentLogo={content.content_logo_5050}
          imageSrc={content.image_5050}
        />
        {Boolean(content.main_cta_text) && (
          <ButtonsSection
            gaScrollEvent={gaScrollEvent}
            mainCtaText={content.main_cta_text}
            mainCtaTheme={content.main_cta_theme?.theme || "coral"}
            secondaryCtaText={content.secondary_cta_text}
            secondaryCtaTheme={content.secondary_cta_theme?.theme || "dark"}
            secondaryCtaURL={content.secondary_cta_url}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

export default LandingWhySection;
