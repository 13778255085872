import { useEffect, useState } from "react";
import { useSpring } from "react-spring";
import useTranslation from "next-translate/useTranslation";
import { Button, H5 } from "@boxt/design-system";

import { ReactComponent as Arrow } from "@Images/icons/top-arrow.svg";

import {
  AnimatedContainer,
  ButtonText,
  ContentWrapper,
  HeadingContainer,
  Subheading,
  SubheadingContainer,
} from "./styles";

export type Props = {
  heading?: string;
  subheading?: string;
};

const BottomBar = ({ heading, subheading }: Props) => {
  const { t } = useTranslation("common");

  const [isBottomBarVisible, setIsBottomBarVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 650) {
      setIsBottomBarVisible(true);
    } else if (window.scrollY <= 650) {
      setIsBottomBarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const animateBottom = useSpring({
    bottom: isBottomBarVisible ? 0 : -300,
    config: { mass: 0.5, tension: 220 },
  });

  const handleClick = (event) => {
    event.stopPropagation();

    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  return (
    <AnimatedContainer data-testid="butter-landing-hero-bottom-bar" style={animateBottom}>
      <ContentWrapper>
        {/* div needed for styling*/}
        <div>
          <HeadingContainer>
            <H5 bottom={1} top={{ lg: 4, sm: 0 }}>
              {heading}
            </H5>
          </HeadingContainer>
          <SubheadingContainer>
            <Subheading boxtTheme="slateLight" size="medium">
              {subheading}
            </Subheading>
          </SubheadingContainer>
        </div>
        <Button
          boxtTheme="jade"
          icon={{
            afix: "left",
            size: "medium",
            svg: <Arrow />,
          }}
          onClick={handleClick}
        >
          <ButtonText>{t("actions.get-started")}</ButtonText>
        </Button>
      </ContentWrapper>
    </AnimatedContainer>
  );
};

export default BottomBar;
