import Skeleton from "react-loading-skeleton";
import useTranslation from "next-translate/useTranslation";
import { kebabCase } from "lodash-es";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import type { BatterySize } from "@DataAccess/remote/solar";

import {
  Container,
  CopyContainer,
  IconContainer,
  ImageContainer,
  LabelInnerContainer,
  LabelSpacing,
  StyledSpan,
  TopRowContainer,
} from "../BatteryOption/styles";

import { BackgroundContainer } from "./styles";

type Props = {
  batterySize: BatterySize;
};

const LoadingBatteryOption = ({ batterySize }: Props) => {
  const { t } = useTranslation("solar/savings-calculator");

  return (
    <BackgroundContainer data-testid="battery-loading">
      <LabelSpacing>
        <LabelInnerContainer>
          <Container>
            <TopRowContainer>
              <ImageContainer>
                <Skeleton containerTestId="skeleton-loader" height={100} width={55} />
              </ImageContainer>
              <CopyContainer>
                <Spacing as={StyledSpan} mr={{ lg: 0, sm: 2 }} mv={{ sm: 0, xlg: 2 }}>
                  <H5 as={StyledSpan} bottom={{ lg: 3, sm: 2 }}>
                    <Skeleton containerTestId="skeleton-loader" />
                  </H5>
                  <Paragraph as={StyledSpan} bottom={{ lg: 5, md: 0 }} boxtTheme="slate">
                    {t(`battery.widget.${kebabCase(batterySize)}.explanation`)}
                  </Paragraph>
                </Spacing>
              </CopyContainer>
            </TopRowContainer>
            <IconContainer $isSelected={false} />
          </Container>
        </LabelInnerContainer>
      </LabelSpacing>
    </BackgroundContainer>
  );
};

export default LoadingBatteryOption;
