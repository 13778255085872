import type { KeenSliderOptions } from "keen-slider/react";
import { breakpoints, Grid, H5, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import type { ButterThreeColumnFields, ImageColumn } from "@Components/pages/common/Butter3Column/types";
import Slider from "@Components/pages/common/Slider";

import { ImageContainer, Slide, TextContainer } from "./styles";

type Props = {
  fields: ButterThreeColumnFields;
  keyName: string;
  idAttribute: string;
};

export const Butter3Column = ({ fields, keyName, idAttribute }: Props) => {
  const { slides } = fields;

  const sliderConfig: KeenSliderOptions = {
    breakpoints: {
      [`(max-width: ${breakpoints.sm.width})`]: {
        initial: 0,
        loop: false,
        slides: {
          perView: 1,
          spacing: 30,
        },
      },
      [`(min-width: ${breakpoints.sm.width})`]: {
        initial: 0,
        loop: false,
        slides: {
          perView: 1,
          spacing: 30,
        },
      },
      [`(max-width: ${breakpoints.md.width})`]: {
        initial: 0,
        loop: false,
        slides: {
          perView: 1,
          spacing: 30,
        },
      },
      [`(min-width: ${breakpoints.md.width})`]: {
        initial: 0,
        loop: false,
        slides: {
          perView: 2,
          spacing: 30,
        },
      },
      [`(min-width: ${breakpoints.lg.width})`]: {
        initial: 0,
        loop: false,
        slides: {
          perView: 3,
          spacing: 30,
        },
      },
    },
  };

  return (
    <Grid id={idAttribute}>
      <Grid.Row>
        <Grid.Col lg={{ offset: 1, span: 14 }}>
          <Slider initiallyLoop={false} sliderOptions={sliderConfig}>
            {slides.map((slide: ImageColumn, i) => (
              <Slide className={`keen-slider__slide number-slide-${i}`} key={`${keyName}-${i}`}>
                <ImageContainer>
                  <Image alt={slide.alt} layout="fill" objectFit="cover" src={slide.image} />
                </ImageContainer>
                {slide.title && slide.description && (
                  <TextContainer>
                    <Spacing mb={1} mt={3}>
                      <H5>{slide.title}</H5>
                    </Spacing>
                    <Paragraph>{slide.description}</Paragraph>
                  </TextContainer>
                )}
              </Slide>
            ))}
          </Slider>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
