// this adds &shy; (soft hyphen that suggests a place where the browser might choose to break the word if necessary)
export const addSoftHyphens = (str, maxLength = 10) =>
  str
    .split(" ")
    .map((word) => {
      const secondPart = word.slice(maxLength, word.length);
      return word.length > maxLength
        ? `${word.slice(0, maxLength)}\u00AD${secondPart.length > maxLength ? addSoftHyphens(secondPart) : secondPart}`
        : word;
    })
    .join(" ");
