import styled from "styled-components";
import { breakpoint, Caption, colors, Grid, Paragraph, rem } from "@boxt/design-system";

import { ReactComponent as QuotationMarkSVG } from "@Images/quotation-mark.svg";

import {
  LG_MAX_CARD_WIDTH,
  LG_MAX_CARDS_VISIBLE,
  LG_SLIDER_PADDING,
  MD_MAX_CARD_WIDTH,
  MD_MAX_CARDS_VISIBLE,
  SLIDER_PADDING,
  SM_MAX_CARD_WIDTH,
  SM_MAX_CARDS_VISIBLE,
} from "./constants";

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.slate.light};
`;

export const Container = styled.div`
  .keen-slider .keen-slider__slide {
    overflow: initial;
    min-height: initial;
    align-self: flex-start;
  }
`;

export const StyledCaption = styled(Caption)`
  font-size: ${rem(16)};
  line-height: ${rem(21)};
  margin: ${rem(27)} 0;

  ${breakpoint("md")} {
    font-size: ${rem(18)};
  }

  ${breakpoint("lg")} {
    font-size: ${rem(16)};
  }
`;

export const CardContainer = styled.a<{ $hasLink: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: auto;
  text-decoration: none;
  cursor: ${({ $hasLink }) => $hasLink && "pointer"};
`;

export const StyledGridRow = styled(Grid.Row)`
  margin-left: 0;
  margin-right: 0;

  ${breakpoint("sm")} {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0;
  }

  ${breakpoint("lg")} {
    margin-right: 0;
  }
`;

export const StyledQuotationMark = styled(QuotationMarkSVG)`
  width: ${rem(24)};
  height: ${rem(19)};
`;

export const StyledGridCol = styled.div<{ $length: number }>`
  padding: 0;
  width: 100%;

  ${breakpoint("sm")} {
    max-width: ${({ $length }) =>
      rem(Math.min(SM_MAX_CARDS_VISIBLE, $length) * (SM_MAX_CARD_WIDTH + SLIDER_PADDING) - SLIDER_PADDING)};
    margin: 0 auto;
  }

  ${breakpoint("md")} {
    max-width: ${({ $length }) =>
      rem(Math.min(MD_MAX_CARDS_VISIBLE, $length) * (MD_MAX_CARD_WIDTH + SLIDER_PADDING) - SLIDER_PADDING)};
  }

  ${breakpoint("lg")} {
    max-width: ${({ $length }) =>
      rem(Math.min(LG_MAX_CARDS_VISIBLE, $length) * (LG_MAX_CARD_WIDTH + LG_SLIDER_PADDING) - LG_SLIDER_PADDING)};
    padding: 0 ${rem(58)};
  }

  ${breakpoint("max")} {
    padding: 0;
  }
`;
