import { isEmpty } from "lodash-es";
import type { ButtonTheme } from "@boxt/design-system";
import { Button, Grid, H4, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";

import { ButtonWrapper, Container, ContentWrapper, ImageWrapper, Wrapper } from "./styles";
import type { ButterRedirectFields } from "./types";

const defaultValues: { bg: string; ctaTheme: ButtonTheme; target: HTMLAnchorElement["target"] } = {
  bg: "platinumLighter",
  ctaTheme: "jade",
  target: "_self",
};

export type Props = {
  fields: ButterRedirectFields;
};

const LandingRedirect = ({ fields }: Props) => {
  return (
    <Grid.Row data-testid="landing-redirect" id={fields.id}>
      <Grid.Col lg={{ offset: 2, span: 12 }}>
        <Spacing mh={2} mv={3}>
          <Container $bgColor={fields.card_theme.background_color || defaultValues.bg}>
            <Wrapper>
              {fields.image && (
                <ImageWrapper>
                  <Image alt={fields.image_alt} height={60} src={fields.image} width={165} />
                </ImageWrapper>
              )}
              <Spacing mh={{ md: 1, sm: 0 }} mv={{ md: 0, sm: 2 }}>
                {fields.explainer ? (
                  <ContentWrapper>
                    <H4 bottom={2}>{fields.title}</H4>
                    <Paragraph>{fields.explainer}</Paragraph>
                  </ContentWrapper>
                ) : (
                  <H4>{fields.title}</H4>
                )}
              </Spacing>
            </Wrapper>
            <ButtonWrapper>
              <Button
                boxtTheme={fields.cta_theme?.theme || defaultValues.ctaTheme}
                href={fields.cta_url}
                rel="noopener noreferrer"
                target={isEmpty(fields.cta_target) ? defaultValues.target : fields.cta_target}
              >
                {fields.cta_text}
              </Button>
            </ButtonWrapper>
          </Container>
        </Spacing>
      </Grid.Col>
    </Grid.Row>
  );
};

export default LandingRedirect;
