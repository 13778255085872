"use client";

import styled, { css } from "styled-components";
import { rem, Spacing } from "@boxt/design-system";

export const WrapperStyle = css`
  max-height: ${rem(100)};
`;

export const SlideWrapper = styled.div<{ $opacity: string }>`
  position: relative;
  ${({ $opacity }) => `opacity: ${$opacity}`};

  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(Spacing)`
  display: flex;
  flex-direction: column;
`;
