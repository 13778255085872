"use client";

import styled from "styled-components";
import { colors, Paragraph } from "@boxt/design-system";

export const TermsWrapper = styled.div`
  display: flex;
`;

export const StyleParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`;

export const StyledLink = styled.a`
  color: ${colors.coral.normal};
`;
