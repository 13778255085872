import Skeleton from "react-loading-skeleton";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { Button, type ButtonProps, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import ButterQuoteBottomSheet from "@Components/pages/common/ButterQuoteBottomSheet";
import { LoadingIcon } from "@Components/pages/common/ButterTitleHero/components/Buttons/styles";
import type { ButterProductTile } from "@Components/pages/common/LandingProductTiles/types";
import useQuotes from "@Hooks/useQuotes";

import type { ButterLandingHeroScrollButton } from "../../../types";

export type Props = {
  bottomSheetTiles?: ButterProductTile[];
  getButtonSize: () => ButtonProps["size"];
  isMobile: boolean;
  scrollButton?: ButterLandingHeroScrollButton[];
  handleScrollButtonClick: () => void;
};

const HomePageButtons = ({
  bottomSheetTiles = [],
  getButtonSize,
  isMobile,
  scrollButton,
  handleScrollButtonClick,
}: Props) => {
  const { t } = useTranslation("home-page/index");
  const { t: tCommon } = useTranslation("common");

  const {
    finishQuoteTiles,
    handleFinishQuoteClick,
    hasExistingScreeners,
    isLoading,
    isFinishQuoteLoading,
    isFinishQuoteBottomSheetOpen,
    isNewQuoteBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
    setIsNewQuoteBottomSheetOpen,
  } = useQuotes();

  const getButtonSkin = () => {
    return (hasExistingScreeners && "secondary") || scrollButton?.[0].skin.name || "primary";
  };

  return (
    <>
      {isLoading ? (
        <Spacing mt={5}>
          <Skeleton containerTestId="skeleton-loader" width="20%" />
        </Spacing>
      ) : (
        <>
          {hasExistingScreeners && (
            <Spacing key={createKey("finish-quote-button")} mb={{ md: 0, sm: 1 }} mr={{ md: 1, sm: 0 }}>
              <Button
                boxtTheme="jade"
                data-testid="finish-quote-button"
                isFullWidth={isMobile}
                loadingConfig={{
                  copy: tCommon("loading"),
                  icon: {
                    afix: "left",
                    size: "medium",
                    svg: <LoadingIcon />,
                  },
                  isLoading: isFinishQuoteLoading,
                }}
                onClick={handleFinishQuoteClick}
                size={getButtonSize()}
                skin="primary"
              >
                {t("finish-your-quote")}
              </Button>
            </Spacing>
          )}
          {scrollButton?.[0] && (
            <Spacing key={createKey(scrollButton?.[0].text as string)} mb={{ md: 0, sm: 1 }} mr={{ md: 1, sm: 0 }}>
              <Button
                boxtTheme={isEmpty(scrollButton[0].theme) ? "coral" : scrollButton[0].theme.theme}
                data-testid="scroll-button"
                isFullWidth={isMobile}
                onClick={handleScrollButtonClick}
                size={getButtonSize()}
                skin={getButtonSkin()}
              >
                {scrollButton[0].text}
              </Button>
            </Spacing>
          )}
        </>
      )}
      <ButterQuoteBottomSheet
        finishQuoteTiles={finishQuoteTiles}
        isFinishQuoteBottomSheetOpen={isFinishQuoteBottomSheetOpen}
        isNewQuoteBottomSheetOpen={isNewQuoteBottomSheetOpen}
        newQuoteTiles={bottomSheetTiles}
        setIsFinishQuoteBottomSheetOpen={setIsFinishQuoteBottomSheetOpen}
        setIsNewQuoteBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
      />
    </>
  );
};

export default HomePageButtons;
