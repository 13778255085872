import useTranslation from "next-translate/useTranslation";
import { Grid, Paragraph, ToolTip } from "@boxt/design-system";

import { ReactComponent as Cross } from "@Images/icons/comparison-cross.svg";
import { ReactComponent as Tick } from "@Images/icons/comparison-tick.svg";
import { ReactComponent as Info } from "@Images/icons/comparison-tooltip.svg";

import { AnswerCol, IconWrapper, TooltipWrapper } from "./styles";
import type { Answer, Theme } from "./types";

type Props = {
  answer: Omit<Answer, "key">;
  isButterComponent?: boolean;
  theme: Theme;
  hoverBehaviour: "click" | "hover";
  isLastRow: boolean;
  hasOneGroup: boolean;
  i18nNamespace?: string;
};

export const AnswerChild = ({
  answer,
  isButterComponent,
  theme,
  hoverBehaviour,
  isLastRow,
  hasOneGroup,
  i18nNamespace,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { icon, answer_text, tooltipText, bold } = answer;

  return (
    <Grid.Col
      $hasTooltip={Boolean(tooltipText)}
      $isLastRow={isLastRow}
      $theme={theme}
      as={AnswerCol}
      lg={3}
      md={2}
      role="cell"
      sm={hasOneGroup ? 2 : 1}
    >
      {answer_text ? (
        <>
          <Paragraph align="center" boxtTheme="slate" weight={bold ? "bold" : "regular"}>
            {isButterComponent ? t("butter-comparison-table", { answer_text }) : answer_text}
          </Paragraph>
          {tooltipText && (
            <TooltipWrapper>
              <ToolTip toolTipText={tooltipText} triggerAction={hoverBehaviour}>
                <Info />
              </ToolTip>
            </TooltipWrapper>
          )}
        </>
      ) : (
        <IconWrapper>{icon === "round-tick" ? <Tick /> : <Cross />}</IconWrapper>
      )}
    </Grid.Col>
  );
};
