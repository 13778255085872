import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { breakpoints, Button, Spacing } from "@boxt/design-system";

import type { ButterButtonCollectionItem, ButtonSkinItem } from "@Collections/types/butter/collectionItem";
import { LoadingIcon } from "@Components/pages/common/ButterTitleHero/components/Buttons/styles";
import useMedia from "@Hooks/useMedia";

import { Container } from "./styles";

export type Props = {
  isLoading: boolean;
  onFinishQuote: () => void;
  onStartQuote: () => void;
  startQuoteButtonText: string | undefined;
  finishQuoteButtonText: string | undefined;
  finishQuoteButtonTheme: ButterButtonCollectionItem | undefined;
  finishQuoteButtonSkin: ButtonSkinItem | undefined;
  startQuoteButtonSkin: ButtonSkinItem | undefined;
  startQuoteButtonTheme: ButterButtonCollectionItem | undefined;
};
export const getButtonSize = (isDesktop: boolean, isMobile: boolean) => {
  if (isDesktop) {
    return "large";
  }

  if (isMobile) {
    return "small";
  }

  return "medium";
};

const FinishQuote = ({
  isLoading,
  onFinishQuote,
  onStartQuote,
  startQuoteButtonText,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  finishQuoteButtonSkin,
  startQuoteButtonSkin,
  startQuoteButtonTheme,
}: Props) => {
  const { t } = useTranslation("common");

  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);

  return (
    <Container>
      <Spacing mb={{ md: 0, sm: 1 }} mr={{ md: 1, sm: 0 }}>
        <Button
          boxtTheme={isEmpty(finishQuoteButtonTheme) ? "coral" : finishQuoteButtonTheme?.theme}
          isFullWidth={isMobile}
          loadingConfig={{
            copy: t("loading"),
            icon: {
              afix: "left",
              size: "medium",
              svg: <LoadingIcon />,
            },
            isLoading,
          }}
          onClick={onFinishQuote}
          size={getButtonSize(isDesktop, isMobile)}
          skin={isEmpty(finishQuoteButtonSkin) ? "primary" : finishQuoteButtonSkin?.name}
        >
          {finishQuoteButtonText}
        </Button>
      </Spacing>
      <Button
        boxtTheme={isEmpty(startQuoteButtonTheme) ? "coral" : startQuoteButtonTheme?.theme}
        data-testid="start-quote-button"
        isFullWidth={isMobile}
        onClick={onStartQuote}
        size={getButtonSize(isDesktop, isMobile)}
        skin={isEmpty(startQuoteButtonSkin) ? "primary" : startQuoteButtonSkin?.name}
      >
        {startQuoteButtonText}
      </Button>
    </Container>
  );
};

export default FinishQuote;
