import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const VideoButton = styled.button<{ $isForegroundVideo: boolean | undefined }>`
  padding: 0;
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  width: ${rem(40)};
  height: ${rem(40)};
  position: absolute;
  top: ${rem(80)};
  right: ${rem(12)};
  background: none;
  z-index: 20;

  ${breakpoint("md")} {
    right: ${rem(24)};
  }

  ${breakpoint("lg")} {
    right: ${({ $isForegroundVideo }) => ($isForegroundVideo ? "initial" : rem(48))};
    top: ${({ $isForegroundVideo }) => ($isForegroundVideo ? "initial" : rem(95))};
    left: ${({ $isForegroundVideo }) => ($isForegroundVideo ? rem(24) : "initial")};
    bottom: ${({ $isForegroundVideo }) => ($isForegroundVideo ? rem(24) : "initial")};
  }
`;
