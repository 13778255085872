const STANDING_CHARGE_IN_PENCE = 54;
const DAYS_IN_YEAR = 365;
const STANDING_CHARGE_COST = STANDING_CHARGE_IN_PENCE * DAYS_IN_YEAR;

export const costOfBillWithoutProduct = ({
  annualElectricityUsage,
  dayRate,
  hasDifferentDayNightRate,
  nightRate,
  singleRate,
}: {
  annualElectricityUsage: number;
  dayRate: number;
  hasDifferentDayNightRate: boolean;
  nightRate: number;
  singleRate: number;
}) => {
  if (hasDifferentDayNightRate) {
    const dayRateCost = dayRate * annualElectricityUsage * 0.6;
    const nightRateCost = nightRate * annualElectricityUsage * 0.4;

    return dayRateCost + nightRateCost + STANDING_CHARGE_COST;
  }

  return Number(singleRate) * annualElectricityUsage + STANDING_CHARGE_COST;
};

export const costPerUnitKey = (hasDifferentDayNightRate: boolean) =>
  hasDifferentDayNightRate ? "dual-rate" : "single-rate";

export const costOfBillWithProduct = ({
  costOfBillWithoutProduct,
  annualSavings,
}: {
  costOfBillWithoutProduct: number;
  annualSavings: number;
}) => {
  const cost = costOfBillWithoutProduct - annualSavings;

  if (cost > 0) {
    return cost;
  }

  return 0;
};
