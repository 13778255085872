import { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";
import { breakpoints, Button, Grid, H4, H5 } from "@boxt/design-system";

import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import { getDOMParserOptions } from "../HTMLParser/helpers";
import ScrollToButton from "../ScrollToButton";

import { AnswerChild } from "./AnswerChild";
import { getTableRows } from "./getTableRows";
import { QuestionChild } from "./QuestionChild";
import {
  BackgroundImage,
  BottomColumn,
  BottomTextWrapper,
  BottomWrapper,
  ButtonsWrapper,
  Container,
  GridContainer,
  HeadingWrapper,
  ImageCol,
  RowWrapper,
  StyledGrid,
  TopTextWrapper,
} from "./styles";
import type { Breakpoints, ButterComparisonTableFields, ComparisonGroup } from "./types";

export type Props = {
  fields: ButterComparisonTableFields;
  i18nNamespace: string;
  testId?: string;
};

const getScreenSize = (isXlg: boolean, isLg: boolean, isMd: boolean): "sm" | "md" | "lg" | "xl" => {
  if (!isXlg && !isLg && isMd) return "md";
  if (!isXlg && isLg && isMd) return "lg";
  if (isXlg && isLg && isMd) return "xl";
  return "sm";
};

export const ButterComparisonTable = ({ fields, i18nNamespace, testId }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const {
    title,
    background,
    body_text,
    bottom_text,
    questions,
    column_one_key,
    column_one_name,
    column_one_theme,
    column_one_image,
    column_one_answers,
    column_two_key,
    column_two_name,
    column_two_theme,
    column_two_image,
    column_two_answers,
    main_cta_text,
    main_cta_url,
    main_cta_theme,
    secondary_cta_text,
    secondary_cta_theme,
    secondary_cta_skin,
    secondary_cta_url,
    background_colour,
    background_gradient_center_x_axis,
    background_gradient_center_y_axis,
    background_linear_gradient_degree,
    background_gradients,
    background_gradient,
  } = fields;

  const groups: ComparisonGroup[] = [
    {
      answers: column_one_answers,
      image: column_one_image,
      key: column_one_key,
      name: column_one_name,
      theme: column_one_theme,
    },
    {
      answers: column_two_answers,
      image: column_two_image,
      key: column_two_key,
      name: column_two_name,
      theme: column_two_theme,
    },
  ];

  const isSm = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isMd = useMedia(`(min-width: ${breakpoints.md.width})`);
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const isXlg = useMedia(`(min-width: ${breakpoints.xlg.width})`);

  const rows = getTableRows(questions, groups);

  const backgroundUrl: string = useMemo(() => {
    const urlsMap: Record<Breakpoints, string> = {
      lg: background[0]?.lg,
      md: background[0]?.md,
      sm: background[0]?.sm,
      xl: background[0]?.xl,
    };

    return urlsMap[getScreenSize(isXlg, isLg, isMd)];
  }, [background, isLg, isMd, isXlg]);

  const bodyText = dangerouslySetInnerHTML(body_text, getDOMParserOptions()) as JSX.Element;

  const bottomText = dangerouslySetInnerHTML(bottom_text, getDOMParserOptions()) as JSX.Element;

  const hasOneGroup = groups.length === 1;
  const hasButtons = Boolean(main_cta_text) || Boolean(secondary_cta_text);

  return (
    <Container
      $backgroundColor={background_colour}
      $gradientCenterXAxis={background_gradient_center_x_axis}
      $gradientCenterYAxis={background_gradient_center_y_axis}
      $gradientDegree={background_linear_gradient_degree}
      $gradients={background_gradients}
      $gradientType={background_gradient}
    >
      {Boolean(backgroundUrl) && (
        <BackgroundImage alt={background[0].alt} layout="fill" objectFit="cover" src={backgroundUrl} />
      )}
      <StyledGrid data-testid={testId} role="table">
        <Grid.Row as={GridContainer}>
          <Grid.Col lg={16} md={8} sm={4}>
            <Grid.Row>
              <Grid.Col lg={{ offset: hasOneGroup ? 2 : 1, span: 14 }} md={{ offset: 1, span: 6 }} sm={4}>
                <HeadingWrapper>
                  <H4 align="center">{t("butter-comparison-table.title", { title })}</H4>
                  {Boolean(body_text) && <TopTextWrapper>{bodyText}</TopTextWrapper>}
                </HeadingWrapper>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row as={RowWrapper}>
              <Grid.Col
                lg={{ offset: hasOneGroup ? 3 : 2, span: 6 }}
                md={{ offset: hasOneGroup ? 1 : 0, span: 4 }}
                sm={2}
              />
              {groups.map((group) => (
                <Grid.Col $theme={group.theme} as={ImageCol} key={group.key} lg={3} md={2} role="columnheader" sm={1}>
                  {group.image ? (
                    <Image
                      alt={group.name}
                      height={isSm ? 70 : 80}
                      objectFit="contain"
                      src={group.image}
                      width={isSm ? 120 : 140}
                    />
                  ) : (
                    <H5 align="center">{t("butter-comparison-table.groupName", { groupName: group.name })}</H5>
                  )}
                </Grid.Col>
              ))}
            </Grid.Row>

            {rows.map((row, index) => {
              const isLastRow = index === rows.length - 1;
              const hasTooltip = row.values.some((value) => value.answer.tooltipText);
              return (
                <Grid.Row as={RowWrapper} key={row.question.question_text} role="row">
                  <QuestionChild
                    hasOneGroup={hasOneGroup}
                    hasTooltip={hasTooltip}
                    hoverBehaviour={isSm ? "click" : "hover"}
                    i18nNamespace={i18nNamespace}
                    isLastRow={isLastRow}
                    question={row.question}
                  />
                  {row.values.map((value, index) => (
                    <AnswerChild
                      answer={value.answer}
                      hasOneGroup={hasOneGroup}
                      hoverBehaviour={isSm ? "click" : "hover"}
                      i18nNamespace={i18nNamespace}
                      isLastRow={isLastRow}
                      key={`${value.answer.key}-${index}`}
                      theme={value.theme}
                    />
                  ))}
                </Grid.Row>
              );
            })}
          </Grid.Col>
          <Grid.Row as={BottomWrapper}>
            <Grid.Col as={BottomColumn} lg={{ span: 12 }} md={{ span: 6 }} sm={4}>
              {Boolean(bottom_text) && <BottomTextWrapper>{bottomText}</BottomTextWrapper>}
              {hasButtons && (
                <ButtonsWrapper>
                  {main_cta_url ? (
                    <Button boxtTheme={main_cta_theme.theme} href={main_cta_url}>
                      {t("butter-comparison-table.main_cta_text", { main_cta_text })}
                    </Button>
                  ) : (
                    <ScrollToButton
                      boxtTheme={main_cta_theme.theme}
                      skin="primary"
                      titleText={t("butter-comparison-table.main_cta_text", { main_cta_text })}
                    />
                  )}
                  {secondary_cta_text && (
                    <Button
                      boxtTheme={secondary_cta_theme.theme}
                      href={secondary_cta_url}
                      skin={secondary_cta_skin.name}
                    >
                      {t("butter-comparison-table.secondary_cta_text", { secondary_cta_text })}
                    </Button>
                  )}
                </ButtonsWrapper>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Row>
      </StyledGrid>
    </Container>
  );
};
