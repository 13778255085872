import { Grid, H3, Paragraph } from "@boxt/design-system";

import type { HeaderTheme } from "../types";

export interface Props {
  header: string;
  headerTheme?: HeaderTheme;
  secondaryText: string;
}

const THEME = "slate";
const Heading = ({ header, secondaryText, headerTheme = THEME }: Props) => (
  <>
    <Grid.Row>
      <Grid.Col>
        <H3 bottom={5} boxtTheme={headerTheme}>
          {header}
        </H3>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <Paragraph boxtTheme={headerTheme}>{secondaryText}</Paragraph>
      </Grid.Col>
    </Grid.Row>
  </>
);

export default Heading;
