import Skeleton from "react-loading-skeleton";
import Trans from "next-translate/Trans";
import { Icon, Paragraph, Spacing } from "@boxt/design-system";

import { Container, IconContainer } from "./styles";

type Props = {
  isLoading: boolean;
  i18nNamespace: string;
  paybackPeriod: number;
};

const PaybackPeriod = ({ isLoading, paybackPeriod, i18nNamespace }: Props) => {
  return (
    <Container>
      <Spacing mr={1}>
        <IconContainer>
          <Icon name="calendar" size="small" />
        </IconContainer>
      </Spacing>
      {isLoading ? (
        <Skeleton containerTestId="skeleton-loader" width={100} />
      ) : (
        <Trans
          components={{
            p: <Paragraph boxtTheme="slateDark" size="small" />,
            strong: <Paragraph as="strong" boxtTheme="slateDark" weight="bold" />,
          }}
          i18nKey={`${i18nNamespace}:payback-period`}
          values={{
            paybackPeriod: paybackPeriod.toFixed(1),
          }}
        />
      )}
    </Container>
  );
};

export default PaybackPeriod;
