import Skeleton from "react-loading-skeleton";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import type { Products } from "@Collections/products";
import ElectricityBillWithoutProduct from "@Components/ProductSavings/ElectricitySavings/ElectricityBillWithoutProduct";
import ElectricityBillWithProduct from "@Components/ProductSavings/ElectricitySavings/ElectricityBillWithProduct";
import { BillSavingsContainer } from "@Components/ProductSavings/ElectricitySavings/styles";
import useElectricitySavings from "@Hooks/useElectricitySavings";
import { costPerUnitKey } from "@Hooks/useElectricitySavings/helpers";
import type { YesOrNo } from "@Pages/api/_next/screeners/types";

export type Props = {
  annualCostWithoutBattery?: number;
  annualCostWithBattery?: number;
  annualElectricityUsageAnswer: number;
  annualGridExportInPence?: number;
  annualSavings: number;
  dayUnitPrice: number;
  differentRateAtNight?: YesOrNo;
  i18nNamespace: string;
  isLoading?: boolean;
  nightUnitPrice: number;
  productType: Products.BatteryOnly | Products.Solar;
  singleUnitPrice: number;
  titleKey?: string;
  totalPriceInPence: number;
  hasUnitCosts?: boolean;
  isSingleColumn?: boolean;
  hasAdditionalInfo?: boolean;
  hasDynamicCopy?: boolean;
  hasPaybackPeriod?: boolean;
  monthAndYearPriceCapRate?: string;
  screeningId: string;
};

const ProductSavings = ({
  annualCostWithBattery,
  annualCostWithoutBattery,
  annualGridExportInPence,
  annualElectricityUsageAnswer,
  annualSavings,
  dayUnitPrice,
  differentRateAtNight = "no",
  hasUnitCosts,
  i18nNamespace,
  isLoading,
  isSingleColumn = false,
  hasAdditionalInfo,
  hasDynamicCopy = true,
  nightUnitPrice,
  productType,
  singleUnitPrice,
  hasPaybackPeriod = true,
  titleKey = "title",
  totalPriceInPence,
  monthAndYearPriceCapRate,
  screeningId,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const {
    annualElectricityUsage,
    billWithProduct,
    billWithoutProduct,
    dayRate,
    hasDefaultElectricityRates,
    hasDifferentDayNightRate,
    nightRate,
    paybackPeriod,
    savingsPercentage,
    singleRate,
  } = useElectricitySavings({
    annualElectricityUsageAnswer,
    annualSavings,
    dayUnitPrice,
    differentRateAtNight,
    nightUnitPrice,
    productType,
    singleUnitPrice,
    totalPriceInPence,
  });

  return (
    <div data-testid={`${productType}-savings`}>
      <Spacing mb={2}>
        {isLoading && hasDynamicCopy ? (
          <Paragraph>
            <Skeleton containerTestId="skeleton-loader" width={250} />
          </Paragraph>
        ) : (
          <Trans
            components={{
              p: <Paragraph />,
              strong: <Paragraph as="strong" boxtTheme="slate" weight="bold" />,
            }}
            i18nKey={`${i18nNamespace}:electricity-bill-savings`}
            values={{
              savings: currencyFormatter(annualSavings, 1, 0),
            }}
          />
        )}
      </Spacing>
      <BillSavingsContainer $isSingleColumn={isSingleColumn}>
        <ElectricityBillWithProduct
          annualGridExportInPence={annualGridExportInPence}
          costPerYear={annualCostWithBattery ?? billWithProduct}
          hasAdditionalInfo={hasAdditionalInfo}
          hasPaybackPeriod={hasPaybackPeriod}
          i18nNamespace={i18nNamespace}
          isLoading={Boolean(isLoading)}
          paybackPeriod={paybackPeriod}
          productType={productType}
          savingsPercentage={Number(savingsPercentage)}
          savingsPerYear={annualSavings}
          screeningId={screeningId}
          titleKey={titleKey}
        />
        <ElectricityBillWithoutProduct
          costPerUnit={t(costPerUnitKey(hasDifferentDayNightRate), { dayRate, nightRate, rate: singleRate })}
          costPerYear={annualCostWithoutBattery ?? billWithoutProduct}
          hasDefaultElectricityRates={hasDefaultElectricityRates}
          hasUnitCosts={hasUnitCosts}
          i18nNamespace={i18nNamespace}
          isLoading={Boolean(isLoading)}
          monthAndYearPriceCapRate={monthAndYearPriceCapRate}
          productType={productType}
          screeningId={screeningId}
          titleKey={titleKey}
          usagePerYear={annualElectricityUsage}
        />
      </BillSavingsContainer>
    </div>
  );
};

export default ProductSavings;
