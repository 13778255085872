import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  justify-content: center;
  margin: auto;

  ${breakpoint("lg")} {
    width: 40%;
  }
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  border: none;
  border-top: 2.5px dotted ${colors.platinum.darker};
  margin-bottom: ${rem(10)};
`;

export const IconWrapper = styled.div`
margin-top: ${rem(4)};
min-width: ${rem(24)};
min-height: ${rem(24)};

${breakpoint("lg")} {
  width: ${rem(32)};
  height: ${rem(32)};
`;

export const ListItems = styled.div`
  display: flex;
  gap: ${rem(16)};
  margin-bottom: ${rem(16)};
`;
