import styled from "styled-components";
import tinycolor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import { LG_MAX_CARD_WIDTH, MD_MAX_CARD_WIDTH } from "@Components/pages/common/LandingProductTiles/constants";

export const InfoContentWrapper = styled.div`
  display: flex;
  flex: 1;
  ${includeSpacing({ ml: 3, mr: 2 })};
`;

export const TextContentWrapper = styled.div<{ $isNewQuoteCard: boolean; $isBottomSheet: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  justify-content: ${({ $isBottomSheet }) => $isBottomSheet && "center"};

  ${breakpoint("lg")} {
    justify-content: ${({ $isNewQuoteCard }) => ($isNewQuoteCard ? "center" : "flex-start")};
  }
`;

export const ImageWrapper = styled.div<{ $isBottomSheet: boolean }>`
  position: relative;
  overflow: hidden;
  width: ${({ $isBottomSheet }) => ($isBottomSheet ? `${rem(100)}` : `${rem(200)}`)};
  height: ${({ $isBottomSheet }) => ($isBottomSheet ? `${rem(100)}` : `${rem(200)}`)};
  flex-shrink: 0;
  align-self: center;
  transition: transform 0.2s ease-out;

  ${breakpoint("lg")} {
    width: ${rem(200)};
    height: ${rem(200)};
  }
`;

export const ChevronContainer = styled.div<{ $isBottomSheet: boolean }>`
  border-radius: 50%;
  border: ${rem(1)} solid ${colors.slate.light};
  background: transparent;
  height: 2rem;
  width: 2rem;
  display: flex;
  margin: ${({ $isBottomSheet }) => ($isBottomSheet ? "auto" : 0)};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${includeSpacing({ ml: 1 })};
  svg {
    color: ${colors.slate.light};
  }
`;

export const CardContainer = styled.a<{ $lastItem: boolean; $firstItem: boolean; $isBottomSheet: boolean }>`
  height: 100%;
  background-color: ${colors.slate.lightest};
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: ${({ $isBottomSheet }) => ($isBottomSheet ? "row" : "column")};
  min-height: ${({ $isBottomSheet }) => !$isBottomSheet && `${rem(355)}`};
  overflow: hidden;
  padding: 1.5rem 0 1rem;
  text-align: left;
  text-decoration: none;
  width: auto;
  cursor: pointer;

  ${breakpoint("sm")} {
    width: ${({ $isBottomSheet }) => !$isBottomSheet && `${rem(MD_MAX_CARD_WIDTH)}`};
  }

  ${breakpoint("md")} {
    margin-right: ${({ $lastItem, $isBottomSheet }) => ($lastItem && !$isBottomSheet ? "2rem" : 0)};
    margin-left: ${({ $firstItem, $isBottomSheet }) => ($firstItem && !$isBottomSheet ? "2rem" : 0)};
  }

  ${breakpoint("lg")} {
    width: 100%;
    flex-direction: column;
    min-height: ${rem(355)};
    max-width: ${rem(LG_MAX_CARD_WIDTH)};
    margin-right: 0;
    margin-left: 0;
  }

  &:active {
    cursor: grabbing;
  }

  &:hover {
    background-color: ${tinycolor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
    & ${ImageWrapper} {
      ${breakpoint("lg")} {
        transition: transform 0.2s ease-out;
        transform: scale(1.1);
      }
    }

    & ${ChevronContainer} {
      transition: all 0.2s ease-out;
      background: ${colors.slate.normal};
      svg {
        color: ${colors.white};
      }
    }
  }
`;
