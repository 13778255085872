import { ReactComponent as ChevronIcon } from "@Images/icons/chevron.svg";

import { ChevronContainer } from "./styles";

const Chevron = () => (
  <ChevronContainer>
    <ChevronIcon />
  </ChevronContainer>
);

export default Chevron;
