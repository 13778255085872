import styled from "styled-components";
import { breakpoint, Grid, H2, includeSpacing, Paragraph, rem } from "@boxt/design-system";

import { ReactComponent as TrustpilotSVG } from "@Images/trustpilot/trustpilot-stars-5.svg";

import {
  COL_PADDING,
  LG_COL_PADDING,
  LG_TILE_WIDTH,
  MD_TILE_WIDTH,
  SM_TILE_WIDTH,
  XLG_MAX_TILE_WIDTH,
} from "../constants";

export const TrustpilotStarsSVG = styled(TrustpilotSVG)`
  ${includeSpacing({ ml: 1, mt: 1 })}
  height: ${rem(16)};
  width: ${rem(84)};

  ${breakpoint("md")} {
    height: ${rem(20)};
    width: ${rem(108)};
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledH2 = styled(H2)`
  font-weight: 400;
  font-size: ${rem(32)};

  ${breakpoint("md")} {
    font-size: ${rem(36)};
  }

  ${breakpoint("lg")} {
    font-size: ${rem(48)};
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: ${rem(12)};

  ${breakpoint("md")} {
    font-size: ${rem(14)};
  }

  ${breakpoint("lg")} {
    font-size: ${rem(16)};
  }
`;

export const StyledGridCol = styled(Grid.Col)`
  padding: 0;

  ${breakpoint("sm")} {
    width: ${rem(SM_TILE_WIDTH + COL_PADDING)};
  }

  ${breakpoint("md")} {
    width: ${rem(MD_TILE_WIDTH + COL_PADDING)};
  }

  ${breakpoint("lg")} {
    width: ${rem(LG_TILE_WIDTH + LG_COL_PADDING)};
  }

  ${breakpoint("max")} {
    width: ${rem(XLG_MAX_TILE_WIDTH + LG_COL_PADDING)};
  }
`;

export const StyledGridRow = styled(Grid.Row)`
  margin: 0;
  flex-wrap: nowrap;
  overflow-y: hidden;
`;
