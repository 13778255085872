import type { ElementType } from "react";
import type { AnimatedComponent } from "react-spring";
import { animated, easings, useInView } from "react-spring";

import Image from "@Components/Image";

import { StyledTile, VanImageWrapper } from "../../styles";
import AnimatedNumberComponent from "../AnimatedNumberComponent";
import { StyledParagraph } from "../styles";

type Props = {
  amount: string;
  description: string;
  width?: number;
  height?: number;
  isAnimated: boolean;
};

const FirstTileWithVan = ({ amount, description, width, height, isAnimated }: Props) => {
  const AnimatedDiv: AnimatedComponent<ElementType> = animated.div;
  const [ref, springs] = useInView(() => ({
    config: { duration: 800, easing: easings.easeOutSine },
    from: {
      transform: "translateX(-800px)",
    },
    to: {
      transform: "translateX(0px)",
    },
  }));

  return (
    <StyledTile $firstTile>
      <div ref={ref || null}>
        <AnimatedDiv style={(ref && springs) || null}>
          <VanImageWrapper>
            <Image alt="van image" height={height} src="/_next-public/images/common/van-hero.png" width={width} />
          </VanImageWrapper>
        </AnimatedDiv>
      </div>
      <div>
        <AnimatedNumberComponent amount={amount} isAnimated={isAnimated} />
        <StyledParagraph>{description}</StyledParagraph>
      </div>
    </StyledTile>
  );
};

export default FirstTileWithVan;
