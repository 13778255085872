import type { Answer, ComparisonGroup, Question, Row } from "./types";

export const getTableRows = (questions: Question[], groups: ComparisonGroup[]): Row[] => {
  const rows = questions.map((question) => {
    const values = groups.map((group) => {
      const answer: Answer | undefined = group.answers.find((answer) => answer.key === question.key);
      return { answer, theme: group.theme };
    });

    const groupAnswersAllQuestions = values.filter((answer) => answer.answer?.key).length === groups.length;

    if (groupAnswersAllQuestions) return { question: question, values };
  });

  return (rows.filter((row) => row) as Row[]) || [];
};
