import { BulletPoints, H5, Paragraph, Spacing } from "@boxt/design-system";

import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import Image from "@Components/Image";
import { getDOMParserOptions } from "@Components/pages/common/HTMLParser/helpers";

import type { BottomSheetContent } from "../../../types";

import { StyledLink, StyleParagraph, TermsWrapper } from "./styles";

export type Props = {
  content: BottomSheetContent;
  dataTestId?: string;
  id?: string;
};

const TileItemBottomSheetContent = ({ id, content, dataTestId }: Props) => {
  const whatsIncluded = content.whatsIncluded;

  const showWhatsIncluded = whatsIncluded.length > 0;

  const whatsIncludedArr = whatsIncluded.map(({ bullet_point_text, icon, bullet_point_alt }, index) => ({
    icon: (
      <Image
        alt={bullet_point_alt || ""}
        data-testid="bullet-point-icon"
        height={20}
        src={icon || "/_next-public/images/icons/round-tick-slate-acid.svg"}
        width={20}
      />
    ),
    item: bullet_point_text,
    key: index.toString(),
  }));

  const showInfoSection = content.infoTitle && content.infoParagraph;

  const showBottomLink = Boolean(content.bottomLinkText) && Boolean(content.bottomLinkUrl);

  return (
    <div data-testid={dataTestId} id={id}>
      {content.header && (
        <Paragraph bottom={5} boxtTheme="slate" weight="bold">
          {content.header}
        </Paragraph>
      )}
      <Paragraph>{content.h2}</Paragraph>
      <Spacing mt={2} />
      <StyleParagraph>{content.paragraph}</StyleParagraph>
      <Spacing mt={2} />
      {showWhatsIncluded && (
        <>
          <H5>{content?.whatsIncludedTitle || ""}</H5>
          <Spacing mt={2} />
          <BulletPoints
            boxtTheme="slate"
            icon={
              <Image
                alt="info-icon"
                height={20}
                src="/_next-public/images/icons/round-tick-slate-acid.svg"
                width={20}
              />
            }
            items={whatsIncludedArr}
          />
        </>
      )}
      {showInfoSection && (
        <>
          <Spacing mb={2} mt={4}>
            <TermsWrapper>
              <Image alt="info-icon" height={20} src={"/_next-public/images/icons/info-slate.svg"} width={20} />
              <Spacing ml={1} />
              <H5>{content.infoTitle}</H5>
            </TermsWrapper>
          </Spacing>
          <div>{dangerouslySetInnerHTML(content?.infoParagraph || "", getDOMParserOptions())}</div>
        </>
      )}
      {showBottomLink && (
        <Spacing mb={2} mt={5}>
          <StyledLink data-testid="bottom-link" href={content.bottomLinkUrl} rel="noopener noreferrer" target="_blank">
            {content.bottomLinkText}
          </StyledLink>
        </Spacing>
      )}
    </div>
  );
};

export default TileItemBottomSheetContent;
