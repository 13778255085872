import type { ElementType } from "react";
import type { AnimatedComponent } from "@react-spring/web";
import { animated, useInView } from "@react-spring/web";

import Image from "@Components/Image";
import type { ButterFullWidthImageFields } from "@Components/pages/common/FullWidthImage/types";

import { ImageWrapper } from "./styles";

type Props = {
  fields: ButterFullWidthImageFields;
  height: number;
  width: number;
  isZoomable?: boolean;
  idAttribute: string;
};

const FullWidthImage = ({ fields: { image, alt }, height, width, isZoomable = false, idAttribute }: Props) => {
  const [ref, springs] = useInView(
    () => ({
      config: { duration: 1200 },
      from: {
        transform: "scale(1)",
      },
      to: {
        transform: "scale(1.1)",
      },
    }),
    {
      amount: 0.25,
    },
  );

  const AnimatedDiv: AnimatedComponent<ElementType> = animated.div;

  return (
    <div id={idAttribute} ref={(isZoomable && ref) || null}>
      <ImageWrapper>
        <AnimatedDiv style={(isZoomable && springs) || null}>
          <Image alt={alt} height={height} layout="responsive" src={image} width={width} />
        </AnimatedDiv>
      </ImageWrapper>
    </div>
  );
};

export default FullWidthImage;
