import { isEmpty } from "lodash-es";

import createKey from "@Helpers/createKey";

import type { ButterLandingHeroUspBulletPoint } from "../../types";

import UspBulletPoint from "./components/UspBulletPoint";

export type Props = {
  uspBulletPoints: ButterLandingHeroUspBulletPoint[];
};

const UspBulletPoints = ({ uspBulletPoints }: Props) => (
  <>
    {uspBulletPoints.map(({ usp, usp_colour }) => (
      <UspBulletPoint key={createKey(usp)} usp={usp} uspColour={isEmpty(usp_colour) ? "slateDark" : usp_colour?.name} />
    ))}
  </>
);

export default UspBulletPoints;
