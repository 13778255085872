import styled from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, colors, Spacing } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";
import Image from "@Components/Image";

export const Container = styled.div<{ $boxtTheme?: FontTheme }>`
  z-index: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${({ $boxtTheme }) => ($boxtTheme ? BACKGROUND_COLORS[$boxtTheme] : colors.white)};
  ${breakpoint("md")} {
    width: 50%;
  }
`;

export const Wrapper = styled(Spacing)`
  height: calc(100% - 2rem);
`;

export const BackgroundImage = styled(Image)`
  z-index: -1;
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
