import useTranslation from "next-translate/useTranslation";
import { Button } from "@boxt/design-system";

import type { ButterCtaButton, ButterNoUrlButton } from "../../types";

import { LoadingIcon } from "./styles";

export type Props = {
  bottomSheetButton: ButterNoUrlButton[];
  setIsBottomSheetOpen: (isBottomSheetOpen: boolean) => void;
  defaultButton: ButterCtaButton[];
  handleFinishQuoteClick: () => void;
  i18nNamespace: string;
  hasFinishQuoteButton: boolean;
  hasBottomSheetButton: boolean;
  hasDefaultButton: boolean;
  isFinishQuoteLoading?: boolean;
};

const Buttons = ({
  bottomSheetButton,
  setIsBottomSheetOpen,
  defaultButton,
  handleFinishQuoteClick,
  i18nNamespace,
  hasFinishQuoteButton,
  hasDefaultButton,
  hasBottomSheetButton,
  isFinishQuoteLoading = false,
}) => {
  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");

  return (
    <>
      {hasBottomSheetButton && !hasFinishQuoteButton && (
        <Button
          boxtTheme={bottomSheetButton[0].theme.theme}
          data-testid="bottom-sheet-button"
          onClick={() => {
            setIsBottomSheetOpen(true);
          }}
          skin={bottomSheetButton[0].skin.name}
        >
          {bottomSheetButton[0].title}
        </Button>
      )}
      {hasDefaultButton && !hasFinishQuoteButton && (
        <Button
          boxtTheme={defaultButton[0].theme.theme}
          data-testid="default-button"
          href={defaultButton[0].url}
          skin={defaultButton[0].skin.name}
        >
          {defaultButton[0].title}
        </Button>
      )}
      {hasFinishQuoteButton && (
        <Button
          boxtTheme="jade"
          data-testid="finish-quote-button"
          loadingConfig={{
            copy: tCommon("loading"),
            icon: hasFinishQuoteButton && {
              afix: "left",
              size: "medium",
              svg: <LoadingIcon />,
            },
            isLoading: isFinishQuoteLoading,
          }}
          onClick={handleFinishQuoteClick}
        >
          {t("title-hero.finish-quote")}
        </Button>
      )}
    </>
  );
};

export default Buttons;
