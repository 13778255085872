import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(20)};
  height: ${rem(500)};
`;

export const TextContainer = styled.div`
  position: relative;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: ${rem(500)};
  border-radius: 16px;
  overflow: hidden;
`;
