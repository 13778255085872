"use client";

import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  button,
  a {
    width: 100%;
  }

  ${breakpoint("md")} {
    width: unset;
  }
`;
