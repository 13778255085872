import type { ChangeEvent } from "react";
import { useState } from "react";
import { Grid, Select } from "@boxt/design-system";

import { FinanceTabsType } from "@Collections/financeTabsType";
import CalculatorTab from "@Components/FinanceCalculator";
import type { ButterFinanceContent } from "@Components/pages/finance/types";

import TextTab from "./Tabs/TextTab";
import { Container, Tab, TabContainer, TabHeaders, TabsDropdownContainer } from "./styles";
import TabContent from "./TabContent";

type Props = {
  content: ButterFinanceContent;
};

const FinanceTabs = ({ content }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const getTabByType = (tabType: FinanceTabsType, props) => {
    if ([FinanceTabsType.Calculator, FinanceTabsType.Bnpl].includes(tabType)) {
      return (
        <CalculatorTab
          {...props}
          includeScrollDelay
          isBnpl={tabType === FinanceTabsType.Bnpl}
          isPayMonthly={tabType === FinanceTabsType.Calculator}
        />
      );
    } else if (tabType === FinanceTabsType.Text) {
      return <TextTab {...props} />;
    }

    return null;
  };

  const tabs = content.tab;

  const options = tabs.map(({ tab_title: tabTitle }, index) => ({
    text: tabTitle,
    value: String(index),
  }));

  return (
    <TabContainer>
      <Grid.Row>
        <TabHeaders>
          {tabs.map(({ tab_title }, index) => (
            <Tab $isSelected={index === activeTabIndex} key={index} onClick={() => setActiveTabIndex(index)}>
              {tab_title}
            </Tab>
          ))}
        </TabHeaders>
        <TabsDropdownContainer>
          <Select
            hideLabel
            id="finance-tabs"
            light
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setActiveTabIndex(Number(e.target.value))}
            options={options}
          />
        </TabsDropdownContainer>
      </Grid.Row>
      <Grid.Row as={Container}>
        {tabs.map((tab, index) => (
          <TabContent isSelected={index === activeTabIndex} key={index}>
            {getTabByType(tab.tab_type, { content: tab })}
          </TabContent>
        ))}
      </Grid.Row>
    </TabContainer>
  );
};

export default FinanceTabs;
