"use client";

import styled, { css } from "styled-components";
import { H3, includeSpacing } from "@boxt/design-system";

const spacing = css`
  ${includeSpacing({ mv: { md: 5, sm: 4 } })};
`;

export const ImageTextWrapper = styled.div`
  padding: 0;
  ${spacing};
`;

export const ImageTextRow = styled.div`
  ${spacing};
`;

export const Title = styled(H3)`
  white-space: pre-wrap;
`;
