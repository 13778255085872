import styled from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, Paragraph, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";

export const UspContainer = styled.div`
  margin-bottom: ${rem(4)};
  display: flex;
  align-items: flex-start;
  text-wrap: wrap;
`;

export const UspIcon = styled.div`
  position: relative;
  width: ${rem(20)};
  height: ${rem(20)};
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${rem(4)};
  padding-top: ${rem(2)};

  ${breakpoint("md")} {
    width: ${rem(30)};
    height: ${rem(30)};
    padding: 0;
  }
`;

export const StyledUsParagraph = styled(Paragraph)`
  align-self: center;
`;

export const StyledUsp = withCustomWrapper(
  StyledUsParagraph,
  ({ children, theme }: { children: string; theme: FontTheme }) => (
    <Paragraph as="strong" boxtTheme={theme} key={children} weight="bold">
      {children}
    </Paragraph>
  ),
);
