"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const StyledTag = styled.div`
  background: ${colors.saffron.lighter};
  border-radius: 20px;
  font-size: ${rem(14)};
  padding: ${rem(4)} ${rem(10)} ${rem(3)} ${rem(10)};
  width: min-content;
`;
