import type { FontTheme } from "@boxt/design-system";
import { breakpoints, Icon } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";

import { StyledUsp, UspContainer, UspIcon } from "./styles";

export type Props = {
  usp: string;
  uspColour?: FontTheme;
};

const UspBulletPoint = ({ usp, uspColour }: Props) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const iconSize = isMobile ? "small" : "medium";

  return (
    <UspContainer data-testid="usp-bullet-point">
      <UspIcon>
        <Icon boxtTheme={uspColour} name="tick" size={iconSize} />
      </UspIcon>
      <StyledUsp boxtTheme={uspColour}>{usp}</StyledUsp>
    </UspContainer>
  );
};

export default UspBulletPoint;
