"use client";

import styled, { css } from "styled-components";
import { breakpoint, Button, rem } from "@boxt/design-system";

import { FONT_COLOR } from "@Collections/themeColors";

export const Container = styled.div`
  flex: 0 0 100%;
  padding: 0.5rem;
  position: relative;
  min-height: ${rem(220)};

  ${breakpoint("md")} {
    min-height: ${rem(302)};
    padding: 1rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  border-radius: ${rem(12)};
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${breakpoint("lg")} {
    padding: 2rem;
  }
  width: 66%;
`;

export const StyledContent = styled.div<{ $textTheme: string; $isContentColorOverriden: boolean }>`
  display: none;

  ${breakpoint("lg")} {
    display: block;
  }

  ${({ $textTheme, $isContentColorOverriden }) =>
    $isContentColorOverriden &&
    FONT_COLOR[$textTheme] &&
    css`
      color: ${FONT_COLOR[$textTheme]};
    `};
`;

export const TextWrapper = styled.div`
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  align-self: flex-start;
`;

export const Cover = styled.div`
  display: block;
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
`;
