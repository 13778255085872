import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const BadgeContainer = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: ${rem(40)};
  right: ${rem(16)};
  border-radius: 16px;
  z-index: 1;
  width: ${rem(100)};
  height: ${rem(60)};

  ${breakpoint("md")} {
    bottom: ${rem(48)};
    right: ${rem(32)};
    width: ${rem(130)};
    height: ${rem(78)};
  }

  ${breakpoint("lg")} {
    left: ${rem(48)};
    right: auto;
  }
`;
