import { useState } from "react";
import TransText from "next-translate/TransText";
import { isEmpty } from "lodash-es";
import type { ButtonTheme, Skin } from "@boxt/design-system";
import { H3, H4, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import { useAnalytics } from "@Hooks/useAnalytics";

import { ButtonContainer, Card, CardContent, Container, ImageDiv, StyledButton, StyledParagraph } from "./styles";
import type { ButterGridCardField } from "./types";

const GridCard = ({ card }: { card: ButterGridCardField }) => {
  const segmentAnalytics = useAnalytics();
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const {
    background_theme: backgroundTheme,
    background_hover_theme: backgroundHoverTheme,
    text_theme: textTheme,
    text_label: textLabel,
    image,
    title,
    explainer_text: explainerText,
    main_cta_text: mainCtaText,
    cta_theme: ctaTheme,
    secondary_cta_text: secondaryCtaText,
    id,
    image_alt_text: imageAltText,
    main_cta_skin: mainCtaSkin,
    secondary_cta_skin: secondaryCtaSkin,
    background_gradient_start_colour: backgroundGradientStartColour,
    background_gradient_end_colour: backgroundGradientEndColour,
    is_double_card: isDoubleCard,
    main_cta_link: mainCtaLink,
    secondary_cta_link: secondaryCtaLink,
    open_main_cta_in_new_tab: openMainCtaInNewTab,
    open_secondary_cta_in_new_tab: openSecondaryCtaInNewTab,
  } = card;

  const fireSegmentClickEvent = (ctaName: string, clickText: string, tile: string) => {
    if (segmentAnalytics) {
      segmentAnalytics?.track("cta_clicked", {
        category: "Homepage",
        click_text: clickText,
        cta_name: ctaName,
        tile: tile,
      });
    }
  };

  const handleButtonHover = () => {
    setIsButtonHovered(true);
  };

  const handleButtonLeave = () => {
    setIsButtonHovered(false);
  };

  return (
    <Card
      $backgroundGradientEndColour={backgroundGradientEndColour}
      $backgroundGradientStartColour={backgroundGradientStartColour}
      $backgroundTheme={backgroundTheme}
      $gridArea={id}
      $hoverTheme={backgroundHoverTheme}
      $isButtonHovered={isButtonHovered}
      $isDoubleCard={isDoubleCard}
      data-testid="grid-card"
      id={id}
    >
      <CardContent $isDoubleCard={isDoubleCard}>
        <Paragraph boxtTheme={textTheme?.name || "slate"} size="small" weight="bold">
          {textLabel}
        </Paragraph>
        <Spacing mb={1} mt={{ lg: 2, sm: 1 }}>
          {isDoubleCard ? (
            <H3 boxtTheme={textTheme?.name || "slate"}>{title}</H3>
          ) : (
            <H4 boxtTheme={textTheme?.name || "slate"}>{title}</H4>
          )}
        </Spacing>
        {explainerText && (
          <StyledParagraph
            $isDoubleCard={isDoubleCard}
            boxtTheme={textTheme?.name || "slate"}
            size={isDoubleCard ? "medium" : "normal"}
          >
            <TransText
              components={{
                strong: <strong />,
              }}
              text={explainerText}
            />
          </StyledParagraph>
        )}
        <ButtonContainer>
          {mainCtaText && mainCtaLink && (
            <StyledButton
              boxtTheme={isEmpty(ctaTheme) ? "dark" : (ctaTheme?.theme as ButtonTheme)}
              data-testid="main-cta-button"
              href={mainCtaLink}
              onClick={() => fireSegmentClickEvent("homepage_tile_main_cta", mainCtaText, textLabel)}
              onMouseEnter={handleButtonHover}
              onMouseLeave={handleButtonLeave}
              skin={isEmpty(mainCtaSkin) ? "primary" : (mainCtaSkin?.name as Skin)}
              target={openMainCtaInNewTab ? "_blank" : undefined}
            >
              {mainCtaText}
            </StyledButton>
          )}
          {secondaryCtaText && secondaryCtaLink && (
            <StyledButton
              boxtTheme={isEmpty(ctaTheme) ? "dark" : (ctaTheme?.theme as ButtonTheme)}
              data-testid="secondary-cta-button"
              href={secondaryCtaLink}
              skin={isEmpty(secondaryCtaSkin) ? "primary" : (secondaryCtaSkin?.name as Skin)}
              target={openSecondaryCtaInNewTab ? "_blank" : undefined}
            >
              {secondaryCtaText}
            </StyledButton>
          )}
        </ButtonContainer>
      </CardContent>
      <Container $isDoubleCard={isDoubleCard}>
        <ImageDiv $isButtonHovered={isButtonHovered} $isDoubleCard={isDoubleCard}>
          <Image alt={imageAltText} layout="fill" objectFit="cover" src={image} />
        </ImageDiv>
      </Container>
    </Card>
  );
};

export default GridCard;
