import type { ButterGridCardField } from "@Components/pages/common/GridCard/types";

export const generateLgGridRule = (cards: ButterGridCardField[]) => {
  // This constructor returns a string in the correct format for the css property grid-template-areas on desktop
  const cardsCopy = [...cards];
  const grid: string[][] = [];

  for (let i = 0; i <= cards.length; i++) {
    grid.push(new Array(2).fill("null"));
  }

  const populateGridResult = (card: ButterGridCardField) => {
    for (let i = 0; i < grid.length; i++) {
      const { id, is_double_card } = card;
      const row = grid[i];
      const nextRow = grid[i + 1];
      const isFirstColumnEmpty = row[0] === "null";
      const isSecondColumnEmpty = row[1] === "null";
      if (!is_double_card) {
        if (isFirstColumnEmpty) {
          row[0] = id;
          break;
        } else if (isSecondColumnEmpty) {
          row[1] = id;
          break;
        }
      } else {
        if (isFirstColumnEmpty) {
          row[0] = id;
          nextRow[0] = id;
          break;
        } else if (isSecondColumnEmpty) {
          row[1] = id;
          nextRow[1] = id;
          break;
        }
      }
    }
  };

  while (cardsCopy.length > 0) {
    const myCard = cardsCopy.shift() as ButterGridCardField;
    populateGridResult(myCard);
  }

  const cleanedGrid = grid.filter((row) => row[0] !== "null");
  const result = cleanedGrid.map((row) => row.join(" ").replace(/null/g, "."));

  return result.map((card) => `'${card}'`).join(" ");
};
