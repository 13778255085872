import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { isEmpty } from "lodash-es";
import { Hero, Paragraph, Spacing } from "@boxt/design-system";

import VisibilitySensor from "@Hoc/VisibilitySensor";
import useQuotes from "@Hooks/useQuotes";

import ButterQuoteBottomSheet from "../ButterQuoteBottomSheet";

import BottomBar from "./components/BottomBar";
import Buttons from "./components/Buttons";
import { TitleContainer } from "./styles";
import type { ButterTitleFields } from "./types";

export type Props = {
  fields: ButterTitleFields;
  idAttribute: string;
};

const i18nNamespace = "list/index";

const ButterTitleHero = ({ fields, idAttribute }: Props) => {
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false);

  const {
    button: defaultButton,
    alignment,
    title_text: titleText,
    sub_heading: subHeading,
    hero_version: heroVersion,
    bottom_sheet_button: bottomSheetButton,
    bottom_sheet_header: bottomSheetHeader,
    tiles = [],
    include_finish_quote_button: displayFinishQuoteButton,
  } = fields;

  const {
    finishQuoteTiles,
    handleFinishQuoteClick,
    hasExistingScreeners,
    isLoading,
    isFinishQuoteLoading,
    isFinishQuoteBottomSheetOpen,
    isNewQuoteBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
    setIsNewQuoteBottomSheetOpen,
  } = useQuotes();

  const handleTitleHeroEnter = () => {
    if (isBottomBarOpen) {
      setIsBottomBarOpen(false);
    }
  };

  const handleTitleHeroLeave = () => {
    if (!isBottomBarOpen) {
      setIsBottomBarOpen(true);
    }
  };

  const hasFinishQuoteButton = displayFinishQuoteButton && hasExistingScreeners;
  const hasDefaultButton = !isEmpty(defaultButton) && heroVersion === "no-bottom-sheet";
  const hasBottomSheetButton = !isEmpty(bottomSheetButton) && heroVersion === "new-quote-bottom-sheet";

  const hasButtons = hasFinishQuoteButton || hasDefaultButton || hasBottomSheetButton;

  return (
    <>
      <TitleContainer>
        {titleText.map(({ text, color }, i) => (
          <Hero
            align={alignment || "center"}
            boxtTheme={color.name}
            data-testid="hero-title"
            key={`${idAttribute}-${i}`}
            weight="regular"
          >
            {text}
          </Hero>
        ))}
        {subHeading && (
          <Spacing mt={2}>
            <Paragraph data-testid="sub-heading">{subHeading}</Paragraph>
          </Spacing>
        )}
        {isLoading ? (
          <Spacing mt={5}>
            <Skeleton containerTestId="skeleton-loader" width="20%" />
          </Spacing>
        ) : (
          <Spacing mt={5}>
            <VisibilitySensor onEnter={handleTitleHeroEnter} onLeave={handleTitleHeroLeave}>
              <Buttons
                bottomSheetButton={bottomSheetButton}
                defaultButton={defaultButton}
                handleFinishQuoteClick={handleFinishQuoteClick}
                hasBottomSheetButton={hasBottomSheetButton}
                hasDefaultButton={hasDefaultButton}
                hasFinishQuoteButton={hasFinishQuoteButton}
                i18nNamespace={i18nNamespace}
                isFinishQuoteLoading={isFinishQuoteLoading}
                setIsBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
              />
            </VisibilitySensor>
          </Spacing>
        )}
      </TitleContainer>
      <ButterQuoteBottomSheet
        finishQuoteTiles={finishQuoteTiles}
        isFinishQuoteBottomSheetOpen={isFinishQuoteBottomSheetOpen}
        isNewQuoteBottomSheetOpen={isNewQuoteBottomSheetOpen}
        newQuoteHeader={bottomSheetHeader}
        newQuoteTiles={tiles}
        setIsFinishQuoteBottomSheetOpen={setIsFinishQuoteBottomSheetOpen}
        setIsNewQuoteBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
      />
      {hasButtons && (
        <BottomBar
          bottomSheetButton={bottomSheetButton}
          defaultButton={defaultButton}
          handleFinishQuoteClick={handleFinishQuoteClick}
          hasBottomSheetButton={hasBottomSheetButton}
          hasDefaultButton={hasDefaultButton}
          hasFinishQuoteButton={hasFinishQuoteButton}
          i18nNamespace={i18nNamespace}
          isBottomBarOpen={isBottomBarOpen}
          setIsBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
        />
      )}
    </>
  );
};

export default ButterTitleHero;
