"use client";

import styled from "styled-components";
import { colors } from "@boxt/design-system";

export const ContainerDiv = styled.div`
  width: 100%;
`;

export const Container = styled.div<{ $isInteractive: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  top: 0;
  z-index: ${({ $isInteractive }) => ($isInteractive ? 1 : -1)};
`;

export const DarkOverlay = styled.div`
  background: ${colors.coal.darker};
  height: 100vh;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
`;

export const MapNotLoaded = styled.div`
  background-color: ${colors.slate.lightest};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
