import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  display: block;

  ${breakpoint("lg")} {
    display: grid;
    gap: ${rem(8)};
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BatteryContainer = styled.label`
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
`;

export const ImageLoader = styled(Skeleton)`
  aspect-ratio: 16 / 9;
  max-height: ${rem(66)};
`;

export const StyledInput = styled.input`
  position: absolute;
  padding: 0;
  margin: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
`;

export const StyledSpan = styled.span`
  display: block;
`;

export const StyledRadio = styled.span`
  display: block;
  width: ${rem(30)};
  height: ${rem(30)};
  margin: 0 auto;
`;
