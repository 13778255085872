"use client";

import styled from "styled-components";
import { breakpoint, Grid, rem } from "@boxt/design-system";

import {
  LG_MAX_CARD_WIDTH,
  MAX_CARDS_VISIBLE,
  MD_MAX_CARD_WIDTH,
  SLIDER_PADDING,
} from "@Components/pages/common/LandingProductTiles/constants";

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  margin: auto;
  width: 100%;

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const StyledGridRow = styled(Grid.Row)<{ $isBottomSheet: boolean }>`
  margin-left: 0;
  margin-right: 0;
  display: ${({ $isBottomSheet }) => ($isBottomSheet ? "none" : "block")};

  ${breakpoint("sm")} {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0;
  }

  ${breakpoint("md")} {
    padding-left: 0;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  ${breakpoint("lg")} {
    display: block;
    margin-right: 0;
  }
`;

export const StyledGridCol = styled.div<{ $length: number }>`
  padding: 0;
  width: 100%;

  ${breakpoint("md")} {
    max-width: ${({ $length }) =>
      rem(Math.min(MAX_CARDS_VISIBLE, $length) * (MD_MAX_CARD_WIDTH + SLIDER_PADDING) - SLIDER_PADDING)};
    margin: 0 auto;
  }

  ${breakpoint("lg")} {
    max-width: ${({ $length }) =>
      rem(Math.min(MAX_CARDS_VISIBLE, $length) * (LG_MAX_CARD_WIDTH + SLIDER_PADDING) - SLIDER_PADDING)};
    padding: 0 ${rem(58)};
  }

  ${breakpoint("max")} {
    padding: 0;
  }
`;
