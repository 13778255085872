import styled, { css } from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

import Image from "@Components/Image";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${rem(50)};

  ${breakpoint("lg")} {
    gap: ${rem(100)};
    height: ${rem(600)};
    flex-direction: row;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: ${rem(400)};
  margin-left ${rem(-16)};

  ${breakpoint("md")} {
    height: ${rem(500)};
    margin-left ${rem(-32)};
  }

  ${breakpoint("lg")} {
    border-radius: 20px;
    height: 100%;
    margin: auto;
    width: 60%;
  }
`;

export const Header = styled.div`
  display: none;

  ${breakpoint("lg")} {
    box-shadow: 0px 10px 10px rgba(51, 69, 87, 0.08);
    display: block;
    position: absolute;
    width: 80%;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${colors.white};
    padding: ${rem(50)};
    border-radius: 15px;
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  background-color: ${colors.white};
  border-radius: 15px;

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const SliderDiv = styled.div`
  width: ${rem(325)};
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: ${rem(-85)};

  ${breakpoint("md")} {
    width: 100%;
    bottom: ${rem(-30)};
  }

  ${breakpoint("lg")} {
    width: ${rem(475)};
    bottom: ${rem(25)};
    height: ${rem(155)};
  }
`;

export const EngineerCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  box-shadow: 0px 10px 10px rgba(51, 69, 87, 0.08);
`;

export const EngineerDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(10)};
  width: 100%;
  height: 100%;

  ${breakpoint("lg")} {
    padding: auto;
  }
`;

export const WrapperStyle = css`
  border-radius: 15px;

  ${breakpoint("md")} {
    border-radius: 0;
  }

  ${breakpoint("lg")} {
    border-radius: 15px;
  }
`;

export const EngineerImage = styled(Image)`
  border-radius: 15px 0 0 15px;
`;

export const BulletItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(10)};
  margin-bottom: ${rem(8)};
`;

export const MapWrapper = css`
  ${breakpoint("lg")} {
    border-radius: 15px;
  }
`;
