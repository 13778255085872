import type { ReactNode } from "react";
import { Paragraph } from "@boxt/design-system";

import { StyledTag } from "./styles";

type Props = {
  children: ReactNode;
};

const Tag = ({ children }: Props) => {
  return (
    <StyledTag data-testid="landing-50-50-tag">
      <Paragraph boxtTheme="slateDark" size="small" weight="regular">
        {children}
      </Paragraph>
    </StyledTag>
  );
};

export default Tag;
