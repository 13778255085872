import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const TrustpilotWrapper = styled.div`
  min-height: ${rem(70)};
  ${breakpoint("md")} {
    min-height: ${rem(120)};
  }
  #trustpilot {
    ${breakpoint("md")} {
      margin-top: 0;
    }

    ${breakpoint("lg")} {
      margin-top: 0;
      border-radius: 0;
    }
  }
`;
