import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";

import TvAdvertPopup from "./components/TvAdvertPopup";
import { Container, ImageContainer } from "./styles";

export type Props = {
  advertBadgeVideoId: string;
  advertBadgeText?: string;
};

const TvAdvertBadge = ({ advertBadgeVideoId, advertBadgeText }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Container data-testid="watch-tv-ad" onClick={handleOnClick}>
        <ImageContainer>
          <Image alt={t("watch-tv-advert-image-alt")} height={20} src="/_next-public/images/tv-advert.gif" width={34} />
        </ImageContainer>
        <Paragraph as="span" boxtTheme="dark" size="small" weight="bold">
          {advertBadgeText || t("watch-tv-advert")}
        </Paragraph>
      </Container>
      {isModalOpen && <TvAdvertPopup onDismiss={() => setIsModalOpen(false)} videoId={advertBadgeVideoId} />}
    </>
  );
};

export default TvAdvertBadge;
