import { Fragment } from "react";
import useTranslation from "next-translate/useTranslation";
import { H3, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import { ReactComponent as CheckMark } from "@Images/icons/check-mark.svg";

import { HorizontalLine, IconWrapper, ListContainer, ListItems } from "./styles";
import type { ButterBulletListFields } from "./types";

type Props = {
  fields: ButterBulletListFields;
};

const BulletList = ({ fields }: Props) => {
  const { t } = useTranslation("cover/your-options");

  const { body, bullets, title } = fields;

  return (
    <ListContainer>
      <H3 data-testid="list-title">{title}</H3>
      <Spacing mb={4} mt={2}>
        {body && <Paragraph>{body}</Paragraph>}
      </Spacing>
      {bullets.map((bullet, index) => (
        <Fragment key={index}>
          <ListItems data-testid={`bullet-${index}`}>
            <IconWrapper>
              {bullet.icon ? (
                <Image alt={t("engineer-map.bullet-icon-alt")} height={24} src={bullet.icon} width={24} />
              ) : (
                <CheckMark />
              )}
            </IconWrapper>
            <div>
              <Paragraph boxtTheme="slate" weight="bold">
                {bullet.title}
              </Paragraph>
              <Spacing mb={1} />
              <Paragraph>{bullet.text}</Paragraph>
            </div>
          </ListItems>
          {index !== bullets.length - 1 && <HorizontalLine />}
        </Fragment>
      ))}
    </ListContainer>
  );
};

export default BulletList;
