"use client";

import styled from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, Hero, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";

const tabletMinHeight = (hasOverflowContent?: boolean) => (hasOverflowContent ? rem(700) : rem(568));

const tabletPaddingBottom = (hasOverflowContent?: boolean) => (hasOverflowContent ? rem(260) : rem(60));

const mobilePaddingBottom = (hasOverflowContent?: boolean) => (hasOverflowContent ? rem(80) : rem(60));

export const ContainerWithBackgroundImage = styled.div<{
  $hasOverflowContent?: boolean;
}>`
  background: transparent;
  min-height: ${rem(600)};
  overflow: hidden;
  padding-bottom: ${({ $hasOverflowContent }) => mobilePaddingBottom($hasOverflowContent)};
  position: relative;
  ${breakpoint("md")} {
    min-height: ${({ $hasOverflowContent }) => tabletMinHeight($hasOverflowContent)};
    padding-bottom: ${({ $hasOverflowContent }) => tabletPaddingBottom($hasOverflowContent)};
  }
`;

export const HeadingContainer = styled.div`
  position: relative;
`;

export const DesktopBackgroundImageContainer = styled.div`
  display: none;
  ${breakpoint("lg")} {
    display: block;
  }
`;

export const MobileBackgroundImageContainer = styled.div`
  ${breakpoint("lg")} {
    display: none;
  }
`;

export const HeroContainer = styled.div<{
  $hasOverflowContent?: boolean;
  $gradientStartColor?: string;
  $gradientEndColor?: string;
}>`
  background: ${({ $gradientStartColor, $gradientEndColor }) =>
    $gradientStartColor && $gradientEndColor
      ? `linear-gradient(191.46deg, ${$gradientStartColor} 8.43%, ${$gradientEndColor} 37.43%)`
      : "transparent"};
  min-height: ${rem(600)};
  overflow: hidden;
  padding-bottom: ${({ $hasOverflowContent }) => mobilePaddingBottom($hasOverflowContent)};
  position: relative;

  ${breakpoint("md")} {
    min-height: ${({ $hasOverflowContent }) => tabletMinHeight($hasOverflowContent)};
    padding-bottom: ${({ $hasOverflowContent }) => tabletPaddingBottom($hasOverflowContent)};
  }
`;

export const StyledImageCol = styled.div`
  height: ${rem(345)};

  ${breakpoint("lg")} {
    position: absolute;
    right: 0;
    width: ${rem(611)};
  }
`;

export const ImageContainer = styled.div`
  height: ${rem(305)};
  left: 50%;
  position: absolute;
  top: ${rem(48)};
  transform: translateX(-50%);
  width: ${rem(611)};

  ${breakpoint("lg")} {
    left: initial;
    right: -${rem(70)};
    top: ${rem(80)};
    transform: none;
  }

  ${breakpoint("xlg")} {
    height: ${rem(430)};
    top: ${rem(48)};
    width: ${rem(920)};
  }
`;

export const Heading = withCustomWrapper(
  Hero,
  ({ children, $titleHighlightedTheme }: { children: string; size; $titleHighlightedTheme: FontTheme }) => (
    <Hero as="span" boxtTheme={$titleHighlightedTheme} key={children} weight="regular">
      {children}
    </Hero>
  ),
);
