import styled, { keyframes } from "styled-components";

import { ReactComponent as LoadingSVG } from "@Images/icons/loading.svg";

const iconRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(LoadingSVG)`
  animation: ${iconRotate} 1s linear infinite;
`;
