import styled, { css, keyframes } from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

const fadeIn = keyframes`
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
`;

export const MobileImage = styled.div`
  display: block;
  position: relative;
  animation: ${fadeIn} 1200ms ease-out;

  ${breakpoint("md")} {
    display: none;
  }
`;

export const TabletImage = styled.div`
  display: none;
  position: relative;

  ${breakpoint("md")} {
    animation: ${fadeIn} 1200ms ease-out;
    display: block;
  }

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const DesktopImage = styled.div<{ $isForegroundImageMaxWidth?: boolean }>`
  display: none;

  ${breakpoint("lg")} {
    animation: ${fadeIn} 1200ms ease-out;
    display: block;
    position: absolute;
    width: 100%;
    height: ${({ $isForegroundImageMaxWidth }) => ($isForegroundImageMaxWidth ? "100%" : `${rem(730)}`)};
    overflow: hidden;
    border-bottom-left-radius: ${({ $isForegroundImageMaxWidth }) => ($isForegroundImageMaxWidth ? "0px" : "24px")};
    border-bottom-right-radius: ${({ $isForegroundImageMaxWidth }) => ($isForegroundImageMaxWidth ? "0px" : "24px")};
  }
`;

export const WrapperStyle = css`
  display: grid !important;
  grid-template-columns: 1fr;

  ${breakpoint("lg")} {
    // Override slider inline css
    position: absolute !important;
  }
`;

type SlideProps = {
  $opacity: number;
  $isForegroundImageMaxWidth?: boolean;
};

export const SlideWrapper = styled.div.attrs<SlideProps>(({ $opacity }) => ({
  style: {
    opacity: $opacity,
    zIndex: $opacity === 0 ? -1 : 1,
  },
}))<SlideProps>`
  grid-row-start: 1;
  grid-column-start: 1;

  ${breakpoint("lg")} {
    height: ${({ $isForegroundImageMaxWidth }) => ($isForegroundImageMaxWidth ? `${rem(760)}` : `${rem(730)}`)};
  }
`;
