import createKey from "@Helpers/createKey";
import Image from "@Components/Image";

import type { ButterLandingHeroUspBadge } from "../../types";

import { UspBadge, UspBadgeContainer } from "./styles";

export type Props = {
  uspBadges: ButterLandingHeroUspBadge[];
};

const UspBadges = ({ uspBadges }: Props) => {
  return (
    <UspBadgeContainer>
      {uspBadges.map(({ badge, alt_text: altText }) => (
        <UspBadge data-testid="usp-badge" key={createKey(altText)}>
          <Image alt={altText} height={70} src={badge} width={70} />
        </UspBadge>
      ))}
    </UspBadgeContainer>
  );
};

export default UspBadges;
