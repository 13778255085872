import { Grid } from "@boxt/design-system";

import { addSoftHyphens } from "@Helpers/strings";
import Image from "@Components/Image";
import {
  Content,
  CTAButton,
  ImageCol,
  ImageWrapper,
  Subtitle,
} from "@Components/pages/common/ImageText/ImageTextItem/styles";
import type { ButterImageTextCard } from "@Components/pages/common/ImageText/types";

type Props = {
  item: ButterImageTextCard;
};

const ImageTextItem = ({ item: { image, cta_text, title, cta_theme, cta_url, body, position } }: Props) => {
  return (
    <>
      <Grid.Col $position={position} as={ImageCol} data-testid="image-content" md={4} sm={8}>
        <ImageWrapper>
          <Image alt={title} layout="fill" objectFit="cover" src={image} />
        </ImageWrapper>
      </Grid.Col>
      <Grid.Col md={4} sm={8}>
        <Subtitle bottom={{ md: 6, sm: 5 }} top={{ md: 0, sm: 6 }}>
          {addSoftHyphens(title)}
        </Subtitle>
        <Content bottom={{ md: 6, sm: 5 }}>{addSoftHyphens(body)}</Content>
        {Boolean(cta_text) && (
          <CTAButton boxtTheme={cta_theme?.theme || "jade"} data-testid="cta-button" href={cta_url}>
            {addSoftHyphens(cta_text)}
          </CTAButton>
        )}
      </Grid.Col>
    </>
  );
};

export default ImageTextItem;
