import { breakpoints, H3, Paragraph, Spacing } from "@boxt/design-system";

import ImageCollectionColumn from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn";
import { StyledGridRow, TextCol, TextRow } from "@Components/pages/common/LandingImageCollection/styles";
import type { ButterImageCollectionFields } from "@Components/pages/common/LandingImageCollection/types";
import useMedia from "@Hooks/useMedia";

type Props = {
  fields: ButterImageCollectionFields;
  idAttribute: string;
};

const LandingImageCollection = ({ fields: { images, title, explainer_text }, idAttribute }: Props) => {
  const isMd = useMedia(`(min-width: ${breakpoints.md.width})`);

  const hasText = title && explainer_text;

  return (
    <>
      {hasText && (
        <TextRow id="landing-image-text-area">
          <TextCol>
            <H3 weight="regular">{title}</H3>
            <Spacing mt={1}>
              <Paragraph>{explainer_text}</Paragraph>
            </Spacing>
          </TextCol>
        </TextRow>
      )}
      <StyledGridRow $renderSpacing={!hasText} id={idAttribute}>
        <ImageCollectionColumn images={images.slice(0, 3)} />
        {isMd && <ImageCollectionColumn images={images.slice(3)} />}
      </StyledGridRow>
    </>
  );
};

export default LandingImageCollection;
