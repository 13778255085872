import type { KeenSliderOptions } from "keen-slider/react";
import { breakpoints } from "@boxt/design-system";

export const getKeenSliderOptions = (slidesLength = 0): KeenSliderOptions => ({
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      initial: 0,
      loop: slidesLength > 1,
      slides: {
        perView: 1,
        spacing: 0,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
    },
  },
});
