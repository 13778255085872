"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const VideoContainer = styled.div<{ $includeMarginTop: boolean }>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  ${({ $includeMarginTop }) =>
    includeSpacing({ mh: { lg: 0, md: 4, sm: 2 }, ...{ [$includeMarginTop ? "mv" : "mb"]: { md: 5, sm: 3 } } })}
`;
