"use client";

import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const LogoListContainer = styled.div`
  display: flex;
  gap: ${rem(16)};
`;

export const ImageContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;
