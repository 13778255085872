"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const BackgroundContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
`;

export const CopyContainer = styled.div`
  overflow: hidden;
  flex: 1 0 auto;
`;

export const ButtonContainer = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
`;

export const Button = styled.button`
  border: 1px solid ${colors.slate.normal};
  padding: 0;
  width: ${rem(30)};
  height: ${rem(30)};
  background: none;
  cursor: pointer;
  border-radius: 50%;
  color: ${colors.slate.normal};

  &:hover {
    border-color: ${colors.jade.normal};
    color: ${colors.jade.normal};
  }

  &:active {
    color: ${colors.white};
    border-color: ${colors.jade.normal};
    background-color: ${colors.jade.normal};
  }

  &:disabled {
    opacity: 0.2;
  }
`;
