import styled from "styled-components";
import { breakpoint, Button, colors, rem } from "@boxt/design-system";

export const StyledButton = styled(Button)`
  border: 1px solid ${colors.slate.darker};
  border-radius: ${rem(32)};
  padding: ${rem(8)} ${rem(12)} ${rem(8)} ${rem(12)};

  ${breakpoint("lg")} {
    padding: ${rem(16)} ${rem(20)} ${rem(16)} ${rem(20)};
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${rem(12)};
`;
