import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

import Image from "@Components/Image";

const svgWidth = rem(25);

export const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled(Image)`
  border-radius: 20px;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(16)};

  ${breakpoint("lg")} {
    gap: ${rem(32)};
    justify-content: center;
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  ${breakpoint("lg")} {
    width: auto;
  }
`;

export const Card = styled.div<{ $cardCount: number }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ $cardCount }) => ($cardCount === 4 ? rem(300) : rem(400))};
  position: relative;
  height: 100%;
  text-align: left;

  ${breakpoint("lg")} {
    text-align: center;
  }
`;

export const StepperRow = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 103%;

  ${breakpoint("lg")} {
    flex-direction: row;
  }
`;

export const StepWrapper = styled.div<{ $cardCount: number }>`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  width: 100%;
  padding-top: 0;
  max-width: ${({ $cardCount }) => ($cardCount === 4 ? rem(322) : rem(432))};

  svg {
    max-width: ${svgWidth};
    max-height: ${svgWidth};
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    max-width: ${({ $cardCount }) => ($cardCount === 4 ? rem(322) : rem(432))};
    opacity: 0.1;
    background-color: ${colors.slate.lighter};
    position: absolute;
  }

  &:first-child {
    &:after {
      height: 100%;
      width: 100%;
    }
  }

  &:last-child {
    &:after {
      height: 0;
      width: 100%;
    }
  }

  ${breakpoint("lg")} {
    &:first-child {
      &:after {
        height: 100%;
        width: 50%;
        right: 0;
      }
    }

    &:last-child {
      &:after {
        height: 100%;
        left: 0;
        width: 50%;
      }
    }
  }
`;

export const Cotainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${rem(8)};

  ${breakpoint("lg")} {
    gap: ${rem(16)};
    flex-direction: column;
  }
`;
