import { useState } from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

import createKey from "@Helpers/createKey";
import { ReactComponent as PauseIcon } from "@Images/icons/pause.svg";
import { ReactComponent as PlayIcon } from "@Images/icons/play-video.svg";

import { VideoButton } from "./styles";

export type Props = {
  isVideoLooping: boolean;
  isVideoPlaying: boolean;
  onClick: () => void;
  onLoad: () => void;
  poster: string;
  testId?: string;
  title: string;
  videoSrc: string | undefined;
  isForegroundVideo?: boolean | undefined;
};

const Video = ({
  isVideoLooping,
  poster,
  title,
  videoSrc,
  onClick,
  isVideoPlaying,
  onLoad,
  testId,
  isForegroundVideo,
}: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [hasError, setHasError] = useState(false);

  const buttonLabel = isVideoPlaying ? tCommon("pause-video") : tCommon("play-video");

  return (
    <>
      <ReactPlayer
        config={{
          file: {
            attributes: {
              "data-testid": testId,
              poster,
              style: {
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                width: "100%",
              },
              title,
            },
          },
        }}
        controls={false}
        height="100%"
        loop={isVideoLooping}
        muted
        onError={() => setHasError(true)}
        onReady={onLoad}
        playing={isVideoPlaying}
        playsinline
        url={videoSrc}
        width="100%"
      />
      {hasError ? null : (
        <VideoButton
          $isForegroundVideo={isForegroundVideo}
          aria-label={buttonLabel}
          data-testid={createKey(`${testId}-${buttonLabel}`)}
          onClick={onClick}
        >
          {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
        </VideoButton>
      )}
    </>
  );
};

export default Video;
