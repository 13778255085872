"use client";

import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const ImageWrapper = styled.div`
  overflow: hidden;
  margin-left: -${rem(16)};
  margin-right: -${rem(16)};

  ${breakpoint("md")} {
    margin-left: -${rem(32)};
    margin-right: -${rem(32)};
  }

  ${breakpoint("max")} {
    margin-left: -${rem(40)};
    margin-right: -${rem(40)};
  }
`;
