import { H3, Spacing } from "@boxt/design-system";
import { Grid } from "@boxt/design-system";

import { Wrapper } from "@Components/pages/common/Landing5050/styles";
import { ReactComponent as ChevronSvg } from "@Images/icons/circled-arrow.svg";

import { Container, StyledButton } from "./styles";
import type { ButterNavigationPillsFields } from "./types";

export type Props = {
  fields: ButterNavigationPillsFields;
};

const NavigationPills = ({ fields }: Props) => {
  const { navigation_pills, title, background_colour, pill_theme } = fields;

  return (
    <Wrapper $bgColor={background_colour?.name}>
      <Spacing mh={{ lg: 8, md: 0, sm: 0 }} mv={5}>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Spacing mb={4}>
                <H3 align="center">{title}</H3>
              </Spacing>
              <Container>
                {navigation_pills?.map((pill, index) => (
                  <StyledButton
                    boxtTheme={pill_theme?.theme}
                    data-testid={`pill-${index}`}
                    href={pill.url}
                    icon={{
                      afix: "right",
                      size: "small",
                      svg: <ChevronSvg />,
                    }}
                    key={index}
                  >
                    {pill.label}
                  </StyledButton>
                ))}
              </Container>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Spacing>
    </Wrapper>
  );
};

export default NavigationPills;
