import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useRouter } from "next/router";
import type { screenerStateType, screenerValuesType } from "@ReduxStore/features/screenerSlice";
import { validateScreener } from "@ScreenerSetup/helpers";
import type { Versions } from "@ScreenerSetup/types";
import { SCREENER_COOKIE_KEY } from "@ScreenerSetup/types";

import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

export type Props = { variant: ScreenerVariant; version?: Versions }[];

type ReturnValue = Record<
  string,
  {
    isSupported: boolean;
    isScreenerStarted: boolean;
    isScreenerComplete: boolean;
    screenerContent: screenerValuesType;
  }
>;
// TODO, once all screeners are supported, remove this list
const listOfSupportedScreeners = [
  ScreenerVariant.AirCondition,
  ScreenerVariant.BoilerInstallation,
  ScreenerVariant.BoilerRepair,
  ScreenerVariant.BoilerSubscription,
  ScreenerVariant.BatteryOnly,
  ScreenerVariant.HeatPump,
  ScreenerVariant.Solar,
  ScreenerVariant.EVCharger,
];

const useScreenerStatus = (variants: Props): ReturnValue => {
  const router = useRouter();
  const [watchState, setWatchState] = useState(true);
  const [innerReduxState, setReduxState] = useState<screenerStateType>();

  const reduxState: screenerStateType = useSelector(
    (state: screenerStateType) => (watchState ? state.screener : { unwatched: true }),
    shallowEqual,
  ) as screenerStateType;

  useEffect(() => {
    // do not look for state changes when changing routes
    router.events.on("routeChangeStart", () => {
      setWatchState(false);
    });
  }, [router]);

  useEffect(() => {
    if (!reduxState.unwatched) {
      setReduxState(reduxState);
    }
  }, [reduxState]);

  const result: ReturnValue = {};
  if (!innerReduxState) return result;
  variants.forEach(({ variant, version }) => {
    const { isValid, isComplete } = validateScreener(innerReduxState, {
      variant,
      version: version || "base",
    });
    result[variant] = {
      isScreenerComplete: isComplete || false,
      isScreenerStarted: isValid,
      isSupported: listOfSupportedScreeners.includes(variant),
      screenerContent: innerReduxState[`${SCREENER_COOKIE_KEY}:${variant}`]?.values,
    };
  });
  return result;
};

export default useScreenerStatus;
