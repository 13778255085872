"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, rem, Spacing } from "@boxt/design-system";

export const Container = styled.div<{ $isGreyTheme?: boolean }>`
  position: relative;
  overflow: hidden;
  width: 98%;
  padding-bottom: ${rem(32)};

  ${({ $isGreyTheme }) =>
    $isGreyTheme &&
    css`
      width: 100vw;
      height: 100%;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      background-color: ${colors.slate.lightest};
    `}

  ${breakpoint("md")} {
    padding-bottom: ${rem(48)};
  }
`;

export const StyledSpacing = styled(Spacing)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  max-width: 99rem;
  margin: 0 auto;
  padding: 0 0.5rem;

  ${breakpoint("md")} {
    padding: 0 1rem;
  }
`;

export const StyledCol = styled.div`
  height: 100%;
`;
