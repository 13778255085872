"use client";

import styled from "styled-components";
import { breakpoint, Grid, includeSpacing, rem } from "@boxt/design-system";

export const StyledGridRow = styled(Grid.Row)<{ $renderSpacing: boolean }>`
  ${({ $renderSpacing }) => $renderSpacing && includeSpacing({ mv: { lg: 8, sm: 6 } })};
  margin: 0;
  padding: 0 ${rem(12)};
  ${breakpoint("lg")} {
    padding: 0 ${rem(8)};
  }
`;

export const TextRow = styled(Grid.Row)`
  margin: 0;
  justify-content: center;

  ${includeSpacing({ mb: 4, mt: { lg: 6, md: 3, sm: 2 } })};

  ${breakpoint("lg")} {
    padding: 0 ${rem(8)};
  }
`;

export const TextCol = styled(Grid.Col)`
  display: flex;
  max-width: ${rem(672)};
  flex-direction: column;
  text-align: center;
`;
