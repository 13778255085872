import { useSpring } from "react-spring";
import useTranslation from "next-translate/useTranslation";
import { breakpoints, Button } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";

import type { ButterCtaButton, ButterNoUrlButton } from "../../types";
import Buttons from "../Buttons";

import { AnimatedContainer, ContentWrapper } from "./styles";

export type Props = {
  isBottomBarOpen: boolean;
  bottomSheetButton?: ButterNoUrlButton[];
  setIsBottomSheetOpen: (isBottomSheetOpen: boolean) => void;
  defaultButton?: ButterCtaButton[];
  handleFinishQuoteClick: () => void;
  i18nNamespace: string;
  hasFinishQuoteButton?: boolean;
  hasBottomSheetButton: boolean;
  hasDefaultButton: boolean;
};

const BottomBar = ({
  isBottomBarOpen,
  bottomSheetButton,
  setIsBottomSheetOpen,
  defaultButton,
  handleFinishQuoteClick,
  i18nNamespace,
  hasFinishQuoteButton,
  hasBottomSheetButton,
  hasDefaultButton,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const animateBottom = useSpring({
    config: { mass: 0.5, tension: 220 },
    opacity: isBottomBarOpen ? 1 : 0,
  });

  return (
    <AnimatedContainer data-testid="bottom-bar" style={animateBottom}>
      <ContentWrapper>
        <Buttons
          bottomSheetButton={bottomSheetButton}
          defaultButton={defaultButton}
          handleFinishQuoteClick={handleFinishQuoteClick}
          hasBottomSheetButton={hasBottomSheetButton}
          hasDefaultButton={hasDefaultButton}
          hasFinishQuoteButton={hasFinishQuoteButton}
          i18nNamespace={i18nNamespace}
          setIsBottomSheetOpen={setIsBottomSheetOpen}
        />
        {hasFinishQuoteButton && !isMobile && (
          <Button
            boxtTheme="jade"
            data-testid="new-quote-button"
            onClick={() => {
              setIsBottomSheetOpen(true);
            }}
            skin="secondary"
          >
            {t("title-hero.new-quote")}
          </Button>
        )}
      </ContentWrapper>
    </AnimatedContainer>
  );
};

export default BottomBar;
