import { Grid, H4, Paragraph } from "@boxt/design-system";

import Chevron from "../LandingProductTiles/Chevron";

import {
  BackgroundColour,
  CardContainer,
  ChevronContainer,
  Container,
  InfoContentWrapper,
  StyledGridCol,
  TextContentWrapper,
} from "./styles";
import type { ButterBlockTiles } from "./types";

export type Props = {
  fields: ButterBlockTiles;
  idAttribute: string;
};

export const NavigationBlock = ({ fields, idAttribute }: Props) => {
  const { tiles, background_colour: backgroundColour } = fields;

  return (
    <Container>
      <BackgroundColour $backgroundColour={backgroundColour} data-testid="background-colour">
        <Grid id={idAttribute}>
          <Grid.Row>
            <Grid.Col as={StyledGridCol} lg={{ offset: 1, span: 14 }}>
              {tiles.map((tile, i) => (
                <CardContainer
                  $desktopImage={tile.desktop_image}
                  $mobileImage={tile.mobile_image}
                  $tabletImage={tile.tablet_image}
                  data-testid="card-container"
                  href={tile.url}
                  key={`nav-block-${i}`}
                >
                  <InfoContentWrapper>
                    <TextContentWrapper>
                      <H4 boxtTheme="dark">{tile.title}</H4>
                      <Paragraph boxtTheme="dark" top={3}>
                        {tile.description}
                      </Paragraph>
                    </TextContentWrapper>
                    <ChevronContainer>
                      <Chevron />
                    </ChevronContainer>
                  </InfoContentWrapper>
                </CardContainer>
              ))}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </BackgroundColour>
    </Container>
  );
};
