import type { SyntheticEvent } from "react";
import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import useSWRImmutable from "swr/immutable";
import { BottomSheet, Button, Grid, H3, H5, Paragraph, Spacing } from "@boxt/design-system";

import { Products } from "@Collections/products";
import { API_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import type { BatterySize } from "@DataAccess/remote/solar";
import ProductSavings from "@Components/ProductSavings";
import PanelIncrementor from "@Components/SolarConfigurator/PanelIncrementor";
import SolarInfoBottomSheet from "@Components/SolarConfigurator/SolarInfoBottomSheet";
import type { SolarPackage } from "@Components/SolarConfigurator/types";
import useChat from "@Hooks/useChat";
import { ReactComponent as ArrowSVG } from "@Images/icons/top-arrow.svg";

import BatteryOptions from "./components/BatteryOptions";
import { SectionContainer } from "./styles";
import type { ButterSolarSavingsCalculatorFields, PaginationMeta, SolarEstimate } from "./types";

export type Props = {
  fields: ButterSolarSavingsCalculatorFields;
};

const SolarSavingsCalculator = ({ fields }: Props) => {
  const { t } = useTranslation("solar/savings-calculator");

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [numOfPanels, setNumOfPanels] = useState(12);
  const [selectedBatteryPackage, setSelectedBatteryPackage] = useState<BatterySize>("small");

  const { minimize } = useChat();

  const { data: solarSavingsData, error: solarSavingsError } = useSWRImmutable<{
    meta: PaginationMeta;
    estimates: SolarEstimate[];
  }>(
    [
      API_URLS.SOLAR.ESTIMATES,
      {
        batterySize: selectedBatteryPackage,
        numberOfPanels: numOfPanels,
        page: 1,
        perPage: 1,
      },
    ],
    ([url, params]) => fetcher(url, params),
    {
      shouldRetryOnError: false,
    },
  );

  const { data: solarPanelsData, error: solarPanelsError } = useSWRImmutable<{
    meta: PaginationMeta;
    packages: SolarPackage[];
  }>(
    [
      API_URLS.SOLAR.PACKAGES,
      {
        packageType: "panel",
        page: 1,
        perPage: 1,
      },
    ],
    ([url, params]) => fetcher(url, params),
    {
      shouldRetryOnError: false,
    },
  );

  const { data: solarBatteryData, error: solarBatteryError } = useSWRImmutable<{
    meta: PaginationMeta;
    packages: SolarPackage[];
  }>(
    [
      API_URLS.SOLAR.PACKAGES,
      {
        packageType: "battery",
      },
    ],
    ([url, params]) => fetcher(url, params),
    {
      shouldRetryOnError: false,
    },
  );

  const solarSavings =
    solarSavingsData && solarSavingsData.estimates.length > 0 ? solarSavingsData?.estimates[0] : null;

  const panelPackage = solarPanelsData && solarPanelsData.packages.length > 0 ? solarPanelsData?.packages[0] : null;

  const isLoadingSolarSavings = (!solarSavingsData && !solarSavingsError) || Boolean(solarSavingsError);
  const isLoadingPanels = (!solarPanelsData && !solarPanelsError) || Boolean(solarPanelsError);
  const isLoadingBatteries = (!solarBatteryData && !solarBatteryError) || Boolean(solarBatteryError);

  const {
    title,
    intro,
    title_theme: titleTheme,
    intro_theme: introTheme,
    annual_electricity_usage: annualElectricityUsage,
    electricity_unit_price: electricityUnitPrice,
    month_and_year_price_cap_rate: monthAndYearPriceCapRate,
  } = fields;

  const maxPowerOutput =
    Number(panelPackage?.specs.find((spec) => spec.key === "maximum_power_output")?.value) || undefined;

  const handleOnClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  const handleOnUnitClick = (units: number) => {
    setNumOfPanels(units);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={{ offset: 3, span: 10 }}>
          {title ? (
            <H3 align="center" bottom={5} boxtTheme={titleTheme?.name}>
              {title}
            </H3>
          ) : null}
          {intro ? (
            <Paragraph align="center" bottom={9} boxtTheme={introTheme?.name} size="medium">
              {intro}
            </Paragraph>
          ) : null}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={8} md={8} sm={4} xlg={{ offset: 2, span: 6 }}>
          <Spacing mb={{ md: 4, sm: 2 }}>
            <SectionContainer>
              <Spacing mh={2} mv={{ md: 4, sm: 3 }}>
                <H5 bottom={3}>{t("panels.widget.title")}</H5>
                <Spacing mb={2}>
                  <Button
                    boxtTheme="coral"
                    onClick={() => {
                      setIsBottomSheetOpen(true);
                      minimize();
                    }}
                    skin="link"
                  >
                    {t("panels.widget.info-link")}
                  </Button>
                </Spacing>
                <PanelIncrementor
                  i18nNamespace="solar/savings-calculator"
                  isLoading={isLoadingPanels}
                  isUpdating={isLoadingPanels}
                  numOfPanelUnits={numOfPanels}
                  onUnitClick={handleOnUnitClick}
                  panelImage={panelPackage?.mainImageUrl}
                  panelPackagePowerOutput={maxPowerOutput}
                />
              </Spacing>
            </SectionContainer>
          </Spacing>
          <Spacing mb={{ md: 4, sm: 2 }}>
            <SectionContainer>
              <Spacing mh={2} mv={{ md: 4, sm: 3 }}>
                <H5 bottom={3}>{t("battery.widget.title")}</H5>
                <Spacing mb={2}>
                  <Paragraph>{t("battery.widget.copy")}</Paragraph>
                </Spacing>
                <BatteryOptions
                  availableBatteryPackages={solarBatteryData?.packages}
                  isLoading={isLoadingBatteries}
                  onBatteryOptionClick={setSelectedBatteryPackage}
                  selectedBatteryPackage={selectedBatteryPackage}
                />
              </Spacing>
            </SectionContainer>
          </Spacing>
        </Grid.Col>
        <Grid.Col lg={8} md={8} sm={4} xlg={6}>
          <SectionContainer>
            <Spacing mh={2} mv={{ md: 4, sm: 3 }}>
              <H5 bottom={2}>{t("electricity-bill-title")}</H5>
              <Spacing mb={{ lg: 2, md: 3 }}>
                <ProductSavings
                  annualElectricityUsageAnswer={Number(annualElectricityUsage) || 4100}
                  annualSavings={Number(solarSavings?.annualSavingsInPence)}
                  dayUnitPrice={0}
                  hasAdditionalInfo
                  hasDynamicCopy={false}
                  hasPaybackPeriod={false}
                  hasUnitCosts={false}
                  i18nNamespace="solar/savings-calculator"
                  isLoading={isLoadingSolarSavings}
                  isSingleColumn
                  monthAndYearPriceCapRate={monthAndYearPriceCapRate}
                  nightUnitPrice={0}
                  productType={Products.Solar}
                  screeningId=""
                  singleUnitPrice={Number(electricityUnitPrice)}
                  totalPriceInPence={Number(solarSavings?.totalPriceInPence)}
                />
              </Spacing>
              <Button
                boxtTheme="jade"
                icon={{
                  afix: "right",
                  size: "medium",
                  svg: <ArrowSVG />,
                }}
                isFullWidth
                onClick={handleOnClick}
                size="large"
              >
                {t("get-started-cta")}
              </Button>
            </Spacing>
          </SectionContainer>
        </Grid.Col>
      </Grid.Row>
      <BottomSheet
        blocking
        data-testid="solar-savings-bottom-sheet"
        headerTitle={t("panels.widget.bottom-sheet.title")}
        open={isBottomSheetOpen}
        setIsOpen={setIsBottomSheetOpen}
      >
        <SolarInfoBottomSheet i18nKey="solar/savings-calculator:panels.widget.bottom-sheet.copy" />
      </BottomSheet>
    </>
  );
};

export default SolarSavingsCalculator;
