import type { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import { ReactComponent as DecrementIcon } from "@Images/icons/minus.svg";
import { ReactComponent as IncrementIcon } from "@Images/icons/plus.svg";

import { BackgroundContainer, Button, ButtonContainer, Container, CopyContainer, ImageContainer } from "./styles";

export type Props = {
  copy: string;
  imageAlt: string;
  imageUrl: string;
  isDecrementDisabled?: boolean;
  isIncrementDisabled?: boolean;
  incrementAriaLabel?: string;
  decrementAriaLabel?: string;
  isLoading?: boolean;
  isUpdating?: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
  title: string;
  children?: ReactNode;
};

const UnitIncrementor = ({
  copy,
  decrementAriaLabel,
  imageAlt,
  imageUrl,
  incrementAriaLabel,
  isDecrementDisabled = false,
  isIncrementDisabled = false,
  isLoading = false,
  isUpdating = false,
  onDecrement,
  onIncrement,
  title,
  children,
}: Props) => {
  return (
    <BackgroundContainer data-testid="unit-incrementor">
      <Spacing mr={2}>
        <Container>
          <ImageContainer>
            {isLoading ? (
              <Spacing mr={1}>
                <Skeleton containerTestId="skeleton-loader" height={88} width={86} />
              </Spacing>
            ) : (
              <Image alt={imageAlt} height={88} objectFit="cover" src={imageUrl} width={86} />
            )}
          </ImageContainer>
          <CopyContainer>
            <Spacing mr={2} mv={2}>
              {isLoading || isUpdating ? (
                <Skeleton containerTestId="skeleton-loader" height={10} />
              ) : (
                <>
                  <H5 bottom={2}>{title}</H5>
                  <Paragraph boxtTheme="slate">{copy}</Paragraph>
                </>
              )}
            </Spacing>
          </CopyContainer>
          <ButtonContainer>
            <Spacing mr={2}>
              <Button
                aria-label={decrementAriaLabel}
                disabled={isDecrementDisabled || isLoading || isUpdating}
                onClick={onDecrement}
              >
                <DecrementIcon />
              </Button>
            </Spacing>
            <Button
              aria-label={incrementAriaLabel}
              disabled={isIncrementDisabled || isLoading || isUpdating}
              onClick={onIncrement}
            >
              <IncrementIcon />
            </Button>
          </ButtonContainer>
        </Container>
      </Spacing>
      {children ? (
        <Spacing mb={1} mh={1}>
          {children}
        </Spacing>
      ) : null}
    </BackgroundContainer>
  );
};

export default UnitIncrementor;
