import Skeleton from "react-loading-skeleton";
import { isEmpty } from "lodash-es";
import { Grid, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import useQuotes from "@Hooks/useQuotes";

import ButterQuoteBottomSheet from "../ButterQuoteBottomSheet";
import Buttons from "../ButterTitleHero/components/Buttons";

import {
  ContainerWithBackgroundImage,
  DesktopBackgroundImageContainer,
  Heading,
  HeadingContainer,
  HeroContainer,
  ImageContainer,
  MobileBackgroundImageContainer,
  StyledImageCol,
} from "./styles";
import type { Butter5050HeroFields } from "./types";

export type Props = {
  fields: Butter5050HeroFields;
};

const Butter5050Hero = ({ fields }: Props) => {
  const {
    finishQuoteTiles,
    handleFinishQuoteClick,
    hasExistingScreeners,
    isLoading,
    isFinishQuoteLoading,
    isFinishQuoteBottomSheetOpen,
    isNewQuoteBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
    setIsNewQuoteBottomSheetOpen,
  } = useQuotes();

  const {
    background_gradient_end_colour: gradientEndColor,
    background_gradient_start_colour: gradientStartColor,
    background_image_desktop: desktopBackgroundImage,
    background_image_mobile: mobileBackgroundImage,
    copy,
    copy_theme: copyTheme,
    desktop_image: desktopImage,
    image_alt: imageAlt,
    has_overflow_content: hasOverflowContent,
    title,
    title_highlighted_theme: titleHighlightedTheme,
    title_theme: titleTheme,
    tiles = [],
    bottom_sheet_header: bottomSheetHeader,
    bottom_sheet_button: bottomSheetButton,
    button: defaultButton,
    include_finish_quote_button: displayFinishQuoteButton,
    hero_version: heroVersion,
  } = fields;

  const hasBackgroundImages = Boolean(desktopBackgroundImage && mobileBackgroundImage);

  const hasFinishQuoteButton = displayFinishQuoteButton && hasExistingScreeners;
  const hasDefaultButton = !isEmpty(defaultButton) && heroVersion === "no-bottom-sheet";
  const hasBottomSheetButton = !isEmpty(bottomSheetButton) && heroVersion === "new-quote-bottom-sheet";

  const renderContent = () => {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Col as={StyledImageCol} lg={6} md={8} sm={4}>
              {desktopImage && (
                <ImageContainer data-testid="butter-5050-hero-main-image">
                  <Image alt={imageAlt} layout="fill" src={desktopImage} />
                </ImageContainer>
              )}
            </Grid.Col>
            <Grid.Col lg={{ offset: 1, span: 8 }} md={8} sm={4} xlg={{ offset: 1, span: 7 }}>
              <Spacing as={HeadingContainer} mb={2} mt={{ lg: 7, md: 2, sm: 2 }}>
                <Heading
                  $titleHighlightedTheme={titleHighlightedTheme?.length ? titleHighlightedTheme[0]?.name : "coalLight"}
                  boxtTheme={titleTheme?.length ? titleTheme[0].name : "coalLight"}
                  weight="regular"
                >
                  {title}
                </Heading>
              </Spacing>
            </Grid.Col>
          </Grid.Row>
          {copy && (
            <Grid.Row>
              <Grid.Col lg={{ offset: 1, span: 6 }} md={8} sm={4}>
                <Paragraph boxtTheme={copyTheme?.name ?? "coalLight"} size="medium" weight="regular">
                  {copy}
                </Paragraph>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col lg={{ offset: 1, span: 6 }} md={8} sm={4}>
              <Spacing mt={4}>
                {isLoading ? (
                  <Skeleton containerTestId="skeleton-loader" width="20%" />
                ) : (
                  <Buttons
                    bottomSheetButton={bottomSheetButton}
                    defaultButton={defaultButton}
                    handleFinishQuoteClick={handleFinishQuoteClick}
                    hasBottomSheetButton={hasBottomSheetButton}
                    hasDefaultButton={hasDefaultButton}
                    hasFinishQuoteButton={hasFinishQuoteButton}
                    i18nNamespace="list/index"
                    isFinishQuoteLoading={isFinishQuoteLoading}
                    setIsBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
                  />
                )}
              </Spacing>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <ButterQuoteBottomSheet
          finishQuoteTiles={finishQuoteTiles}
          isFinishQuoteBottomSheetOpen={isFinishQuoteBottomSheetOpen}
          isNewQuoteBottomSheetOpen={isNewQuoteBottomSheetOpen}
          newQuoteHeader={bottomSheetHeader}
          newQuoteTiles={tiles}
          setIsFinishQuoteBottomSheetOpen={setIsFinishQuoteBottomSheetOpen}
          setIsNewQuoteBottomSheetOpen={setIsNewQuoteBottomSheetOpen}
        />
      </>
    );
  };

  return hasBackgroundImages ? (
    <ContainerWithBackgroundImage $hasOverflowContent={hasOverflowContent}>
      <DesktopBackgroundImageContainer data-testid="butter-5050-hero-desktop-background-image">
        <Image alt="" layout="fill" objectFit="cover" objectPosition="top" src={desktopBackgroundImage as string} />
      </DesktopBackgroundImageContainer>
      <MobileBackgroundImageContainer data-testid="butter-5050-hero-mobile-background-image">
        <Image alt="" layout="fill" objectFit="cover" objectPosition="top" src={mobileBackgroundImage as string} />
      </MobileBackgroundImageContainer>
      {renderContent()}
    </ContainerWithBackgroundImage>
  ) : (
    <HeroContainer
      $gradientEndColor={gradientEndColor}
      $gradientStartColor={gradientStartColor}
      $hasOverflowContent={hasOverflowContent}
    >
      {renderContent()}
    </HeroContainer>
  );
};

export default Butter5050Hero;
