"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import Image from "@Components/Image";

export const WrapperStyle = css`
  /* hack to fix Safari border-radius issue: https://stackoverflow.com/a/69238586 */
  -webkit-transform: translate3d(0, 0, 0);
  height: ${rem(226)};
  border-radius: ${rem(7.8)};

  ${breakpoint("md")} {
    height: ${rem(385)};
    border-radius: ${rem(13.28)};
  }

  ${breakpoint("lg")} {
    height: ${rem(464)};
    border-radius: ${rem(16)};
  }
`;

export const SlideWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const StyledImage = styled(Image)`
  object-position: top;
`;

export const CustomDotIcon = styled.div<{ $selected: boolean }>`
  background: ${({ $selected }) => ($selected ? colors.coral.normal : colors.slate.lighter)};
  border-radius: ${rem(60)};
  cursor: pointer;
  height: ${rem(9)};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.2)};
  width: ${({ $selected }) => ($selected ? rem(19) : rem(9))};
  margin-right: ${rem(10)};
`;

export const DotsStyle = css`
  ${includeSpacing({
    mb: { md: 0, sm: 0 },
    mt: 2,
  })}
`;
