"use client";

import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, Grid, includeSpacing, rem, Spacing } from "@boxt/design-system";

export const TextRow = styled(Grid.Row)`
  margin: 0;
  padding: 0 ${rem(12)};
  justify-content: center;
  ${includeSpacing({ mb: 4 })};
`;

export const InstallationGuideCol = styled(Grid.Col)`
  width: 100%;
  padding: 0;
  max-width: ${rem(848)};
`;

export const WrapperStyle = css`
  width: 100%;
  display: flex;
  align-items: center;

  ${breakpoint("lg")} {
    border-radius: ${rem(20)};
  }
`;

export const SlideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SlideCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${rem(20)};
  margin-top: ${rem(20)};

  ${breakpoint("lg")} {
    margin-top: ${rem(30)};
    margin-bottom: 0;
  }
`;

export const InstallationGuideText = styled.div`
  backdrop-filter: blur(${rem(4)});
  border-radius: ${rem(20)};
  margin-top: ${rem(10)};
  background: ${tinyColor(colors.slate.normal).setAlpha(0.8).toRgbString()};
  text-align: center;
  display: flex;
  padding: ${rem(5)} ${rem(10)} ${rem(5)} ${rem(10)};
  width: 100%;
  max-width: ${rem(390)};
  justify-content: center;

  ${breakpoint("md")} {
    margin-top: ${rem(20)};
    width: unset;
  }

  ${breakpoint("lg")} {
    bottom: 0;
    position: absolute;
    margin-bottom: ${rem(20)};
    margin-top: 0;
  }
`;

export const GuideSpacing = styled(Spacing)`
  padding: 0 ${rem(12)};
`;

export const InstallationGuideImage = styled.div`
  border-radius: ${rem(20)};
  position: relative;
  width: 100%;
  max-width: ${rem(420)};
  min-height: ${rem(210)};

  ${breakpoint("md")} {
    max-width: ${rem(680)};
    min-height: ${rem(373)};
  }

  ${breakpoint("lg")} {
    max-width: ${rem(848)};
    min-height: ${rem(465)};
  }
`;

export const TextCol = styled(Grid.Col)`
  display: flex;
  max-width: ${rem(672)};
  flex-direction: column;
  text-align: center;
`;
