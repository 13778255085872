"use client";

import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

import ScrollToButton from "../ScrollToButton";

export const SvgWrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    width: 100%;

    ${breakpoint("md")} {
      transform: unset;
    }
  }
`;

export const StyledButton = styled(ScrollToButton)``;

export const ButtonContainer = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.slate.lightest};
  padding: ${rem(16)};
  border-radius: 100px;

  ${StyledButton} {
    border-radius: 30px;
  }
`;
