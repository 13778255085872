"use client";

import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  max-width: ${rem(848)};
  margin: 0 auto;
`;

export const FinanceTabsWrapper = styled.div`
  margin-top: ${rem(228)};
  margin-bottom: 3rem;
`;
