import useTranslation from "next-translate/useTranslation";
import { BottomSheet } from "@boxt/design-system";

import LandingProductTiles from "../LandingProductTiles";
import type { ButterProductTile } from "../LandingProductTiles/types";

export type Props = {
  finishQuoteTiles: ButterProductTile[];
  newQuoteHeader?: string;
  newQuoteTiles: ButterProductTile[];
  isFinishQuoteBottomSheetOpen: boolean;
  isNewQuoteBottomSheetOpen: boolean;
  setIsFinishQuoteBottomSheetOpen: (isOpen: boolean) => void;
  setIsNewQuoteBottomSheetOpen: (isOpen: boolean) => void;
};

const ButterQuoteBottomSheet = ({
  finishQuoteTiles,
  newQuoteHeader,
  newQuoteTiles,
  isFinishQuoteBottomSheetOpen,
  isNewQuoteBottomSheetOpen,
  setIsFinishQuoteBottomSheetOpen,
  setIsNewQuoteBottomSheetOpen,
}: Props) => {
  const { t } = useTranslation("list/index");

  return (
    <>
      <BottomSheet
        blocking
        headerTitle={newQuoteHeader || t("title-hero.new-quote")}
        maxHeight={700}
        maxWidth={1200}
        open={isNewQuoteBottomSheetOpen}
        setIsOpen={setIsNewQuoteBottomSheetOpen}
      >
        <LandingProductTiles
          fields={{ tiles: newQuoteTiles }}
          idAttribute={"product-tiles-bottom-sheet"}
          isBottomSheet
        />
      </BottomSheet>
      <BottomSheet
        blocking
        headerTitle={t("title-hero.finish-your-quote")}
        maxHeight={700}
        maxWidth={1200}
        open={isFinishQuoteBottomSheetOpen}
        setIsOpen={setIsFinishQuoteBottomSheetOpen}
      >
        <LandingProductTiles
          fields={{ tiles: finishQuoteTiles }}
          idAttribute={"product-tiles-bottom-sheet"}
          isBottomSheet
        />
      </BottomSheet>
    </>
  );
};

export default ButterQuoteBottomSheet;
