import Image from "@Components/Image";

import { CTAContainer, ImageContainer, StyledArrow, StyledDiv, StyledH5, StyledLink, StyledSpacing } from "./styles";
import type { SidePanelCTA } from "./types";

export type Props = {
  fields: SidePanelCTA;
};

const GuideSidePanelCTA = ({ fields }: Props) => (
  <StyledLink href={fields.cta_url}>
    <CTAContainer>
      <StyledDiv>
        <ImageContainer>
          <Image alt="Sidepanel Call-to-action image" layout="fill" objectFit="cover" src={fields.cta_photo} />
        </ImageContainer>
      </StyledDiv>
      <StyledH5>{fields.cta_text}</StyledH5>
      <StyledSpacing mh={2}>
        <StyledArrow />
      </StyledSpacing>
    </CTAContainer>
  </StyledLink>
);

export default GuideSidePanelCTA;
