import { Grid } from "@boxt/design-system";

import LandingImageTilesContent from "@Components/pages/common/LandingImageTiles/LandingImageTilesContent";
import LandingImageTilesHeader from "@Components/pages/common/LandingImageTiles/LandingImageTilesHeader";
import { Container, StyledCol, StyledSpacing } from "@Components/pages/common/LandingImageTiles/styles";
import type { ButterImageTilesFields } from "@Components/pages/common/LandingImageTiles/types";

type Props = {
  content: ButterImageTilesFields;
  isGreyTheme?: boolean;
};

const LandingImageTiles = ({ content, isGreyTheme = true }: Props) => {
  const isDark = "is_grey_theme" in content ? content.is_grey_theme : isGreyTheme;

  return (
    <Container $isGreyTheme={isDark} id={content.id}>
      <LandingImageTilesHeader content={content} />
      <Grid.Row>
        <Grid.Col as={StyledCol}>
          <StyledSpacing>
            <LandingImageTilesContent content={content} />
          </StyledSpacing>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default LandingImageTiles;
