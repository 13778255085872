import SVG from "react-inlinesvg";
import styled, { css } from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  display: flex;
  gap: ${rem(16)};
  flex-direction: column;
`;

export const ValuePropWrapper = styled.div`
  gap: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  align-items: start;

  ${breakpoint("md")} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoint("lg")} {
    grid-template-columns: repeat(auto-fit, minmax(167px, 1fr));
  }
`;

export const ValuePropButton = styled.button<{ $bgColour?: string }>`
  border: none;
  background-color: ${({ $bgColour }) => ($bgColour ? $bgColour : colors.white)};
  border-radius: 12px;
  padding: ${rem(16)};
  cursor: pointer;
  position: relative;
  min-height: ${rem(90)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${breakpoint("lg")} {
    max-height: ${rem(90)};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: ${rem(6)};
  left: ${rem(8)};
`;

export const IconWrapperBottomSheet = styled.div`
  position: absolute;

  ${breakpoint("md")} {
    top: ${rem(25)};
    left: ${rem(23)};
  }
`;

export const TextWrapper = styled.div`
  margin-top: ${rem(60)};
`;

export const BottomSheetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const BottomSheetItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  width: 100%;
  padding: ${rem(8)};

  ${breakpoint("md")} {
    padding: ${rem(24)};
    width: 50%;
  }
`;

export const StyledSvg = styled(SVG)<{ $strokeColour?: string }>`
  path {
    ${({ $strokeColour }) =>
      $strokeColour
        ? css`
            stroke: ${$strokeColour};
          `
        : css`
            stroke: ${colors.slate.normal};
          `}
  }
`;
