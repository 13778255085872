import { Grid, H3, Spacing } from "@boxt/design-system";

import { LinkContent } from "./LinkContent";
import { StyledLink } from "./styles";
import type { ButterLinkListFields } from "./types";

export type Props = {
  fields: ButterLinkListFields;
};

const LinkList = ({ fields }: Props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={{ offset: 2, span: 12 }}>
          <Spacing mv={3}>
            <Spacing mb={4}>
              <H3 align="center">{fields.title}</H3>
            </Spacing>
            {fields.bullets.map(({ title, url }, index) => (
              <StyledLink data-testid={`link-${index}`} href={url} key={index} target="_blank">
                <LinkContent hasDottedLine={index < fields.bullets.length - 1} title={title} />
              </StyledLink>
            ))}
          </Spacing>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default LinkList;
