"use client";

import Link from "next/link";
import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, H5, rem, Spacing } from "@boxt/design-system";

import { ReactComponent as ArrowSVG } from "@Images/icons/circled-arrow.svg";

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: ${rem(90)};
  height: ${rem(90)};
`;

export const StyledH5 = styled(H5)`
  flex: 1;
  margin: ${rem(35.5)} 0;
  font-size: ${rem(18)};
  line-height: ${rem(21.6)};
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;

export const StyledArrow = styled(ArrowSVG)`
  color: ${colors.slate.lighter};
  border-radius: ${rem(50)};
`;

export const StyledSpacing = styled(Spacing)`
  width: ${rem(30)};
  height: ${rem(30)};
`;

export const CTAContainer = styled.div`
  background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${rem(90)};
  width: 100%;

  &:hover,
  &:focus {
    ${StyledArrow} {
      background-color: ${colors.white};
      path:first-child {
        color: ${colors.white};
      }
    }
    background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.3).toRgbString()};
    cursor: pointer;
  }

  ${breakpoint("lg")} {
    width: ${rem(320)};
  }
`;

export const StyledDiv = styled.div`
  min-width: ${rem(90)};
`;
