import { breakpoints, Grid, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import AchievementTilesCollection from "./components/AchievementTilesCollection";
import { ImageWrapper, StyledBackgroundStrip, StyledGrid } from "./styles";
import type { ButterAchievementTilesFields } from "./types";

type Props = {
  fields: ButterAchievementTilesFields;
  idAttribute: string;
};

const AchievementTilesComponent = ({ fields, idAttribute }: Props) => {
  const {
    desktop_background_image: desktopBackgroundImage,
    tablet_background_image: tabletBackgroundImage,
    tiles,
  } = fields;
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const image = isLg ? desktopBackgroundImage : tabletBackgroundImage;

  return (
    <Spacing id={idAttribute} mv={7}>
      <StyledBackgroundStrip>
        <Grid as={StyledGrid}>
          <Grid.Row>
            <Grid.Col lg={{ offset: 3, span: 13 }} md={{ offset: 2, span: 6 }} sm={{ offset: 1, span: 3 }}>
              <ImageWrapper>
                <Image alt="image" layout="fill" objectFit="cover" objectPosition="left top" src={image} />
              </ImageWrapper>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <AchievementTilesCollection tiles={tiles} />
      </StyledBackgroundStrip>
    </Spacing>
  );
};

export default AchievementTilesComponent;
