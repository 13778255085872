"use client";

import styled, { css } from "styled-components";
import { breakpoint, Grid, rem } from "@boxt/design-system";

import { ImageSize } from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn/ImageComponent/ImageComponent";

export const ImageWrapper = styled.div<{ $size: ImageSize }>`
  position: relative;
  width: 100%;
  ${({ $size }) => {
    return $size === ImageSize.sm
      ? css`
          height: ${rem(158)};

          ${breakpoint("md")} {
            height: ${rem(142)};
          }

          ${breakpoint("lg")} {
            height: ${rem(280)};
          }

          ${breakpoint("max")} {
            height: ${rem(310)};
          }
        `
      : css`
          height: ${rem(308)};

          ${breakpoint("md")} {
            height: ${rem(276)};
          }

          ${breakpoint("lg")} {
            height: ${rem(576)};
          }

          ${breakpoint("max")} {
            height: ${rem(639)};
          }
        `;
  }}
`;

export const StyledGridCol = styled(Grid.Col)`
  padding: 0;
  margin: 0 ${rem(4)};
  border-radius: ${rem(12)};
  overflow: hidden;
  ${breakpoint("lg")} {
    margin: 0 ${rem(8)};
  }
`;
