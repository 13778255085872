import useTranslation from "next-translate/useTranslation";
import { Spacing } from "@boxt/design-system";

import type { BatterySize } from "@DataAccess/remote/solar";
import type { SolarPackage } from "@Components/SolarConfigurator/types";

import BatteryOption from "./BatteryOption";
import { getBatteryCapacity, getBatteryImage, getBatterySize } from "./helpers";
import LoadingBatteryOption from "./LoadingBatteryOption";
import { Container } from "./styles";

export type Props = {
  availableBatteryPackages?: SolarPackage[];
  onBatteryOptionClick: (batterySize: BatterySize) => void;
  isLoading: boolean;
  selectedBatteryPackage: BatterySize;
};

const BatteryOptions = ({
  availableBatteryPackages = [],
  isLoading,
  onBatteryOptionClick,
  selectedBatteryPackage,
}: Props) => {
  const { t } = useTranslation("solar/savings-calculator");

  if (isLoading) {
    const batterySizes: BatterySize[] = ["none", "small", "large_premium"];

    return (
      <Container>
        {batterySizes.map((size) => (
          <Spacing key={size} mb={{ lg: 0, sm: 1 }}>
            <LoadingBatteryOption batterySize={size} />
          </Spacing>
        ))}
      </Container>
    );
  }

  const filteredPackages = availableBatteryPackages
    .filter((product) => {
      const batterySize = getBatterySize(product.specs);
      return batterySize === "small" || batterySize === "large_premium";
    })
    .sort((a, b) => {
      const sizeA = getBatterySize(a.specs);
      const sizeB = getBatterySize(b.specs);

      if (sizeA === "small") return -1;
      if (sizeB === "small") return 1;
      return 0;
    });

  return (
    <Container>
      <Spacing mb={{ lg: 0, sm: 1 }}>
        <BatteryOption
          batterySize="none"
          id="none"
          imageUrl="/_next-public/images/icons/no-entry.svg"
          isLoading={isLoading}
          isSelected={selectedBatteryPackage === "none"}
          name="battery-size"
          onChange={() => onBatteryOptionClick("none")}
          title={t("battery.widget.none.title")}
        />
      </Spacing>
      {filteredPackages.map((packageData) => {
        const batterySize = getBatterySize(packageData.specs) as BatterySize;

        return (
          <Spacing key={packageData.id} mb={{ lg: 0, md: 1, sm: 1 }}>
            <BatteryOption
              batterySize={batterySize}
              id={batterySize}
              imageUrl={getBatteryImage(packageData)}
              isLoading={isLoading}
              isSelected={selectedBatteryPackage === batterySize}
              name="battery-size"
              onChange={() => onBatteryOptionClick(batterySize)}
              title={getBatteryCapacity(packageData.specs)}
            />
          </Spacing>
        );
      })}
    </Container>
  );
};

export default BatteryOptions;
