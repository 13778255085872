import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint("md")} {
    flex-direction: row;
  }
`;

export const IconWrapper = styled.div`
  width: ${rem(21)};
  height: ${rem(21)};
`;
