"use client";

import styled from "styled-components";

export const Container = styled.div`
  .keen-slider .keen-slider__slide {
    overflow: initial;
    width: initial;
    min-height: initial;
  }
`;
