"use client";

import styled from "styled-components";
import { Grid, includeSpacing } from "@boxt/design-system";

export const StyledSmallImagesWrapper = styled(Grid.Col)`
  &:nth-child(3) {
    ${includeSpacing({ mt: { lg: 7, sm: 0 } })};
  }
`;

export const StyledLargeImageWrapper = styled(Grid.Col)`
  &:nth-child(2) {
    ${includeSpacing({ mb: { lg: 0, sm: 1 }, mt: { lg: 6, sm: 1 } })};
  }

  &:nth-child(4) {
    ${includeSpacing({ mb: { lg: 0, sm: 1 }, mt: { lg: 4, sm: 1 } })};
  }
`;
