import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const MobileImage = styled.div`
  display: block;

  ${breakpoint("md")} {
    display: none;
  }
`;

export const TabletImage = styled.div`
  display: none;

  ${breakpoint("md")} {
    display: block;
  }

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const DesktopImage = styled.div`
  display: none;

  ${breakpoint("lg")} {
    display: block;
  }
`;
