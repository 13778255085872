"use client";

import styled from "styled-components";
import { BulletPoints, colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  width: 100%;
  margin: 2rem auto;
  background-color: ${colors.white};
  border-radius: ${rem(12)};
  min-height: ${rem(300)};
  overflow: hidden;
  max-width: ${rem(672)};
`;

export const ImageCol = styled.div`
  position: relative;
  padding: 0;
  min-height: ${rem(375)};
`;

export const ContentCol = styled.div`
  position: relative;
  padding: 2rem 2rem 2rem 1.5rem;
  text-align: left;
`;

export const LogoWrapper = styled.div`
  width: ${rem(138)};
  height: ${rem(55)};
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledBulletPoints = styled(BulletPoints)`
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;
