import { useEffect, useRef } from "react";
import useTranslation from "next-translate/useTranslation";
import { useFsFlag } from "@flagship.io/react-sdk";
import type { AnimationItem } from "lottie-web";
import { Paragraph, Spacing } from "@boxt/design-system";

import UnitIncrementor from "@Components/UnitIncrementor";

import BogofOffer from "../BogofOffer";

import { BogofContainer } from "./styles";

export type Props = {
  isLoading?: boolean;
  isUpdating?: boolean;
  onUnitClick: (units: number) => void;
  panelPackagePowerOutput?: number;
  panelImage?: string | null | undefined;
  animationData?: Record<string, unknown>;
  numOfPanelUnits?: number;
  i18nNamespace?: string;
};

const PanelIncrementor = ({
  isLoading = false,
  isUpdating = false,
  onUnitClick,
  panelPackagePowerOutput = 430,
  panelImage,
  numOfPanelUnits = 12,
  animationData,
  i18nNamespace = "solar/options",
}: Props) => {
  const { t } = useTranslation(i18nNamespace);

  const isSolarBogofEnabled = useFsFlag("is-solar-bogof-enabled", false).getValue();

  const prevPanels = useRef<number | null>(null);
  const playerRef = useRef<AnimationItem | undefined>();

  const hasReachedMaxPanels = numOfPanelUnits === 20;
  const hasReachedMinPanels = numOfPanelUnits === 4;

  const numOfFreePanels = Math.floor(numOfPanelUnits / 2);

  useEffect(() => {
    if (!prevPanels || !playerRef.current) return;

    playerRef.current.stop();
    playerRef.current.play();
  }, [numOfPanelUnits]);

  const saveCurrentBasket = () => (prevPanels.current = numOfPanelUnits);

  return (
    <>
      <Spacing mb={2}>
        <UnitIncrementor
          copy={t("panels.widget.panel-power", {
            power: ((panelPackagePowerOutput * Number(numOfPanelUnits)) / 1000).toFixed(1),
          })}
          decrementAriaLabel={t("panels.widget.decrement-aria-label")}
          imageAlt={t("panels.widget.title")}
          imageUrl={panelImage || "/_next-public/images/solar/basket-panel-image.png"}
          incrementAriaLabel={t("panels.widget.increment-aria-label")}
          isDecrementDisabled={hasReachedMinPanels}
          isIncrementDisabled={hasReachedMaxPanels}
          isLoading={isLoading}
          isUpdating={isUpdating}
          onDecrement={() => {
            saveCurrentBasket();
            onUnitClick(numOfPanelUnits - 1);
          }}
          onIncrement={() => {
            saveCurrentBasket();
            onUnitClick(numOfPanelUnits + 1);
          }}
          title={t("panels.widget.number-of-panels", { numOfPanels: numOfPanelUnits })}
        >
          {isSolarBogofEnabled && animationData && !isLoading ? (
            <BogofContainer>
              <Spacing ml={1}>
                <BogofOffer
                  animationData={animationData}
                  onComplete={() => playerRef.current?.stop()}
                  playerRef={playerRef}
                >
                  <Paragraph boxtTheme="coral" size="small" weight="bold">
                    {t("panels.widget.bogof-offer", { count: numOfFreePanels })}
                  </Paragraph>
                </BogofOffer>
              </Spacing>
            </BogofContainer>
          ) : null}
        </UnitIncrementor>
      </Spacing>
      {hasReachedMaxPanels && (
        <Spacing mb={3}>
          <Paragraph boxtTheme="error" size="small">
            {t("panels.widget.max-panels-info")}
          </Paragraph>
        </Spacing>
      )}
      {hasReachedMinPanels && (
        <Spacing mb={3}>
          <Paragraph boxtTheme="error" size="small">
            {t("panels.widget.min-panels-info")}
          </Paragraph>
        </Spacing>
      )}
    </>
  );
};

export default PanelIncrementor;
