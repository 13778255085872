"use client";

import styled from "styled-components";
import { breakpoint, colors, H3, includeSpacing, rem } from "@boxt/design-system";

export const FlexTemplate = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`;

export const Container = styled(FlexTemplate)`
  width: 100vw;
  min-height: ${rem(250)};
  padding: ${rem(24)} 0;
  overflow: hidden;

  ${breakpoint("md")} {
    min-height: ${rem(440)};
    padding: 0;
  }

  z-index: 0;
  background: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const BackgroundRow = styled(FlexTemplate)`
  position: absolute;
  z-index: -1;
  background: ${colors.white};
`;

export const ContentWrapper = styled(FlexTemplate)`
  form {
    ${includeSpacing({ mv: 1 })};
  }
`;

export const BackgroundWrapper = styled.div`
  overflow: hidden;
  padding: 0;
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: 100%;
  svg {
    height: 100%;
    width: 100%;
    transform: scale(1.6);
    ${breakpoint("md")} {
      transform: unset;
    }
  }
`;

export const Header = styled(H3)`
  max-width: ${rem(300)};

  ${breakpoint("md")} {
    max-width: ${rem(400)};
  }

  ${breakpoint("lg")} {
    max-width: unset;
  }
`;
