import { H5 } from "@boxt/design-system";

import { DottedLine } from "@Components/DiscountsDisplay/styles";

import { ArrowSvg, LinkLine } from "./styles";

type Props = {
  title: string;
  hasDottedLine: boolean;
};

export const LinkContent = ({ title, hasDottedLine }: Props) => {
  return (
    <>
      <LinkLine>
        <H5>{title}</H5>
        <ArrowSvg />
      </LinkLine>
      {hasDottedLine ? <DottedLine /> : null}
    </>
  );
};
