import { useCallback, useEffect, useRef, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { API_URLS } from "@Collections/routes";

import { fetcher } from "src/dataAccess/local";

type Props = {
  onMarkerDragEnd?: (location: google.maps.LatLng) => void;
  postcode?: string;
  isApiLoaded: boolean;
  latLng?: { lat: number; lng: number };
};

const useMap = ({
  onMarkerDragEnd,
  postcode,
  isApiLoaded,
  latLng,
}: Props): {
  center: google.maps.LatLng | null;
  addMarker: (options: google.maps.MarkerOptions) => void;
  centerFail: boolean;
} => {
  const [center, setCenter] = useState<google.maps.LatLng | null>(null);
  const [centerFail, setCenterFail] = useState<boolean>(false);
  const marker = useRef<google.maps.Marker>(null);

  const { data, error } = useSWRImmutable(
    postcode ? [API_URLS.ADDRESSES, postcode] : null,
    ([url, code]) => fetcher(url, { postcode: code }),
    {
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (!isApiLoaded) return;

    if (latLng) {
      setCenter(new google.maps.LatLng(latLng.lat, latLng.lng));
    }

    if (postcode && data?.length && !error) {
      const { latitude, longitude } = data[0];
      setCenter(new google.maps.LatLng(latitude, longitude));
    }
  }, [postcode, data, error, isApiLoaded, latLng]);

  const addMarker = useCallback(
    (options: google.maps.MarkerOptions) => {
      /*
      clean previous marker to avoid have multiple marker's on different location
      or multiple layer on same location
      */

      if (marker.current) {
        marker.current.setMap(null);
      }

      const mapMarker = new google.maps.Marker(options);

      // @ts-ignore
      marker.current = mapMarker;

      mapMarker.addListener("dragend", (event) => {
        onMarkerDragEnd?.(event.latLng);
      });

      mapMarker.setMap(options.map || null);
    },
    [onMarkerDragEnd],
  );

  useEffect(() => {
    // check if coordinate's for center been set
    const checkCenter = setTimeout(() => {
      if (!center) {
        setCenterFail(true);
      }
    }, 3000);
    return () => {
      clearTimeout(checkCenter);
    };
  }, [center]);

  return { addMarker, center, centerFail };
};

export default useMap;
