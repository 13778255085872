import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const TitleContainer = styled.div`
  margin: 4rem auto 0;
  ${breakpoint("sm")} {
    width: ${rem(318)};
  }
  ${breakpoint("md")} {
    width: ${rem(496)};
  }
  ${breakpoint("lg")} {
    margin: 6rem auto 0;
    padding-bottom: 4rem;
    width: ${rem(848)};
  }
`;
