import type { Products } from "@Collections/products";
import { costOfBillWithoutProduct, costOfBillWithProduct } from "@Hooks/useElectricitySavings/helpers";
import type { YesOrNo } from "@Pages/api/_next/screeners/types";

export type Props = {
  annualSavings: number;
  productType: Products.Solar | Products.BatteryOnly;
  totalPriceInPence: number;
  annualElectricityUsageAnswer: number | null;
  singleUnitPrice: number | null;
  dayUnitPrice: number;
  nightUnitPrice: number;
  differentRateAtNight: YesOrNo | undefined;
};

const DEFAULT_ANNUAL_ELECTRICITY_USAGE = 2800;

const useElectricitySavings = ({
  annualSavings,
  totalPriceInPence,
  annualElectricityUsageAnswer,
  singleUnitPrice,
  dayUnitPrice,
  nightUnitPrice,
  differentRateAtNight = "no",
}: Props) => {
  const paybackPeriod = Number(totalPriceInPence) / annualSavings;

  const singleRate = singleUnitPrice || 24.5;
  const dayRate = dayUnitPrice;
  const nightRate = nightUnitPrice;

  const hasDifferentDayNightRate = differentRateAtNight === "yes";

  const billWithoutProduct = costOfBillWithoutProduct({
    annualElectricityUsage: annualElectricityUsageAnswer || DEFAULT_ANNUAL_ELECTRICITY_USAGE,
    dayRate,
    hasDifferentDayNightRate,
    nightRate,
    singleRate,
  });

  const billWithProduct = costOfBillWithProduct({ annualSavings, costOfBillWithoutProduct: billWithoutProduct });
  const savingsPercentage = Number((annualSavings / billWithoutProduct) * 100).toFixed(0);

  const hasDefaultElectricityRates = !singleUnitPrice && !annualElectricityUsageAnswer;

  return {
    annualElectricityUsage: annualElectricityUsageAnswer || DEFAULT_ANNUAL_ELECTRICITY_USAGE,
    billWithProduct,
    billWithoutProduct,
    dayRate,
    hasDefaultElectricityRates,
    hasDifferentDayNightRate,
    nightRate,
    paybackPeriod,
    savingsPercentage,
    singleRate,
  };
};

export default useElectricitySavings;
