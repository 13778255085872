import Trans from "next-translate/Trans";
import { Paragraph } from "@boxt/design-system";

import { List, ListItem } from "./styles";

type Props = {
  i18nKey: string;
};

const SolarInfoBottomSheet = ({ i18nKey }: Props) => (
  <Trans
    components={{
      li: <ListItem />,
      p: <Paragraph align="left" bottom={5} />,
      ul: <List />,
    }}
    i18nKey={i18nKey}
  />
);

export default SolarInfoBottomSheet;
