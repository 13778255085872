import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.slate.dark};
  padding: ${rem(8)} ${rem(16)} ${rem(8)} ${rem(8)};
  display: inline-flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  font-size: 0;
`;
