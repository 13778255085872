import type { ButterGridCardField } from "@Components/pages/common/GridCard/types";

export const generateMdGridRule = (cards: ButterGridCardField[]) => {
  // This constructor returns a string in the correct format for the css property grid-template-areas on desktop
  const cardsCopy = [...cards];
  const grid: string[][] = [];

  const halfEmpty: number[] = [];
  const populateGridResult = (card: ButterGridCardField) => {
    const { is_double_card, id } = card;
    if (!is_double_card) {
      if (halfEmpty.length) {
        const index = halfEmpty.shift() as number;
        grid[index][1] = id;
      } else {
        const index = grid.push([id, "null"]) - 1;
        halfEmpty.push(index);
      }
    } else {
      grid.push([id, id]);
    }
  };

  while (cardsCopy.length > 0) {
    const myCard = cardsCopy.shift() as ButterGridCardField;
    populateGridResult(myCard);
  }

  const cleanedGrid = grid.filter((row) => row[0] !== "null");
  const result = cleanedGrid.map((row) => row.join(" ").replace(/null/g, "."));

  return result.map((card) => `'${card}'`).join(" ");
};
