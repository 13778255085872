"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

const linearGradient = "linear-gradient(360deg, rgba(0, 27, 39, 0.71) 20%, rgba(45, 61, 77, 0) 70%)";

export const Container = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const BackgroundColour = styled.div<{ $backgroundColour: string }>`
  background-color: ${({ $backgroundColour }) => $backgroundColour};
  padding-bottom: ${rem(40)};
  padding-top: ${rem(20)};
`;

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const StyledGridCol = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint("lg")} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const InfoContentWrapper = styled.div`
  position: relative;
  display: flex;
  bottom: 0;
  padding-bottom: ${rem(10)};
  min-height: ${rem(95)};
  ${includeSpacing({ ml: 2, mr: 1 })};

  ${breakpoint("lg")} {
    ${includeSpacing({ ml: 3, mr: 2 })};
  }
`;

export const ChevronContainer = styled.div`
  border-radius: 50%;
  border: ${rem(1)} solid ${colors.white};
  background: transparent;
  height: ${rem(32)};
  width: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${includeSpacing({ ml: 1 })};
  svg {
    color: ${colors.white};
  }
`;

export const CardContainer = styled.a<{ $desktopImage: string; $tabletImage: string; $mobileImage: string }>`
  text-decoration: none;
  position: relative;
  background-image: ${({ $mobileImage }) => `${linearGradient}, url(${$mobileImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: ${rem(300)};
  height: ${rem(200)};
  border-radius: ${rem(8)};
  display: flex;
  flex-direction: column-reverse;
  text-align: left;
  cursor: pointer;
  margin-top: ${rem(24)};

  ${breakpoint("md")} {
    background-image: ${({ $tabletImage }) => `${linearGradient}, url(${$tabletImage})`};

    width: ${rem(400)};
    height: ${rem(280)};
  }

  ${breakpoint("lg")} {
    background-image: ${({ $desktopImage }) => `${linearGradient}, url(${$desktopImage})`};
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
    width: ${rem(450)};
    height: ${rem(300)};
  }

  &:hover {
    background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
    transition: all 0.2s ease-out;
    transform: scale(1.01);

    & ${ChevronContainer} {
      background: ${colors.white};
      svg {
        color: ${colors.slate.darker};
      }
    }
  }
`;
