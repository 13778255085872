import { Grid, H3, Spacing } from "@boxt/design-system";

import Youtube from "@Components/Youtube";

import { VideoContainer } from "./styles";
import type { ButterVideoFields } from "./types";

type Props = {
  content: ButterVideoFields;
};

const LandingSingleVideo = ({ content }: Props) => {
  const { title, video_id: videoId, video_cover: videoCover, title_position: titlePosition, size, id } = content;
  const titleLgSize = size === "large" ? 16 : { offset: 4, span: 8 };

  return (
    videoId && (
      <Grid.Row id={id}>
        <Grid.Col lg={titleLgSize} md={8} sm={4}>
          {Boolean(title) && (
            <Spacing mb={{ md: 5, sm: 3 }}>
              <H3 align={titlePosition || "center"}>{title}</H3>
            </Spacing>
          )}
          <VideoContainer $includeMarginTop={Boolean(title)}>
            <Youtube cover={videoCover} id={videoId} />
          </VideoContainer>
        </Grid.Col>
      </Grid.Row>
    )
  );
};

export default LandingSingleVideo;
