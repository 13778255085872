import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const Container = styled.div`
  ${breakpoint("lg")} {
    position: absolute;
    height: ${rem(730)};
    width: 75%;
    left: ${rem(40)};
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    overflow: hidden;
  }
`;
