import BackgroundVideo from "../BackgroundVideo";

import { Container } from "./styles";

export type Props = {
  videoPoster: string | undefined;
  video: string | undefined;
  isVideoLooping: boolean | undefined;
  mobilePoster: string | undefined;
  mobileVideo: string | undefined;
  videoDescription: string | undefined;
};

const ForegroundVideo = ({
  videoPoster = "",
  video = "",
  isVideoLooping = true,
  mobilePoster = "",
  mobileVideo = "",
  videoDescription = "",
}) => {
  return (
    <Container data-testid="foreground-video">
      <BackgroundVideo
        backgroundPoster={videoPoster}
        backgroundVideo={video}
        hasForegroundImage={false}
        isForegroundVideo
        isVideoLooping={isVideoLooping}
        mobileBackgroundPoster={mobilePoster}
        mobileBackgroundVideo={mobileVideo}
        videoDescription={videoDescription}
      />
    </Container>
  );
};

export default ForegroundVideo;
